import { Box, ListItemText, Theme, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { makeStyles } from '@mui/styles';

/**
 * Basic properties, used in style creation and component alike
 */
type ColoredVariant = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
type MessageBaseProps = {
    groupStart: boolean,
    groupEnd: boolean,
    color: ColoredVariant | 'default' | 'light',
    side: 'left' | 'right',
};

/**
 * Properties for {@link Message} component
 */
export type MessageProps = PropsWithChildren<Partial<MessageBaseProps>>;

/**
 * Uppercase first helper
 */
const ucFirst = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};

/**
 * Styles used in {@link Message} component
 */
const useStyles = makeStyles(({ palette, spacing, shape }: Theme) => ({
    message: {
        borderRadius: spacing(1.75),
        padding: spacing(1),
        display: 'inline-block',
        position: 'relative',
        zIndex: 1,
        maxWidth: '80%',
        textAlign: 'left',
        '&::after': {
            content: '" "',
            position: 'absolute',
            top: 0,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            width: 32,
            height: 26,
            backgroundColor: 'inherit',
            zIndex: 0,
        },
    },
    messageSideLeft: {
        '&::after': {
            left: -5,
            mask: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAzMiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMxLjYgMEMyNS4zIDAgMTkuNiAyLjIgMTUuMSA1LjlDMTIgMi45IDcuMSAwLjMgMCAwLjNDNi4xIDYuMiA1LjYgMTkuOSA1LjYgMjZIMzEuNlYwWiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+")',
        },
    },
    messageSideRight: {
        '&::after': {
            right: -5,
            mask: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAzMiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMEM2LjMgMCAxMiAyLjIgMTYuNSA1LjlDMTkuNiAyLjkgMjQuNSAwLjMgMzEuNiAwLjNDMjUuNSA2LjIgMjYgMTkuOSAyNiAyNkgwVjBaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K")',
        },
    },
    messageContent: {
        position: 'relative',
        zIndex: 2,
    },
    listItem: { marginTop: 18 },
    listItemSideLeft: { textAlign: 'left' },
    listItemSideRight: { textAlign: 'right' },
    colorDefault: {
        backgroundColor: palette.grey[300],
        color: palette.getContrastText(palette.grey[300]),
    },
    colorLight: {
        backgroundColor: palette.grey[50],
        color: palette.getContrastText(palette.grey[50]),
    },
    ...Object.fromEntries(
        Object.entries(palette)
            .filter(([, colorType]) => (colorType.main))
            .map(([paletteKey, colorType]) => ([`color${ucFirst(paletteKey)}`, {
                backgroundColor: colorType.main,
                color: palette.getContrastText(colorType.main),
            }]))
    ),
}));

/**
 * A Message to display a chat message in
 */
const Message = ({
    color = 'default',
    groupStart = false,
    groupEnd = false,
    side = 'left',
    children,
}: MessageProps) => {
    const classes = useStyles();
    const listItemClasses = [
        classes.listItem,
        // @ts-ignore
        classes[`listItemSide${ucFirst(side)}`],
    ].join(' ');
    const messageClasses = [
        classes.message,
        // @ts-ignore
        classes[`color${ucFirst(color)}`],
        // @ts-ignore
        classes[`messageSide${ucFirst(side)}`],
    ].join(' ');

    return (
        <ListItemText className={listItemClasses} primary={(
            <Box component="span" className={messageClasses}>
                <Typography variant="inherit" className={classes.messageContent}>
                    {children}
                </Typography>
            </Box>
        )} />
    );
};

export default Message;
