import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

/**
 * Styles used for {@link ../AppBarSpacer}
 */
export const useAppBarStyles = makeStyles((theme: Theme) => ({
    appBarSpacer: {
        marginBottom: 60,
        ...theme.mixins.toolbar,
        [theme.breakpoints.up('md')]: {
            marginBottom: 120,
        },
    },
    navIcon: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    icon: {
        width: 48,
    },
    iconMenu: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '100%',
        },
    },
    navItem: {
        paddingLeft: 10,
        paddingRight: 10,
        color: 'inherit',
        '& .MuiBadge-root .badge-content': {
            display: 'inline-block',
            paddingRight: 10,
        },
    },
    menuMobile: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginTop: 72,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '& .MuiLink-root': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        '& .MuiDivider-root': {
            borderColor: theme.palette.primary.light,
        },
    },
}));


/**
 * Responsive styles for {@link ../CubeLogo}
 */
export const useLogoStyles = makeStyles((theme: Theme) => ({
    logoCube: {
        verticalAlign: 'middle',
        height: 56,
        [theme.breakpoints.down('sm')]: {
            height: 42,
            marginLeft: '-30%',
            marginTop: '-5%',
            marginBottom: '-5%',
        },
    },
    logoWord: {
        verticalAlign: 'middle',
        marginTop: 0,
        height: 36,
        [theme.breakpoints.down('sm')]: {
            height: 27,
            marginTop: 5,
        },
    },
    providedLine: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        fontSize: 12,
        '& .MuiLink-root': {
            fontWeight: '700',
            textDecoration: 'none',
        },
    },
    rightPart: {},
    container: {
        display: 'flex',
        alignItems: 'center',
    },
}))
