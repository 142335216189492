import React, { PropsWithChildren } from 'react';

import { Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';

/**
 * Properties for {@link FormState}
 */
export type FormStateProps<T = {}> = PropsWithChildren<{
    /**
     * Whether or not the form is loading
     */
    loading?: boolean,

    /**
     * Whether or not the form is completed
     */
    completed?: boolean,

    /**
     * Whether or not the form is in error state
     */
    error?: Error,

    /**
     * State display component
     */
    Component: React.ComponentType<{ state: 'loading' | 'completed' } & T>,

    /**
     * Extra component props
     */
    componentProps?: T,
}>;

/**
 * Styles used in {@link FormState} component.
 */
const useStyles = makeStyles({
    container: {
        position: 'relative',
        width: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    hiddenForm: {
        opacity: 0,
        pointerEvents: 'none',
    },
    alert: {
        marginBottom: 20,
    },
});

/**
 * Form state display. Displays a child component (presumably the form) if no special state is
 * provided.
 */
const FormState = <T,>({
    children,
    loading,
    completed,
    error,
    Component,
    componentProps,
}: FormStateProps<T>) => {
    const classes = useStyles();
    const stateDisplay = (loading || completed)
        ? (
            <div className={classes.overlay}>
                <Component state={loading ? 'loading' : 'completed'} {...componentProps as T} />
            </div>
        )
        : null;

    return (
        <div className={classes.container}>
            {stateDisplay}

            {error
                ? (<Alert severity="error" className={classes.alert}>{error.message}</Alert>)
                : null
            }
            <div className={stateDisplay ? classes.hiddenForm :  ''}>
                {children}
            </div>
        </div>
    );
}

export default FormState;
