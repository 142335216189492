import {
    Box,
    BoxProps,
} from '@mui/material';

import React from 'react';
import Spacer from './Spacer';

/**
 * Properties for stack component
 */
export type StackProps = Exclude<BoxProps, ''> & {
    /**
     * If it should display horizontally
     */
    dir?: 'horizontal' | 'vertical',

    /**
     * Spacing applied, used from theme same as system padding only when no custom component is used
     */
    spacing?: BoxProps['padding'],

    /**
     * Spacing component
     */
    spacerElement?: React.ReactElement,
};

const Stack = ({
    children,
    spacing = 2,
    dir = 'vertical',
    spacerElement,
    ...boxProps
}: StackProps) => {
    const finalBoxProps = dir === 'horizontal' ? {
        ...boxProps,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: boxProps.justifyContent ?? 'flex-start',
        alignItems: boxProps.alignItems ?? 'center',
    } : {
        ...boxProps,
        display: 'flex',
        flexDirection: 'column',
    };

    // Get a spacer element
    const spacer = spacerElement
        ? spacerElement
        : <Spacer spacing={spacing} horizontal={dir === 'horizontal'} />;

    return (
        /* @ts-ignore */
        <Box {...finalBoxProps}>
            {
                React.Children.map(children, (child, i) => (
                    <>
                        {i === 0 ? null : spacer}
                        {child}
                    </>
                ))
            }
        </Box>
    );
};
export default Stack;
