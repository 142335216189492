import {
    Box,
    Typography,
} from '@mui/material';

import { Advertisment } from '../../Types/Client/graphql';
import KeyInfos from './KeyInfos';
import React from 'react';
import Stack from '../Common/Stack';
import { adTitle } from '../../Lib/Util';
import defaultThumb from './defaultThumbnail.png';

/**
 * Properties for {@link ListingItem} component.
 */
export type ListingItemProps = {
    /**
     * Item to display
     */
    item: Advertisment,
};

/**
 * Single advertisement listing item
 */
const ListingItem = ({ item }: ListingItemProps) => {
    return (
        <Stack dir="horizontal" alignItems="flex-start" width="100%">
            <Box flex={1}>
                <img style={{
                    width: '100%', height: 220, backgroundColor: '#f4f4f4', borderRadius: 10,
                    display: 'block',
                    objectFit: 'cover',
                }} alt="Apothekenbild als Vorschau" src={item.thumbnail ?? defaultThumb} />
            </Box>
            <Stack dir="vertical" spacing={1} flex={1}>
                <Typography variant="h4">
                    {adTitle(item)}
                </Typography>
                <Typography variant="subtitle1">
                    Objekt: {item.id}
                </Typography>
                <KeyInfos
                    infos={[
                        { type: 'transferDate', value: item.transferDate },
                        { type: 'grossProfit', value: item.grossProfit },
                        { type: 'netSales', value: item.netSales },
                        { type: 'operatingTurn', value: item.operatingTurn },
                    ]}
                />
            </Stack>
        </Stack>
    );
}

export default ListingItem;
