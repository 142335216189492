import { useApolloClient, useMutation } from '@apollo/client';

import { GET_AUTHENTICATED } from './useInitialAuthentication';
import { UserAuthenticationWithPasswordSuccess } from '../Types/Client/graphql';
import { gql } from '../Types/Client';

// The query to get all filtered
export const AUTHENTICATE = gql(/* GraphQL */ `
    mutation AuthenticateUserWithPassword($email: String!, $password: String!) {
        authenticateUserWithPassword(email: $email, password: $password) {
            ... on UserAuthenticationWithPasswordSuccess {
                item {
                    id
                    name
                }
                sessionToken
            }
            ... on UserAuthenticationWithPasswordFailure {
                message
            }
        }
    }
`);

export type UserCredentials = { email: string, password: string };

/**
 * Autheticate a user on the server
 */
const useAuthenticate = () => {
    const apolloClient = useApolloClient();
    const [mutate, { loading, data, error }] = useMutation(
        AUTHENTICATE,
        { variables: { email: '', password: '' } },
    );

    const authenticate = (credentials: UserCredentials): Promise<UserAuthenticationWithPasswordSuccess> => {
        return mutate({ variables: credentials })
            .then((response) => {
                if (response.data?.authenticateUserWithPassword?.__typename === 'UserAuthenticationWithPasswordFailure') {
                    throw new Error('Authentication failed');
                }
                return response.data?.authenticateUserWithPassword as UserAuthenticationWithPasswordSuccess;
            })
            .then((withTokenResult) => {
                return apolloClient.query({
                    query: GET_AUTHENTICATED ,
                    fetchPolicy: 'network-only',
                }).then(({ data }) => ({
                    ...withTokenResult,
                    item: data.authenticatedItem!,
                }));
            });
    };
    return { loading, data, error, authenticate };
};

export default useAuthenticate;
