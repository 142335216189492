import BottomBackLink from '../Common/BottomBackLink';
import Container from '../Common/Container';
import FormState from '../Common/FormState';
import React from 'react';
import RegistrationForm from '../User/RegistrationForm';
import RegistrationState from '../User/RegistrationState';
import { Typography } from '@mui/material';
import useRegisterUser from '../../Hooks/useRegisterUser';

/**
 * Registration page for new users
 */
const Registration = ({ type = 'default' }: { type?: 'default' | 'pharmacists' }) => {
    const { register, ...state } = useRegisterUser();
    return (
        <Container size="default">
            <FormState {...state} Component={RegistrationState}>
                <RegistrationForm onSubmit={register} type={type} />
            </FormState>
            <BottomBackLink>
                <Typography variant="body2" color="text.secondary">
                    *Pflichtfelder
                </Typography>
            </BottomBackLink>
        </Container>
    );
}

export default Registration;
