import React, { useMemo, useState } from 'react';
/**
 * Props passed to step component
 */
export type StepComponentProps<T extends {}> = {
    /**
     * Submission
     */
    onSubmit: (data: T) => void,

    /**
     * A form id to pass to map button to it
     */
    id: string,

    /**
     * Notify stepped form to activate submit button when finishing can be done
     */
    onCanFinishChanged?: (canFinish: boolean) => void,

    /**
     * Possible passed values
     */
    values?: T,
};

/**
 * Component of a form step
 */
export type StepComponent<T extends {}> = React.ComponentType<StepComponentProps<T>>;

type StepValueType = Record<string, any>;
type StepsFrom<TStepNames extends string[], TValues extends Record<TStepNames[number], StepValueType>> = {
    [K in TStepNames[number]]: StepComponent<TValues[K]>
};

/**
 * Properties for {@link SteppedForm} component
 */
type UseSteppedFormProps<
    TStepNames extends string[],
    TValues extends Record<TStepNames[number], StepValueType>
> = React.PropsWithChildren<{
    stepComponents: StepsFrom<TStepNames, TValues>,
    onSubmit?: (data: TValues) => (void | Promise<true | Error>),
    nextStepLabel?: string,
    prevStepLabel?: string,
    completeLabel?: string,
    defaultValues?: Partial<TValues>,
}>;

/**
 * Implementation of a multistep form. Pass step configuration to display a multistepped form.
 */
const useSteppedForm = <
    TStepNames extends string[],
    TValues extends Record<TStepNames[number], StepValueType>
>({
    stepComponents,
    onSubmit = () => Promise.resolve(true),
    nextStepLabel = 'nächster Schritt',
    prevStepLabel = 'Schritt zurück',
    completeLabel = 'abschließen',
    defaultValues = {},
}: UseSteppedFormProps<TStepNames, TValues>) => {
    const steps = Object.keys(stepComponents) as TStepNames;
    const [valuesCache, setValuesCache] = useState<Partial<TValues>>(defaultValues);

    // Properties derived from current step state
    const [activeStep, setActiveStep] = useState(0);
    const activeStepName = steps[activeStep] as TStepNames[number];
    const [canFinishCurrentStep, setCanFinishCurrentStep] = useState(true);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const isLastStep = activeStep === steps.length - 1;

    // Render the active step
    const Current = useMemo(() => (stepComponents[activeStepName]), [stepComponents, activeStepName]);
    const handleStepSubmit = (data: any) => {
        setValuesCache((prev) => ({
            ...prev,
            [activeStepName]: data,
        }));

        // Copy to value cache
        setSubmitting(true);

        // And process
        if (isLastStep) {
            // setValuesCache has probably not propagated, so we do it again here
            const finalValues = {
                ...valuesCache,
                [activeStepName]: data,
            } as TValues;
            (onSubmit(finalValues) ?? Promise.resolve(true))
                .then((result) => {
                    if (result !== true) {
                        return;
                    }
                    setIsCompleted(true);
                })
                .catch((error) => {
                    console.error("submitting encoundtered an error", error);
                    // setError(error);
                })
                .finally(() => setSubmitting(false));
        } else {
            setActiveStep(activeStep + 1);
            setSubmitting(false);
        }
    };
    const componentProps = {
        onSubmit: handleStepSubmit,
        id: `step_${activeStep}`,
        onCanFinishChanged: setCanFinishCurrentStep,
        values: valuesCache[activeStepName],
    };

    // Handler for back click
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const buttons = [{
        onClick: handleBack,
        disabled: activeStep === 0 || isSubmitting,
        label: prevStepLabel,
    }, {
        disabled: !canFinishCurrentStep,
        form: `step_${activeStep}`,
        label: isLastStep ? completeLabel : nextStepLabel,
    }];

    return {
        activeStep,
        steps,
        Current,
        componentProps,
        buttons,
        isSubmitting,
        isCompleted,
        isLastStep,
    };
};

export default useSteppedForm;
