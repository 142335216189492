import {
    Grid,
    MenuItem,
    Stack,
    TextField,
    Theme,
    Typography,
} from '@mui/material';

import React from 'react';
import SelectField from '../Common/SelectField';
import { makeStyles } from '@mui/styles';
import { rulesProfile } from '../../Lib/RegistrationValidation';
import { useAuth } from '../../Hooks/useAuth';
import useValidatedForm from '../../Hooks/useValidatedForm';

/**
 * Data of form in {@link ProfileDataForm}
 */
export type ProfileDataFormData = {
    salutation: string,
    titlePrefix?: string,
    titleSuffix?: string,
    email: string,
    phoneNumber?: string,
    firstName: string,
    lastName: string,
};

/**
 * Properties for {@link ProfileDataForm} component
 */
export type ProfileDataFormProps = {
    /**
     * Callback when registration form is submitted
     */
    onSubmit: (data: ProfileDataFormData) => void,

    /**
     * Id for the form element in the dom
     */
    htmlId?: string,
};

/**
 * Styles used in {@link ProfileDataForm} component
 */
const useStyles = makeStyles(({ spacing }: Theme) => ({
    formGroupHeading: {
        marginTop: spacing(1),
    },
}));

/**
 * A ProfileDataForm to display a chat ProfileDataForm in
 */
const ProfileDataForm = ({
    onSubmit,
    htmlId = 'profile-data-form',
}: ProfileDataFormProps) => {
    const classes = useStyles();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id = '', __typename, ...currentData } = useAuth().authentication || {};
    // Form state management
    const { getFieldProps, handleSubmit } = useValidatedForm<ProfileDataFormData>(
        rulesProfile,
        {
            // @ts-ignore Null values incompatibility
            defaultValues: currentData,
        },
    );

    return (
        <Stack
            dir="vertical"
            justifyContent="space-between"
            component="form"
            onSubmit={handleSubmit((data) => onSubmit(data))}
            role="form"
            id={htmlId}
        >
            <Grid container columnSpacing={2} rowSpacing={0}>
                <Grid item md={12} xs={12}>
                    <Typography variant="h6" className={classes.formGroupHeading}>
                        Angaben zur Person
                    </Typography>
                </Grid>
                <Grid item md={4} xs={6}>
                   <SelectField
                       label="Anrede"
                       select
                       fullWidth
                       {...getFieldProps('salutation')}
                   >
                       <MenuItem value="Herr">Herr</MenuItem>
                       <MenuItem value="Frau">Frau</MenuItem>
                       <MenuItem value=" ">Divers</MenuItem>
                   </SelectField>
                </Grid>
                <Grid item md={4} xs={6}>
                    <TextField
                        label="Titel vorgestellt"
                        placeholder="Mag."
                        fullWidth
                        {...getFieldProps('titlePrefix')}
                    />
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        label="Vorname"
                        placeholder="Vorname"
                        fullWidth
                        {...getFieldProps('firstName')}
                    />
                </Grid>
                <Grid item md={6} xs={8}>
                    <TextField
                        label="Nachname"
                        placeholder="Nachname"
                        fullWidth
                        {...getFieldProps('lastName')}
                    />
                </Grid>
                <Grid item md={4} xs={4}>
                    <TextField
                        label="Titel nachgestellt"
                        placeholder="Bsc."
                        fullWidth
                        {...getFieldProps('titleSuffix')}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography variant="h6" className={classes.formGroupHeading}>
                        Kommunikation
                    </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        label="Telefonnummer"
                        type="tel"
                        fullWidth
                        {...getFieldProps('phoneNumber')}
                    />
                </Grid>
            </Grid>
        </Stack>
    );
};

export default ProfileDataForm;
