import {
    IconButton,
    InputAdornment,
    Popover,
    TextField,
    TextFieldProps,
} from '@mui/material';
import React, { useEffect } from 'react';

import { visuallyHidden } from '@mui/utils';

/**
 * Picker field with picker
 */
export type PickerFieldProps<TDataType> = Omit<TextFieldProps, 'value'> & {
    /**
     * Typed value
     */
    value?: TDataType,

    /**
     * Icon to display as adornment
     */
    adornmentIcon?: React.ReactNode,

    /**
     * Value change listener
     */
    onValueChange?: (value?: TDataType) => void,

    /**
     * Value display transformer
     */
    valueDisplay: (value?: TDataType) => string,

    /**
     * Picker to display
     */
    Picker: React.ComponentType<{ initial?: TDataType, onChange: (data?: TDataType) => void }>,
};



/**
 * Input field with custom picker
 */
const PickerField = <TDataType,>({
    value,
    adornmentIcon,
    onValueChange,
    valueDisplay,
    Picker,
    ...textFieldProps
}: PickerFieldProps<TDataType>) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [valueInternal, setValueInternal] = React.useState<TDataType | null>(
        null,
    );
    const setPickerValue = (picked?: TDataType) => {
        setValueInternal(picked ?? null);
        onValueChange?.(picked);
    };
    useEffect(() => setValueInternal(value ?? null), [value]);
    const display = valueDisplay(valueInternal ?? undefined);

    const openButton = (
        <InputAdornment position="end">
            <IconButton
                style={adornmentIcon === null ? visuallyHidden : {}}
                aria-label="Auswahlhilfe öffnen"
            >
                {adornmentIcon}
            </IconButton>
        </InputAdornment>
    );

    return (
        <>
            <TextField
                {...textFieldProps}
                value={display}
                InputLabelProps={{ shrink: valueInternal !== null || display.length > 0 }}
                inputProps={{ readOnly: true, 'aria-readonly': true }}
                InputProps={{
                    endAdornment: openButton,
                    onClick: ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl(currentTarget);
                    },
                }}
                onClick={({ currentTarget }: React.MouseEvent<HTMLElement>) => {
                    setAnchorEl(currentTarget)
                }}
            />
            <Popover
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Picker
                    initial={valueInternal ?? undefined}
                    onChange={setPickerValue}
                />
                <IconButton
                    style={visuallyHidden}
                    aria-label="Dialog schließen"
                    onClick={() => setAnchorEl(null)}
                />
            </Popover>
        </>
    );
};

export default PickerField;
