import { gql } from '../Types/Client';
import { useAuth } from './useAuth';
import { useQuery } from '@apollo/client';

// The query to get all for user unfiltered
export const GET_USER_PROFILES = gql(/* GraphQL */ `
    query GetUserProfiles($id: ID!) {
        searchProfiles(where: { user: { id: { equals: $id } } }) {
            id
            name
            sendNotificationMails
            isPublic
            searchParameters {
                id
                netSalesMin
                netSalesMax
                grossProfitMin
                grossProfitMax
                transferDateIsAsOfNow
                transferDateMin
                transferDateMax
                operatingTurn
                operatingResultMin
                operatingResultMax
                type
                regions {
                    code
                    id
                    regionName
                    state {
                        id
                    }
                }
                states {
                    id
                    abbreviation
                    stateName
                }
            }
        }
    }
`);

/**
 * Use search profiles from server.
 */
const useSearchProfiles = () => {
    const { authentication } = useAuth();
    const { loading, data, error } = useQuery(
        GET_USER_PROFILES, { variables: { id: authentication?.id ?? '' }, fetchPolicy: 'cache-and-network' },
    );
    return { loading, data, error };
};

export default useSearchProfiles;
