export const monthDisplay = (date: Date) => (
    `${(date.getMonth() + 1).toString().padStart(2, '0')} / ${date.getFullYear()}`
);

/**
 * Simple date formatting, does not offer full api but meets current needs
 */
export function dateFormat(date: Date, format: string): string
{
    const final = format.replace("YYYY", "{FullYear}")
        .replace("yyyy", "{FullYear}")
        .replace("HH", "{Hours:2}")
        .replace("ii", "{Minutes:2}")
        .replace("ss", "{Seconds:2}")
        .replace("dd", "{Date:2}")
        .replace("mm", "{Month:2}")
        .replace("MM", "{Month:2}");

    return final.replace(
        /{(.+?)(?::(.*?))?}/g, // {<part>:<padding>}
        function (
            match, // Matched string (ignored, used as local var)
            component, // Date component name
            maxlen // Padding amount
        ) {
            // @ts-ignore
            if (typeof (date[`get${component}`] as unknown) !== 'function') {
                throw new Error(`Date function for ${component} does not exist`);
            }

            // @ts-ignore
            for(match = date[`get${component}`]() // Execute date component getter
                + /h/.test(component) // Increment Mont(h) components by 1
                + ""; // Cast to String
                match.length < maxlen; // While padding needed,
                match = 0 + match); // pad with zeros
            return match; // Return padded result
        });
}
/**
 * Intelligently format date
 */
export const defaultFormat = (date?: Date | null): string => (
    date ? dateFormat(date, 'dd.mm.YYYY') : '-'
);

/**
 * Intelligently format date
 */
export const intelligentFormat = (date: Date) => {
    const diff = (Date.now() - date.getTime()) / 1000;
    if (diff < 60) { return 'gerade'; }
    if (diff < 3600) { return `${Math.ceil(diff/60)} min`; }
    if (diff < 24*3600) { return `${Math.ceil(diff/3600)} h`; }

    if (date.getFullYear() === new Date().getFullYear()) { return dateFormat(date, 'dd.MM. HH:ii'); }
    return dateFormat(date, 'dd.MM.YYYY HH:ii');
};
