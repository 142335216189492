import {
    Button,
    Stack,
    Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { filterBubblesFromFilter, searchParameterToFilters } from '../../Lib/Filter';

import ActiveFilters from '../Advertisement/ActiveFilters';
import EnabledStatus from './EnabledStatus';
import ResponsiveStack from '../Common/ResponsiveStack';
import { SearchProfile } from '../../Types/Client/graphql';
import {
    makeStyles,
} from '@mui/styles';
import { searchProfileTitle } from '../../Lib/Util';

/**
 * Properties for {@link AgentItem} component.
 */
export type AgentItemProps = {
    /**
     * Handler when delete button is clicked
     */
    onDelete?: () => void,

    /**
     * Handler settings are changed
     */
    onChangeSettings?: (data: { sendNotificationMails: boolean, isPublic: boolean }) => void,

    /**
     * Handler when show results is selected
     */
    onShowResults?: () => void,

    /**
     * Search profile
     */
    profile: SearchProfile,
};

/**
 * Styles used in {@link AgentItem} component.
 */
const useStyles = makeStyles({
    /* Display button as small link text */
    buttonLink: {
        fontWeight: 'normal',
        fontSize: '0.8rem',
        paddingLeft: 0,
        paddingRight: 0,
    },
});

/**
 * Display a created search agent for the user with its props and controls to change setting or
 * delete agent entirely.
 */
const AgentItem = ({
    onDelete,
    onShowResults,
    onChangeSettings = () => {},
    profile
}: AgentItemProps) => {
    const [notificationsEnabled, setNotificationsEnabled] = useState(!!profile.sendNotificationMails);
    const [searchVisisble, setSearchVisisble] = useState(!!profile.isPublic);
    const classes = useStyles();

    const handleChange = (kind: 'public' | 'notifications', value: boolean) => {
        const values = {
            sendNotificationMails: notificationsEnabled,
            isPublic: searchVisisble,
        };
        (kind === 'public' ? setSearchVisisble : setNotificationsEnabled)(value);
        values[(kind === 'public' ? 'isPublic' : 'sendNotificationMails')] = value;
        onChangeSettings(values);
    };
    // @ts-ignore
    const bubbles = useMemo(() => filterBubblesFromFilter(
        searchParameterToFilters(profile.searchParameters)
    ), [profile.searchParameters]);

    return (
        <>
            <Stack direction="column" spacing={1} flex={1}>
                <Typography variant="h4">
                    {searchProfileTitle(profile.searchParameters ?? {})}
                </Typography>
                <Typography variant="subtitle1">
                    <ActiveFilters
                        filters={bubbles}
                    />
                </Typography>
                <ResponsiveStack
                    direction="row"
                    spacing={0}
                    alignItems="flex-start"
                    desktopProps={{ alignItems: 'center', spacing: 1 }}
                >
                    <EnabledStatus
                        label="Email-Benachrichtigungen"
                        enabled={notificationsEnabled}
                        onChange={(value) => handleChange('notifications', value)}
                    />
                    <EnabledStatus
                        label="Anzeige in Suche"
                        enabled={searchVisisble}
                        onChange={(value) => handleChange('public', value)}
                    />
                    <Button
                        variant="text"
                        className={classes.buttonLink}
                        aria-label="Suchagent löschen"
                        onClick={onDelete}
                    >
                        Suchagent löschen
                    </Button>
                </ResponsiveStack>
            </Stack>
            <Button
                onClick={onShowResults}
                aria-label="Ergebnisse anzeigen"
            >
                Ergebnisse anzeigen &gt;
            </Button>
        </>
    );
}

export default AgentItem;
