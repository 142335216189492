import React, { PropsWithChildren, createContext, useState } from 'react';

/**
 * Notification type type
 */
export type NotificationType = 'error' | 'success' | 'info' | 'warn' | 'loading';

/**
 * Notification type
 */
export type Notification = {
    message: string,
    type: NotificationType,
    icon: string,
    key: number,
    open: boolean | null,
};

/**
 * Type of controls for notifications
 */
export type ControlledNotificationControls = {
    hideNotification: () => void,
};

/**
 * Internal state type
 */
type NotificationsState = Array<Notification>;

/**
 * The created context.
 */
export const NotificationContext = createContext<{
    notifications: Array<Notification>,
    add: (message: string, type: NotificationType, icon: string) => void,
    addError: (message: string, icon?: string) => void,
    addSuccess: (message: string, icon?: string) => void,
    addInfo: (message: string, icon?: string) => void,
    addControlledNotification: (message: string, type: NotificationType, icon: string) => ControlledNotificationControls,
        }>(
        {
            notifications: [],
            add: (message: string, type: NotificationType, icon: string) => {},
            addError: (message: string, icon?: string) => {},
            addSuccess: (message: string, icon?: string) => {},
            addInfo: (message: string, icon?: string) => {},
            addControlledNotification: (message: string, type: NotificationType, icon: string): ControlledNotificationControls => ({
                hideNotification: () => {},
            }),
        }
        );

/**
 * The provider, implementing the context
 */
const NotificationProvider = ({ children }: PropsWithChildren<{}>) => {
    // Notifications state
    const [notifications, setNotifications] = useState<NotificationsState>([]);

    /**
     * Generic add notification function
     */
    const add = (
        message: string,
        type: NotificationType,
        icon: string,
        controlled?: boolean,
    ): ControlledNotificationControls => {
        const generatedKey = Date.now() + Math.random();
        const newval = [
            ...notifications,
            { message, type, icon, key: generatedKey, open: controlled ? true : null },
        ];
        setNotifications(newval);

        return {
            hideNotification: () => setNotifications((prev: NotificationsState) => (
                [
                    ...prev.map((notification) => (
                        notification.key === generatedKey ? {
                            ...notification,
                            open: false,
                        } : notification
                    )),
                ]
            )),
        };
    };

    const addWithoutControls = (
        message: string,
        type: NotificationType,
        icon: string,
        controlled?: boolean,
    ): void => {
        add(message, type, icon, controlled);
    };

    /// Generate the context
    const contextValue = {
        notifications,
        add: (message: string, type: NotificationType, icon: string) => addWithoutControls(message, type, icon),
        addError: (message: string, icon: string = '') => addWithoutControls(message, 'error', icon),
        addSuccess: (message: string, icon: string = '') => addWithoutControls(message, 'success', icon),
        addInfo: (message: string, icon: string = '') => addWithoutControls(message, 'info', icon),
        addControlledNotification: (message: string, type: NotificationType, icon: string) => add(message, type, icon, true),
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
}

export default NotificationProvider;
