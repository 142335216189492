import { useParams } from 'react-router-dom';

/**
 * Use id param and parse numerically
 */
const useIdParam = () => {
    const params = useParams<'id'>();
    if (! params.id) {
        return undefined;
    }

    const parsed = parseInt(params.id);
    return isNaN(parsed) ? undefined : parsed;
};

export default useIdParam;
