import { ApolloError } from '@apollo/client';

export type QueryOrMutationState = {
    error?: ApolloError;
    loading: boolean;
    called?: boolean;
    reset?: () => void;
};

export type CombinedQueryMutationState = QueryOrMutationState & {
    completed: boolean;
};

/**
 * Check if a query state is considered completed (when called and not in loading or error state)
 */
export const isCompleted = (check: QueryOrMutationState): boolean => (
    (check.called && !check.loading && !check.error) ?? false
);

/**
 * Merge multiple states into one
 */
export const mergeStates = (...statesToCombine: QueryOrMutationState[]): CombinedQueryMutationState => {
    const state: QueryOrMutationState = { error: undefined, loading: false, called: statesToCombine.length > 0 };
    const combined = statesToCombine.reduce((memo, state) => ({
         error: memo.error ?? state.error,
         loading: memo.loading || state.loading,
         called: memo.called && state.called,
    }), state);
    const reset = () => statesToCombine.forEach(({ reset }) => reset?.());

    return { reset, ...combined, completed: isCompleted(combined) };
};

/**
 * Helper to mark types as nullable optional as in apollo tuples
 */
export type ApolloType<TType> = null | {
    [TKey in keyof TType]?: TType[TKey] | null | undefined;
};
