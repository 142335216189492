import Notification from './Notification';
import React from 'react';
import useNotifications from '../../Hooks/useNotifications';

/**
 * Display all current notifications provided by {@link useNotifications}
 */
const Notifications: React.FC<{}> = () => {
    const { notifications } = useNotifications();
    return (
        <>
            {notifications.map(({ key, message, type, open }) => (
                <Notification message={message} type={type} key={key} open={open} />
            ))}
        </>
    );
};

export default Notifications;
