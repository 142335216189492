import { User, UserUserRoleType } from '../Types/Client/graphql';

import createAuthContext from '../Providers/AuthProvider';
import { useContext } from 'react';

/**
 * Authentication credentials
 */
export type Credentials = {
    email: string,
    password: string,
};

/**
 * Export the contexts
 */
export const {
    useAuth,
    AuthContext,
    AuthContextProvider,
} = createAuthContext<Partial<Omit<User, 'id'>> & Pick<User, 'id'>, Credentials>();

export function useAuthenticate(): (credentials: Credentials) => void {
    return useContext(AuthContext).signIn;
};

export function useIsSeller(): boolean {
    return useAuth().authentication?.userRole === UserUserRoleType.Seller;
};

// re-export as default as well
export default useAuth;
