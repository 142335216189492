import Form, { FormData } from './Form';
import React, { useState } from 'react';
import {
    Stack,
    Typography,
} from '@mui/material';

import FormDialog from '../Common/FormDialog';
import FormState from '../Common/FormState';
import FormStateMessage from '../Common/FormStateMessage';
import useCallbackRequest from '../../Hooks/useCallbackRequest';

/**
 * Properties for {@link Dialog} component
 */
export type DialogProps = {
    /**
     * Dialog opened state control
     */
    open?: boolean,

    /**
     * Callback fired when close is requested
     */
    onClose: () => void,

    /**
     * Callback fired when form is submitted
     */
    onSubmit: (data: FormData) => void,
};

/**
 * Dialog location selection component
 */
const Dialog = ({
    open = true,
    onClose,
    onSubmit,
}: DialogProps) => {
    const [completed, setCompleted] = useState(false);
    const { request, ...state } = useCallbackRequest();
    const handleSubmit = (data: FormData) => {
        if (completed) {
            handleClose();
            return;
        }
        request(data)
            .then(() => {
                setCompleted(true);
                onSubmit(data);
            });
    };
    const handleClose = () => {
        setCompleted(false);
        onClose();
    };

    return (
        <FormDialog
            open={open}
            onClose={handleClose}
            title="Rückruf vereinbaren"
            action={completed ? 'schließen' : 'Anfrage absenden'}
            actionSubmit="request-callback-form"
        >
            <FormState
                completed={completed}
                {...state}
                Component={FormStateMessage}
                componentProps={{
                    loadingMessage: (
                        <Stack spacing={0} alignItems="center">
                            <Typography variant="h3">
                                Anfrage wird versendet
                            </Typography>
                            <Typography variant="body1">
                                Bitte einen Moment Geduld.
                            </Typography>
                        </Stack>
                    ),
                    completedMessage: (
                        <Stack spacing={0} alignItems="center">
                            <Typography variant="h3">
                                Anfrage wurde abgeschickt
                            </Typography>
                            <Typography variant="body1">
                                Vielen Dank unser Service-Team wird sich mit Ihnen in Verbindung setzen.
                            </Typography>
                        </Stack>
                    ),
                }}
            >
                <Form onSubmit={handleSubmit} id="request-callback-form" />
            </FormState>
        </FormDialog>
    );
};

export default Dialog;
