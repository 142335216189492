import { CheckIcon, ClearIcon } from '../Icon';
import React, { useMemo } from 'react';
import {
     Stack,
     Typography,
} from '@mui/material';

/**
 * Properties for {@link PasswordStrengthIndicator} component
 */
export type PasswordStrengthIndicatorProps = {
    /**
     * Value to indicate
     */
    value?: string,
};

/**
 * The used password policy
 */
export const POLICY = {
    hasUppercase: 'Großbuchstabe',
    hasLowercase: 'Kleinbuchstabe',
    hasNumber: 'Nummer',
    hasLength: 'mind. 8 Zeichen',
};

/**
 * A password strength visualisation
 */
const PasswordStrengthIndicator = ({ value }: PasswordStrengthIndicatorProps) => {
    // All categories indication
    const policyMatch = useMemo(() => ({
        hasUppercase: value?.match(/[A-Z]/) ?? false,
        hasLowercase: value?.match(/[a-z]/) ?? false,
        hasNumber: value?.match(/[0-9]/) ?? false,
        hasLength: (value?.length || 0) >= 8,
    }), [value]);

    return (
        <Stack direction="row" spacing={1} alignSelf="center" flexWrap="wrap">
            {Object.entries(POLICY).map(([key, label]) => (
                <Typography
                    variant="body2"
                    /* @ts-ignore */
                    sx={{ color: policyMatch[key] ? 'success.main' : 'text.secondary' }}
                    key={key}
                >
                    {/* @ts-ignore */}
                    {policyMatch[key]
                        ? <CheckIcon color="inherit" />
                        : <ClearIcon color="inherit" />
                    }{' '}
                    {label}
                </Typography>
            ))}
        </Stack>
    );
};

export default PasswordStrengthIndicator;
