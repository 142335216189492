import {
    FormHelperText,
    FormHelperTextProps,
} from '@mui/material';

import React from 'react';

/**
 * Properties for {@link FormErrorDisplay}
 */
export type FormErrorDisplayProps = {
    /**
     * Helper / error text
     */
    helperText?: string,
} & FormHelperTextProps;

/**
 * Default FormErrorDisplay for content. Wrapping all in a default {@link Box}
 */
const FormErrorDisplay: React.FC<FormErrorDisplayProps> = ({
    helperText,
    ...helperProps
}: FormErrorDisplayProps) => {
    return (
        <FormHelperText {...helperProps}>
            {helperText}
        </FormHelperText>
    );
};

export default FormErrorDisplay;
