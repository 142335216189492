import {
    Divider,
    IconButton,
    Modal,
    Stack,
    Theme,
    useMediaQuery,
} from '@mui/material';
import React, { PropsWithChildren, useEffect, useState } from 'react'

import { MenuIcon } from '../Components/Icon';
import { useAppBarStyles } from './Styles';
import { useLocation } from 'react-router-dom';

/**
 * Layouts header, display an app bar and a global menu.
 */
const ResponsiveMenu = ({ children }: PropsWithChildren<{}>) => {
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const classes = useAppBarStyles();
    const stackProps = isDesktop
        ? { direction: 'row', alignItems: 'center' } as const
        : { direction: 'column', alignItems: 'stretch', divider: <Divider color="light" />, className: classes.menuMobile } as const;
    const [open, setOpen] = useState(false);
    // Close menu on selection
    const location = useLocation();
    useEffect(() => setOpen(false), [location]);

    const wrappedMenu = (
        <Stack {...stackProps} spacing={1} component="nav">
            {children}
        </Stack>
    );
    if (isDesktop) {
        return wrappedMenu;
    }

    return (
        <>
            <IconButton
                onClick={() => setOpen(true)}
                color="inherit"
                className={classes.navIcon}
            >
                <MenuIcon color="inherit" className={classes.icon} />
            </IconButton>
            <Modal open={open} onClose={() => setOpen(false)}>
                {wrappedMenu}
            </Modal>
        </>
    );
};

export default ResponsiveMenu;
