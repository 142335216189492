import {
    Theme,
} from '@mui/material';
import {
    makeStyles,
} from '@mui/styles';

/**
 * Stylings used in {@link AllFotosButton} component.
 */
const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) => ({
    filterContainer: {
        backgroundColor: '#F4F4F4',
        borderRadius: 5,
        marginLeft: spacing(-1),
        marginRight: spacing(-1),
        display: 'none',
        [breakpoints.up('md')]: {
            display: 'block',
        },
    },
    filterContent: {
        padding: spacing(1),
    },
    specialHeader: {
        backgroundColor: palette.primary.main,
        marginLeft: spacing(-2),
        marginRight: spacing(-2),
        marginTop: spacing(-2),
        marginBottom: spacing(2),
        padding: `${spacing(1)} ${spacing(2)}`,
        color: palette.primary.contrastText,
    },
    backButton: {
        padding: 0,
        paddingLeft: 0,
        fontWeight: 'normal',
        color: 'inherit',
        marginBottom: spacing(1),
        minWidth: 'auto',
    },
    pageTitle: {
        textTransform: 'uppercase',
    },
}));

export default useStyles;
