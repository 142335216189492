import { ConversationMessage } from '../../Types/Client/graphql';
import {
    List,
} from '@mui/material';
import ListingEmpty from '../Common/ListingEmpty';
import MessageGroup from './MessageGroup';
import React from 'react';
import useDisplayGroupedMessages from '../../Hooks/useDisplayGroupedMessages';

/**
 * Properties for {@link ChatMessages}
 */
export type ChatMessagesProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * Messages for display
     */
    messages: Array<ConversationMessage>,
};

/**
 * Displays ChatMessages interface
 */
const ChatMessages = ({
    className,
    messages,
}: ChatMessagesProps) => {
    const grouped = useDisplayGroupedMessages(messages);
    return (
        <List className={className}>
            {grouped.map(({ own, messages, displayedTime }) => (
                <MessageGroup
                    key={displayedTime}
                    side={own ? 'left' : 'right'}
                    color={own ? 'default' : 'light'}
                    messages={messages}
                    displayedTime={displayedTime}
                />
            ))}
            <ListingEmpty items={grouped} message="Noch keine Nachrichten in dieser Konversation" />
        </List>
    );
};

export default ChatMessages;
