import {
    Button,
    Chip,
    Stack,
} from '@mui/material';

import { FilterBubbleItem } from '../../Lib/Filter';
import React from 'react';
import ResponsiveStack from '../Common/ResponsiveStack';

/**
 * Properties for {@link ActiveFilters} component.
 */
export type ActiveFiltersProps = {
    /**
     * Current filter values to display
     */
    filters: Array<FilterBubbleItem>,

    /**
     * Handler for remove of a single filter
     */
    onRemove?: (filterIndex: number) => void,

    /**
     * Handler for reset, if set shows reset control
     */
    onReset?: () => void,
};

/**
 * Display current active fiters
 */
const ActiveFilters = ({
    filters,
    onRemove,
    onReset,
}: ActiveFiltersProps) => {
    const handleDelete = (num: number) => (onRemove ? (()  => onRemove(num)) : undefined);
    return (
        <Stack direction="row" justifyContent="space-between" width="100%">
            <ResponsiveStack direction="row" flexWrap="wrap" alignItems="flex-start" gap={0.5}>
                {filters.map(({ label, valueLabel }, i) => {
                    const filterLabel = label
                        ? (<>{label}: {valueLabel}</>)
                        : valueLabel;
                    return (
                        <Chip key={`filter_${valueLabel}_${i}`} label={filterLabel} onDelete={handleDelete(i)} />
                    );
                })}
            </ResponsiveStack>
            {onReset && (
                <Button variant="text" onClick={onReset}>Filter zurücksetzen</Button>
            )}
        </Stack>
    );
}

export default ActiveFilters;
