import {
    Grid,
    ListItem,
    Typography,
} from '@mui/material';
import Message, { MessageProps } from './Message';

import React from 'react';
import { makeStyles } from '@mui/styles';

type InheritedProps = Pick<MessageProps, 'side' | 'color'>;

/**
 * Properties for {@link MessageGroup} component
 */
export type MessageGroupProps = InheritedProps & {
    messages: Array<any>,
    displayedTime: string,
};

/**
 * Styles used in {@link MessageGroup} component
 */
const useStyles = makeStyles(() => ({
    messageTime: {
        textAlign: 'center',
        display: 'block',
    },
}));

/**
 * A message group of several messages with a common time stamp
 */
const MessageGroup = ({
    messages,
    displayedTime,
    ...passedProps
}: MessageGroupProps) => {
    const groupMessageCount = messages.length;
    const classes = useStyles();
    return (
        <ListItem key="1">
            <Grid container>
                <Grid item xs={12}>
                    <Typography className={classes.messageTime} variant="body2">{displayedTime}</Typography>
                </Grid>
                {messages.map((message, i) => (
                    <Grid item xs={12}>
                        <Message
                            groupStart={i === 0}
                            groupEnd={i === groupMessageCount - 1}
                            {...passedProps}
                        >
                            {message}
                        </Message>
                    </Grid>
                ))}
            </Grid>
        </ListItem>
    );
};

export default MessageGroup;
