import {
    Card,
    CardContent,
    Container,
    Skeleton,
} from '@mui/material';
import { Navigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import useCreateConversation, { CreateInput } from '../../Hooks/useCreateConversation';

import AppError from '../../AppError';

/**
 * Validate input data
 */
const isValidCreateInput = (params: Record<string, string | undefined>): params is CreateInput => (
    ((params.advertismentId && !params.searchProfileId) ? true : false)
    || ((!params.advertismentId && params.searchProfileId) ? true : false)
    || false
);

/**
 * User area
 */
const CreateConversation = () => {
    const [searchParams, ] = useSearchParams();
    const [result, setResult] = useState<any>();
    const { create, ...state } = useCreateConversation();

    const [advertismentId, searchProfileId] = useMemo(() => ([
        searchParams.get('advertisment') ?? undefined,
        searchParams.get('profile') ?? undefined,
    ]), [searchParams]);

    useEffect(() => {
        const createParams = { advertismentId, searchProfileId };
        if (!state.loading && isValidCreateInput(createParams)) {
            create(createParams)
                .then((conversation) => setResult(conversation));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertismentId, searchProfileId]);

    if (result?.id) {
        return (<Navigate to={`/nachrichten?c=${result.id}`} replace={true} />);
    }

    if (state.loading) {
        return (
            <Container>
                <Card>
                    <CardContent>
                        <Skeleton aria-label="Daten werden verarbeitet. Einen Moment Geduld." />
                    </CardContent>
                </Card>
            </Container>
        );
    };

    return (
        <AppError
            error={new Error('Anfrage konnte nicht verarbeitet werden')}
            resetErrorBoundary={() => {}}
        />
    );
};

export default CreateConversation;
