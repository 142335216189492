import {
    Button,
    Stack,
    TextField,
} from '@mui/material';

import { rulesForgotPassword } from '../../Lib/RegistrationValidation';
import useForm from '../../Hooks/useValidatedForm';
import useRequestPasswordLink from '../../Hooks/useRequestPasswordLink';

/**
 * Properties for {@link PasswordForgotForm} component
 */
export type PasswordForgotFormProps = {
    /**
     * Callback when request was sent
     */
    onRequested?: () => void,
};


/**
 * Passwort forgot form to request email
 */
const PasswordForgotForm = ({ onRequested }: PasswordForgotFormProps) => {
    const { request, loading } = useRequestPasswordLink();
    const { getFieldProps, handleSubmit } = useForm<{ email: string }>(rulesForgotPassword, {});
    const handleFormSubmit = ({ email }: { email: string }) => {
        request(email).then(onRequested ?? (() => {}));
    };

    return (
        <Stack
            spacing={1}
            alignItems="flex-end"
            component="form"
            onSubmit={handleSubmit(handleFormSubmit)}
            role="form"
        >
            <TextField
                label="Email-Adresse"
                fullWidth
                {...getFieldProps('email')}
            />

            <Button
                variant="contained"
                type="submit"
                disabled={loading}
            >
                weiter
            </Button>
        </Stack>
    );
}

export default PasswordForgotForm;
