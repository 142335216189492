import ActivateSearchAgent, { ActivateSearchAgentOptions } from '../Advertisement/ActivateSearchAgent';
import {
    Alert,
    CardContent,
    Paper,
    Stack,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import FilterForm, { type FilterData } from '../Advertisement/FilterForm';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import SidebarContainer, { Main, Sidebar } from '../Common/SidebarContainer';
import {
    filterBubblesFromFilter,
    getLocationTypeFilterIds,
    parseAll,
    serializeAll,
} from '../../Lib/Filter';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ActiveFilters from '../Advertisement/ActiveFilters';
import Container from '../Common/Container';
import ContainerHeader from '../Common/ContainerHeader';
import LANG from '../../Lang/StaticTexts';
import Listing from '../Advertisement/Listing';
import ListingEmpty from '../Common/ListingEmpty';
import ListingLoader from '../Advertisement/ListingLoader';
import MobileDialog from '../Common/MobileDialog';
import QueryState from '../Common/QueryState';
import RelatedInexactMatches from '../Advertisement/RelatedInexactMatches';
import { SearchParameterTypeType } from '../../Types/Client/graphql';
import useAdvertisments from '../../Hooks/useAdvertisments';
import useMutateSearchProfile from '../../Hooks/useMutateSearchProfile';
import useNotifications from '../../Hooks/useNotifications';
import useStyles from './usePageStyles';

const Advertisements = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState<FilterData>(parseAll(searchParams));
    const { data, ...queryState } = useAdvertisments(filters);
    const { mutate } = useMutateSearchProfile();
    const { addError } = useNotifications();
    const bubbles = useMemo(() => filterBubblesFromFilter(filters), [filters]);
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    // Update search param state on filter change
    useEffect(() => setSearchParams(serializeAll(filters), { replace: true }), [filters, setSearchParams]);

    // Handle removal of fitler bubble
    const handleBubbleRemoval = (index: number) => {
        const { filterKey, valueKey } = bubbles[index];
        setFilters((prev) => {
            const copy = { ...prev };
            let filterOverride = prev[filterKey];
            // Override part or total
            if (valueKey !== undefined) {
                if (typeof valueKey === 'number') {
                    // @ts-ignore
                    filterOverride = [...filterOverride];
                    // @ts-ignore
                    filterOverride.splice(valueKey, 1);
                } else {
                    // @ts-ignore
                    delete filterOverride[valueKey];
                }
                // @ts-ignore
                copy[filterKey] = filterOverride;
            } else {
                delete copy[filterKey];
            }
            return copy;
        });
    };

    // Handle activation via button
    const handleActivateSearchAgent = ({ isPublic, allowNotifications }: ActivateSearchAgentOptions) => {
        if (!filters.type) {
            addError('Geben Sie bitte einen Objekttyp an um einen Suchagenten einzurichten');
            return;
        }

        // Profit range
        const profitFilter = filters.profit ? {
            netSalesMax: filters.profit[1],
            netSalesMin: filters.profit[0],
        } : {};

        // Get and normalize regions data to use in filters
        const regionIds = getLocationTypeFilterIds('region', filters.location);
        const stateIds = getLocationTypeFilterIds('state', filters.location);
        const regionsFilter = {
            regions: regionIds?.length ? { connect: regionIds } : undefined,
            states: stateIds?.length ? { connect: stateIds } : undefined,
        };

        // Create a new search profile
        mutate({
            name: `agent-${Date.now()}`,
            sendNotificationMails: allowNotifications,
            isPublic: isPublic,
            searchParameters: {
                create: {
                    type: filters.type as SearchParameterTypeType,
                    transferDateMin: filters.transferDate ? filters.transferDate : null,
                    ...profitFilter,
                    ...regionsFilter,
                },
            },
        });
    };

    const FilterWrap: React.FC<PropsWithChildren<{}>> = useMemo(() => (
        isDesktop
            ? ({ children }) => (
                <Paper className={classes.filterContainer}>
                    <CardContent className={classes.filterContent}>
                        <Typography variant="h4" color="text.primary">Filter</Typography>
                        {children}
                    </CardContent>
                </Paper>
            )
            : ({ children }) => (
                <MobileDialog title="Filter" actionLabel="Ergenisse anzeigen">
                    <>{children}</>
                </MobileDialog>
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [isDesktop]);

    return (
        <Container size="default" style={{ overflow: 'visible' }}>
            <ContainerHeader
                className={classes.specialHeader}
                title="Inserate"
                subtitle={data?.advertisments ? `${data.advertisments.length} Angebote` : 'Angebote'}
                sidebarFirst
            />
            <SidebarContainer alignItems="flex-start">
                <Sidebar>
                    <Stack spacing={2}>
                        <FilterWrap>
                            <FilterForm onChange={setFilters} values={filters} multiple />
                            <ActivateSearchAgent onActivate={handleActivateSearchAgent} />
                        </FilterWrap>
                        <Alert severity="info">
                            {LANG.providerServiceInfo}
                        </Alert>
                    </Stack>
                </Sidebar>
                <Main>
                    <ActiveFilters
                        filters={bubbles}
                        onRemove={handleBubbleRemoval}
                        onReset={() => setFilters({})}
                    />
                    <QueryState {...queryState} loadingIndicator={(<ListingLoader />)}>
                        <Listing
                            results={data?.advertisments ?? []}
                            onSelect={(id) => navigate(`/inserat/${id}`)}
                        />
                        <ListingEmpty
                            items={data?.advertisments}
                            message={(
                                <Stack spacing={2} pb={3}>
                                    <Typography variant="h3">Es tut uns leid!</Typography>
                                    <Typography variant="body1">{LANG.noResultsSearch}</Typography>
                                    <Typography variant="body1">{LANG.noResultsSearchProfile}</Typography>
                                </Stack>
                            )}
                        />
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
                            {data?.advertisments?.length ? (
                                <Typography variant="body2" color="text.secondary">
                                    {LANG.endOfSearchResults}
                                </Typography>
                            ) : ' '}
                            <div>
                                <ActivateSearchAgent
                                    onActivate={handleActivateSearchAgent}
                                    onlyButton
                                />
                            </div>
                        </Stack>
                        <RelatedInexactMatches filters={filters} />
                    </QueryState>
                </Main>
            </SidebarContainer>
        </Container>
    );
}

export default Advertisements;
