import {
    Button,
    ImageList,
    Stack,
} from '@mui/material';
import MainForm, { FormData } from '../Advertisement/MainForm';
import QueuedFoto, { FileQueuedFoto } from '../Advertisement/QueuedFoto';
import React, { useEffect, useRef, useState } from 'react';
import useMutateAdvertismentImages, { CurrentFile } from '../../Hooks/useMutateAdvertismentImages';

import Container from '../Common/Container';
import ContainerHeader from '../Common/ContainerHeader';
import DescriptionForm from '../Advertisement/DescriptionForm';
import EditState from '../Advertisement/EditState';
import FormDialog from '../Common/FormDialog';
import FormState from '../Common/FormState';
import FotoForm from '../Advertisement/FotoForm';
import { mergeStates } from '../../Lib/Apollo';
import useAdvertismentImages from '../../Hooks/useAdvertismentImages';
import useMutateAdvertisment from '../../Hooks/useMutateAdvertisment';
import { useParams } from 'react-router-dom';

/**
 * Page to edit an advertisment
 */
const Edit = () => {
    // Basic data hooks
    const { id } = useParams<{ id: string }>();
    const { mutate, editObject, ...state } = useMutateAdvertisment(id);
    const ref = useRef<HTMLButtonElement>(null);
    const { images } = useAdvertismentImages(id || '');
    const { mutate: mutateImages, ...imagesMutationState } = useMutateAdvertismentImages(id || '');

    // Editing details
    const [detailEdit, setDetailEdit] = useState<string>('');
    const [details, setDetails] = useState({
        description: editObject?.description ?? '',
        fotos: [] as Array<CurrentFile & { url: string }>,
        upload: [] as Array<File>,
    });

    // When fotos are (re)loaded, set local foto state
    useEffect(() => {
        setDetails((prev) => {
            const updatedFotos = images.map(({ id, image, sort }) => ({
                id,
                url: image?.url ?? '',
                sort: sort ?? 1,
                shouldDelete: prev.fotos.find(({ id: match }) => (match === id))?.shouldDelete ?? false,
            }));

            return { ...prev, fotos: updatedFotos };
        });
    }, [images]);

    // Handle submit with fotos in extra step
    const handleSubmitWithoutFotos = (data: FormData) => {
        const { thumbnail, __typename, id, user, ...cleanedData } = data;
        mutate({
            ...cleanedData,
            description: details.description,
            fotos: [],
        }).then(() => {
            return mutateImages(details.fotos, details.upload);
        });
    };

    // Handle removal mark for deletion
    const handleImageRemove = (index: number) => {
        setDetails((prev) => {
            const updatedFotos = prev.fotos;
            updatedFotos.splice(index, 1, {
                ...updatedFotos[index],
                shouldDelete: true,
            });

            return { ...prev, fotos: updatedFotos };
        });
    };
    // Handle removal of not yet uploaded
    const handleUploadRemove = (index: number) => {
        setDetails((prev) => {
            const updatedFotos = prev.upload;
            updatedFotos.splice(index, 1);
            return { ...prev, upload: updatedFotos };
        });
    };

    return (
        <Container size="default">
            <ContainerHeader
                title="Inserat bearbeiten"
                titleTypographyProps={{ color: 'primary', className: '' }}
            />
            <Stack>
                <FormState {...mergeStates(state, imagesMutationState)} completed={state.completed} Component={EditState}>
                    <MainForm
                        id="edit-form"
                        onSubmit={handleSubmitWithoutFotos}
                        values={{ ...editObject }}
                    />
                    <Stack direction="row">
                        <Button variant="contained" type="submit" form="edit-form" ref={ref}>
                            Daten übernehmen
                        </Button>
                        <Button onClick={() => setDetailEdit('description')}>
                            Beschreibung ändern
                        </Button>
                        <Button onClick={() => setDetailEdit('foto')}>
                            Fotos ändern
                        </Button>
                    </Stack>
                </FormState>
            </Stack>

            <FormDialog
                open={detailEdit !== ''}
                title="Bearbeiten"
                onClose={() => setDetailEdit('')}
                action="speichern"
                actionSubmit="detail-form"
                size="md"
            >
                {
                    detailEdit === 'description'
                    ? (
                        <DescriptionForm
                            values={{ description: editObject?.description ?? '' }}
                            id="detail-form"
                            onSubmit={(formData) => {
                                setDetails((prev) => ({ ...prev, ...formData }));
                                setDetailEdit('');
                                requestAnimationFrame(() => ref.current?.click());
                            }}
                        />
                    )
                    : (
                        <Stack>
                            <FotoForm
                                id="detail-form"
                                onSubmit={(fotoData) => {
                                    setDetails((prev) => ({
                                        ...prev,
                                        upload: [...prev.upload, ...(fotoData.fotos ?? [])],
                                    }));
                                    setDetailEdit('');
                                    requestAnimationFrame(() => ref.current?.click());
                                }}
                            />
                            <ImageList cols={5}>
                                {details.fotos
                                    .filter(({ shouldDelete }) => (!shouldDelete))
                                    .map((image, index) => (
                                        <QueuedFoto
                                            key={`foto_${index}`}
                                            image={{ data_url: image.url }}
                                            onRemove={() => handleImageRemove(index)}
                                        />
                                    ))
                                }
                                {details.upload
                                    .map((image, index) => (
                                        <FileQueuedFoto
                                            key={`upload_${index}`}
                                            image={{ file: image }}
                                            onRemove={() => handleUploadRemove(index)}
                                        />
                                    ))
                                }
                            </ImageList>
                        </Stack>
                    )
                }
            </FormDialog>
        </Container>
    );
}

export default Edit;
