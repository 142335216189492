import { GET_LIST } from './useContentList';
import { gql } from '../Types/Client';
import { useQuery } from '@apollo/client';

// The query to get one by id
export const GET_CONTENT = gql(/* GraphQL */ `
    query GetContent($id: ID!) {
        content(where: { id: $id }) {
            image { url }
            title
            content {
                document(hydrateRelationships: true)
            }
        }
    }
`);


/**
 * Use content with a special key from server
 */
export const useContentByKey = (key: string) => {
    const { loading, data, error } = useQuery(
        GET_LIST,
         { variables: { take: 1, key } },
    );
    return { loading, data: data?.contents?.at(0), error };
};


/**
 * Use content from server.
 */
const useContent = (id: string) => {
    const { loading, data, error } = useQuery(
        GET_CONTENT,
         { variables: { id } },
    );
    return { loading, data, error };
};

export default useContent;
