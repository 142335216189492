import { Advertisment, AdvertismentStatusType } from '../../Types/Client/graphql';
import {
    Chip,
    ListItemAvatar,
    ListItemText,
    ListItem as MuiListItem,
    Stack,
    Typography,
} from '@mui/material';

import React from 'react';
import Thumbnail from './Thumbnail';
import { adTitle } from '../../Lib/Util';
import { defaultFormat as dateFormat } from '../../Lib/Dates';
import defaultThumb from '../Advertisement/defaultThumbnail.png';

/**
 * Properties for {@link ListItem}
 */
export type ListItemProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * Pass a secondary option to the list item
     */
    secondaryAction?: React.ReactNode,

    /**
     * A row to add at the botton
     */
    lastRow?: React.ReactNode,

    /**
     * The item to display
     */
    item?: Advertisment | null,

    /**
     * Whether or not to show created date rathen than poster
     */
    showCreatedDate?: boolean
};

/**
 * List item for a post
 */
const ListItem = ({
    className,
    secondaryAction,
    item,
    lastRow,
    showCreatedDate,
}: ListItemProps) => {
    const activeChipProps = item?.status === AdvertismentStatusType.Active
        ? { label: 'aktiv', color: 'primary' } as const
        : { label: 'inaktiv', color: 'default'} as const;

    return (
        <MuiListItem
            alignItems="center"
            secondaryAction={secondaryAction}
            className={className}
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            <ListItemAvatar sx={{ marginRight: 1 }}>
                <Thumbnail
                    src={item?.thumbnail ?? defaultThumb}
                />
            </ListItemAvatar>
            <ListItemText
                primary={item ? adTitle(item) : '---'}
                primaryTypographyProps={{
                    variant: 'h4',
                    gutterBottom: true,
                }}
                secondary={
                    <Stack spacing={1}>
                        <Stack
                            direction="row"
                            spacing={0.5}
                            alignItems="center"
                        >
                            <Chip {...activeChipProps} size="small" />
                            <Typography
                                variant="body2"
                                color="text.primary"
                            >
                                Objekt: {item?.id ?? '1248192512'}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                            >
                                — {
                                    showCreatedDate
                                        ? `Erstellt am ${dateFormat(item?.createdAt)}`
                                        : `Ersteller: ${item?.user?.name}`
                                }
                            </Typography>
                        </Stack>
                        {lastRow}
                    </Stack>
                }
            />
        </MuiListItem>
    );
};

export default ListItem;
