import {
    Button,
    Card,
    CardContent,
    CardHeader,
    DialogActions,
    DialogContent,
    Stack,
    Theme,
} from '@mui/material';
import React, { useState } from 'react';

import Columns from '../Common/Columns';
import Dialog from '../Common/Dialog';
import { EditIcon } from '../Icon';
import LabelledValue from '../Common/LabelledValue';
import ProfileDataForm from './ProfileDataForm';
import ResponsiveStack from '../Common/ResponsiveStack';
import { fullSalutation } from '../../Lib/Util';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from '../../Hooks/useAuth';
import useUpdateUser from '../../Hooks/useUpdateUser';

/**
 * Responsive styles for {@link ProfileData}
 */
const useStyles = makeStyles((theme: Theme) => ({
    header: {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.main,
    },
    editBtn: {
        color: theme.palette.primary.main,
        marginTop: -4,
        marginBottom: -4,
        fontWeight: 'normal',
        '& .MuiSvgIcon-root': {
            width: 30,
            height: 30,
        },
    },
}));

/**
 * Properties for {@link ProfileData}
 */
export type ProfileDataProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,
};

/**
 * Logo with cube and word mark and subline
 */
const ProfileData = ({
    className,
}: ProfileDataProps) => {
    const classes = useStyles();
    const { authentication } = useAuth();
    const [open, setOpen] = useState(false);
    const { mutate, loading } = useUpdateUser();

    return (
        <Card className={className}>
            <CardHeader
                title={fullSalutation(authentication)}
                action={(
                    <Button
                        onClick={() => setOpen(true)}
                        endIcon={(<EditIcon />)}
                        size="small"
                        className={classes.editBtn}
                    >
                        Daten bearbeiten
                    </Button>
                )}
                className={classes.header}
            />
            <CardContent>
                <Columns breakpoint="md">
                    <ResponsiveStack spacing={1} direction="column" pb={1} desktopProps={{ pb: 0 }}>
                        <LabelledValue label="Email">
                            {authentication?.email}
                        </LabelledValue>
                        <LabelledValue label="Telefon">
                            {authentication?.phoneNumber ?? 'k.A.'}
                        </LabelledValue>
                    </ResponsiveStack>
                    <Stack spacing={1}>
                        <LabelledValue label="Benutzername">
                            {authentication?.name}
                        </LabelledValue>
                        <LabelledValue label="Mitarbeiternummer">
                            {authentication?.employeeId ?? 'n.v.'}
                        </LabelledValue>
                    </Stack>
                </Columns>
            </CardContent>

            <Dialog title="Daten bearbeiten" open={open} onClose={() => setOpen(false)} maxWidth="md">
                <DialogContent>
                    <ProfileDataForm onSubmit={mutate} htmlId="data-form" />
                </DialogContent>
                <DialogActions>
                    <Button form="data-form" type="submit" variant="contained" disabled={loading}>
                        Daten speichern
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default ProfileData;
