import Container from '../Common/Container';
import ContentDisplay from '../Content/Display';
import PageContainer from '../Common/PageContainer';
import QueryState from '../Common/QueryState';
import React from 'react';
import useContent from '../../Hooks/useContent';
import { useParams } from 'react-router-dom';

const Dashboard = () => {
    const { id } = useParams<{ id?: string }>();
    const { data, ...queryState } = useContent(id || '');

    return (
        <Container size="default" wrap={false}>
            <QueryState {...queryState}>
                <PageContainer back title={data?.content?.title ?? 'Lade...'}>
                    <ContentDisplay
                        document={data?.content?.content?.document}
                    />
                </PageContainer>
            </QueryState>
        </Container>
    );
}

export default Dashboard;
