import {
    Chip,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Stack,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';

import { AddIcon } from '../Icon';
import type { AdvertismentType } from '@apo/shared-config';
import LocationSelect from '../LocationSelector/Modal';
import { TreeItem } from '../../Hooks/useLocationsTree';
import { UserUserRoleType } from '../../Types/Client/graphql';
import { getSelectedRootsText } from '../LocationSelector/LocationsData';
import useAuth from '../../Hooks/useAuth';

/**
 * Type of filter data in component
 */
export type FilterData = Partial<{
    action: 'buy' | 'sell',
    type: AdvertismentType,
    location: Array<TreeItem>,
}>;


/**
 * Properties for {@link FilterForm} component.
 */
export type FilterFormProps = {
    /**
     * Initial data
     */
    initial?: FilterData,

    /**
     * Change listener
     */
    onChange?: (data: FilterData) => void,
};

/**
 * Display filter form.
 */
const FilterForm = ({
    initial = { action: 'buy', type: 'withLicense' },
    onChange = () => {},
}: FilterFormProps) => {
    const { authentication } = useAuth();
    const isSeller = authentication?.userRole === UserUserRoleType.Seller;
    const [modalOpen, setModalOpen] = useState(false);
    const [values, setValues] = useState<FilterData>({
        ...initial,
        action: isSeller ? initial.action : 'buy',
    });

    // Update single value in form data, also notify change listener
    const setValue = <TKey extends keyof FilterData>(key: TKey, value: FilterData[TKey]) => {
        setValues((prev) => {
            const updated = {
                ...prev,
                [key]: value,
            };
            onChange(updated);
            return updated;
        });
    };

    // Location selection
    const handleLocationSelect = (values: Array<TreeItem>) => {
        setValue('location', values);
        setModalOpen(false);
    };
    const locationValues = getSelectedRootsText(values.location || []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid md={4} xs={12} item>
                    <TextField
                        label="Suche"
                        placeholder="Suche"
                        margin="dense"
                        select
                        fullWidth
                        value={values.action}
                        onChange={(e) => { setValue('action', e.target.value as any); }}
                    >
                        <MenuItem value="buy">Inserate</MenuItem>
                        {isSeller && (<MenuItem value="sell">Interessenten</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid md={4} xs={12} item>
                    <TextField
                        label="Objekttyp"
                        placeholder="Objekttyp"
                        margin="dense"
                        select
                        fullWidth
                        value={values.type}
                        onChange={(e) => { setValue('type', e.target.value as AdvertismentType); }}
                    >
                        <MenuItem value="withLicense">Apothekenanteil mit Konzession</MenuItem>
                        <MenuItem value="withoutLicense">Apothekenanteil ohne Konzession</MenuItem>
                        <MenuItem value="lease">Pacht</MenuItem>
                    </TextField>
                </Grid>
                <Grid md={4} xs={12} item>
                    <TextField
                        label="Standort"
                        placeholder="Standort"
                        margin="dense"
                        fullWidth
                        onClick={() => setModalOpen(true)}
                        InputLabelProps={{ shrink: locationValues.length > 0 }}
                        inputProps={{ style: { flex: 0 } }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" component="label">
                                    <IconButton
                                        onClick={() => setModalOpen(true)}
                                        aria-label="Auswahlfenster öffnen"
                                    >
                                        <AddIcon color="inherit" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <Stack py={1} flexWrap="wrap" direction="row" flex={1} spacing={0.5} justifyContent="flexStart">
                                    {locationValues.map((text) => (<Chip label={text} />))}
                                </Stack>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            <LocationSelect
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSelect={handleLocationSelect}
            />
        </>
    );
}

export default FilterForm;
