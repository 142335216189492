import { TreeItem, TreeItemIndex } from 'react-complex-tree';
import { useEffect, useState } from 'react';

/**
 * Arguments for {@link useLocationSelection}
 */
export type UseLocationSelectionOptions = {
    /**
     * The tree item data
     */
    data: Record<TreeItemIndex, TreeItem<string>>,

    /**
     * Whether or not to allow multiple selection
     */
    multiple: boolean,

    /**
     * Initially selected items
     */
    initialSelection?: Array<TreeItemIndex>,
};

// Check if any child is selected. Currently only works one level deep.
const isChildSelected = (
    children: Array<TreeItemIndex> | undefined,
    selected: Array<TreeItemIndex>,
) => {
    return children !== undefined &&
        children.some((childIndex) => selected.includes(childIndex));
};

/**
 * Hook to manage location selection state. Intended to work with {@link react-complex-tree}.
 */
const useLocationSelection = ({
    data,
    multiple,
    initialSelection = [],
}: UseLocationSelectionOptions) => {
    // Internal state
    const [focusedItem, setFocusedItem] = useState<TreeItemIndex>();
    const [expandedItems, setExpandedItems] = useState<Array<TreeItemIndex>>([]);
    const [selectedItems, setSelectedItems] = useState<Array<TreeItemIndex>>(initialSelection);

    /**
     * Derive expanded items from selection, which is intended for purposes. Any selected element
     * is considered expanded if it contains children. There is no expansion without selection.
     */
    useEffect(() => {
        setExpandedItems(
            Object.values(data)
                .filter(({ index, children }) => (
                    (children?.length ?? 0) > 0
                        && (selectedItems.includes(index) || (multiple ? false : isChildSelected(children, selectedItems)))
                ))
                .map(({ index }) => index)
        );
    }, [selectedItems, data, multiple]);
    /**
     * Handle the selection to allow multiple selection
     */
    const handleSelection = (items: Array<any>) => {
        // Single selection, afaik there is always one item in items
        if (!multiple) {
            setSelectedItems(items);
            return;
        }

        // When parent is in toggle list unset all selected children
        const childrenToUnsetWhenParentToggled = items.flatMap((index) => (
            selectedItems.includes(index) ? data[index].children : []
        ));

        // Select only those newly selected and unselected already selected
        const updatedSelectItems = [...items, ...selectedItems].filter((index) => {
            // If item index is present in both arrays, it means unseleect
            return !selectedItems.includes(index) || !items.includes(index);
        }).filter((index) => !childrenToUnsetWhenParentToggled.includes(index));

        // Also implicitly select all parents when a child is selected
        const implicitlySelectedParents: any = Object.values(data)
            .filter((item) => (
                (item.children?.length ?? 0) > 0
                    && item.index !== 'root'
                    && !updatedSelectItems.includes(item.index)
                    && item.children?.some((childIndex) => updatedSelectItems.includes(childIndex))
            ))
            .map(({ index }) => (index));

        setSelectedItems([...updatedSelectItems, ...implicitlySelectedParents]);
    };

    return {
        handleSelection,
        focusedItem,
        setFocusedItem,
        expandedItems,
        selectedItems,
        updateValues: setSelectedItems,
    };
};

export default useLocationSelection;
