import {
    Breakpoint,
    Grid,
    GridProps,
} from '@mui/material';
import React, { Children } from 'react';

/**
 * Properties for {@link Columns}
 */
export type ColumnsProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * Breakpoint, minimal size when column layout is used. Down that breakpoint layout is stacked.
     */
    breakpoint?: Breakpoint,
} & Pick<GridProps, 'children' | 'spacing' | 'rowSpacing'>;

/**
 * Display a column layout. Each child will be displayed as a column down until breakpoint is hit,
 * at which point it resolves to being stacked.
 */
const Columns = ({
    className,
    breakpoint = 'sm',
    children,
    ...gridProps
}: ColumnsProps) => {
    const breakpointProps = { [breakpoint]: Math.floor(12 / Children.count(children)) };
    return (
        <Grid container className={className} {...gridProps}>
            {
                Children.map(children, (child) => (
                    <Grid item xs={12} {...breakpointProps}>
                        {child}
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default Columns;
