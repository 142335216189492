import {
    Box,
    Button,
    OutlinedInput,
    Theme,
} from '@mui/material';
import React, { useRef } from 'react';

import { makeStyles } from '@mui/styles';

/**
 * Styles used in {@link ChatMessageInput} component
 */
const useStyles = makeStyles(({ palette, spacing, shape }: Theme) => ({
    root: {
        backgroundColor: '#f4f4f4',
        marginRight: spacing(1),
        flex: 1,
    },
    notchedOutline: {
        borderColor: '#f4f4f4',
    },
    box: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: spacing(1),
    },
}));

/**
 * Properties for {@link ChatMessageInput}
 */
export type ChatMessageInputProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * Disable input
     */
    disabled?: boolean,

    /**
     * Handler for send click
     */
    onSend: (message: string) => void,
};

/**
 * Displays chat message input with send control
 */
const ChatMessageInput = ({
    className,
    disabled,
    onSend,
}: ChatMessageInputProps) => {
    const classes = useStyles();
    const input = useRef<HTMLInputElement>();
    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (input?.current?.value) {
            onSend(input.current.value);
            input.current.value = '';
        }
    };

    return (
        <Box className={[classes.box, className].join(' ')} component="form" onSubmit={handleSubmit}>
            <OutlinedInput
                placeholder="Nachricht..."
                inputRef={input}
                classes={classes}
                disabled={disabled}
                name="message"
            />
            <Button variant="contained" disabled={disabled} type="submit">senden</Button>
        </Box>
    );
};

export default ChatMessageInput;
