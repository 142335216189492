import AppLink from '../Common/AppLink';
import { ArrowBackIcon } from '../Icon';
import Container from '../Common/Container';
import ContentDisplay from '../Content/Display';
import React from 'react';
import { Theme } from '@mui/material';
import {
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import termsDocument from '../../Lang/DocumentTerms';
import { useColumnExtraStyles } from './ColumnLayout';

const useStyles = makeStyles((theme: Theme) => ({
    overline: {
        marginBottom: theme.spacing(2),
        textTransform: 'uppercase',
    },
    intro: {
        marginBottom: theme.spacing(4),
        '& ol[start]': {
            counterIncrement: 'olo',
            listStyle: 'none',
            '&> li::before': {
                content: 'counter(olo) ". "',
                float: 'left',
                marginLeft: '-40px',
            },
        },
        '& ul + ul': {
            paddingLeft: 80,
        },
    },
    sub: {
        counterReset: 'olc',
        listStyle: 'none',

        '& li': {
            counterIncrement: 'olc',
        },
        '& li::before': {
            content: 'counter(olo) "." counter(olc) ". "',
            float: 'left',
            marginLeft: '-40px',
        },
    },
    table: {
        border: '1px solid #444',
        borderCollapse: 'collapse',
        borderSpacing: 0,
        '& td': {
            border: '1px solid #444',
            padding: theme.spacing(0.25),
            '& ul': {
                marginTop: 0,
                marginBottom: 0,
                textAlign: 'left',
                '& li': {
                    textAlign: 'left',
                },
            },
        },
    },
}));

const LegalTerms = () => {
    const classes = {...useColumnExtraStyles(), ...useStyles() };

    return (
        <Container className={classes.intro} size="narrow" pageContainer>
            <Typography variant="h1" gutterBottom>Nutzungsbedingungen</Typography>
            <ContentDisplay document={termsDocument} />
            <div className={classes.bottomContainer}>
                <AppLink
                    variant="body1"
                    color="primary"
                    onClick={() => {}}
                    className={classes.moreInfo}
                    to="/"
                >
                    <ArrowBackIcon color="inherit" className={classes.linkIcon} />
                    Zurück
                </AppLink>
            </div>
        </Container>
    );
}

export default LegalTerms;
