import { FilterData, MAX_RANGE_PROFIT } from '../Components/Advertisement/FilterForm';
import { numberFormat, titleForTypes } from './Util';

import { GetUserProfilesQuery } from '../Types/Client/graphql';
import { TreeItem } from '../Hooks/useLocationsTree';
import { monthDisplay } from './Dates';

/**
 * Filter bubble display item type
 */
export type FilterBubbleItem = {
    /**
     * Optional label to identify category or value type
     */
    label?: string,

    /**
     * The value representation
     */
    valueLabel: string,

    /**
     * Matching filter key in filters
     */
    filterKey: keyof FilterData,

    /**
     * Subkey in filter data, if any
     */
    valueKey?: any,
};

// Intermediate types from graph ql querires
type ProfileQueryResult = GetUserProfilesQuery['searchProfiles'];
type BaseType = Exclude<ProfileQueryResult, undefined | null>[number];
export type ProfileParamsType = BaseType['searchParameters'];

/**
 * Get a filter object from use search profile parameters type
 */
export const searchParameterToFilters = (value?: ProfileParamsType): FilterData => {
    if (!value) {
        return {};
    }

    const filterData: FilterData = { type: value.type ?? "", location: [] };

    if (value.netSalesMin || value.netSalesMax) {
        filterData.profit = [
            value.netSalesMin ?? MAX_RANGE_PROFIT[0],
            value.netSalesMax ?? MAX_RANGE_PROFIT[1],
        ];
    }

    if (value.transferDateMin) {
        filterData.transferDate = value.transferDateMin;
    }

    if (value.states?.length) {
        value.states?.forEach(({ id, stateName }: any) => {
            const children = (value.regions?.length)
                ? (
                    value.regions
                        .filter(({ state }) => (state?.id === id))
                        .map(({ id, regionName }) => ({
                           id,
                           name: regionName ?? '',
                           type: 'region' as const,
                       }))
                )
                : [];
            filterData.location!.push({
                id,
                name: stateName ?? '',
                type: 'state' as const,
                children,
            });
        })
    }

    return filterData;
};

/**
 * Filter bubble value transformer
 */
export const filterBubblesFromFilter = (
    value: FilterData, isMulti: boolean = true,
): Array<FilterBubbleItem> => {
    const filterBubbles: Array<FilterBubbleItem> = [];

    if (value.type) {
        filterBubbles.push({
            label: 'Objekttyp',
            valueLabel: titleForTypes[value.type],
            filterKey: 'type',
        });
    }

    if (value.profit) {
        const valueLabel = isMulti
            ? `${numberFormat(value.profit[0])}-${numberFormat(value.profit[1])}`
            : `${numberFormat(value.profit[1])}`;
        filterBubbles.push({
            label: 'Umsatz',
            valueLabel,
            filterKey: 'profit',
        });
    }

    if (value.transferDate) {
        filterBubbles.push({
            label: 'Übernahme',
            valueLabel: monthDisplay(value.transferDate),
            filterKey: 'transferDate',
        });
    }

    if (value.location) {
        value.location.forEach((locationValue, i) => {
            filterBubbles.push({
                valueLabel: locationValue.name,
                filterKey: 'location',
                valueKey: i,
            });
        });
    }

    return filterBubbles;
};

/**
 * Serialize filtervalue to pass in url
 */
export const serialize = (filterValue: any) => {
    if (filterValue === null) { return ''; }
    if(typeof filterValue === 'object' || typeof filterValue === 'function') {
        if (filterValue instanceof Date) {
            return filterValue.toISOString();
        }
        return JSON.stringify(filterValue);
    }
    return `${encodeURIComponent(filterValue)}`;
};

/**
 * Parse filtervalue to read from serialized value
 */
export const parse = (filterValue: string) => {
    return /^[{["]/.test(filterValue)
        ? JSON.parse(filterValue)
        : (/^\d{4}-\d{2}-\d{2}/.test(filterValue) && !isNaN(Date.parse(filterValue)))
        ? new Date(Date.parse(filterValue))
        : decodeURIComponent(filterValue);
};

/**
 * Serialize all variables from dictionary
 */
export const serializeAll = (filterValues: Record<string, any>) => {
    return Object.fromEntries(
        Object.entries(filterValues)
            .filter(([key, value]) => (value !== null && value !== undefined))
            .map(([key, value]) => ([key, serialize(value)]))
    );
};

/**
 * Parse all variables from dictionary
 */
export const parseAll = (filterValues: URLSearchParams) => {
    return Object.fromEntries(
        Array.from(filterValues.entries())
            .filter(([key, value]) => (value.length > 0))
            .map(([key, value]) => ([key, parse(value)]))
    );
};

/**
 * Serialize object to url parameters directly
 */
export const serializeUrl = (filterValues: Record<string, any>) => {
    return new URLSearchParams(serializeAll(filterValues));
}

/**
 * Helper to get region filter ids
 */
export const getLocationTypeFilterIds = (type: 'state' | 'region', locations?: Array<TreeItem>) => {
    return locations?.filter((item) => item.type === type).map(({ id }) => ({ id }));
};
