import {
    Box,
    BoxProps,
} from '@mui/material';

import React from 'react';

const Spacer = ({ spacing = 2, horizontal = false }: { spacing?: BoxProps['padding'], horizontal?: boolean, }) => {
    return (
        <Box {...({[horizontal ? 'pl' : 'pt']: spacing})} />
    );
};
export default Spacer;
