import {
    SvgIconProps,
    Typography,
} from '@mui/material';

import React from 'react';
import Stack from '../Common/Stack';

/**
 * Properties for {@link KeyInfo} component
 */
export type KeyInfoProps = {
    /**
     * Info label
     */
    label: string,

    /**
     * Icon
     */
    icon: React.ComponentType<SvgIconProps>,

    /**
     * The value to display
     */
    value: string | React.ReactNode,
};

/**
 * Display an ads key info with its label and accompanying icon
 */
const KeyInfo = ({
    label,
    icon: IconCmp,
    value,
}: KeyInfoProps) => {
    return (
        <Stack dir="horizontal" spacing={0.75}>
            <IconCmp style={{ width: 48, height: 48 }} color="primary" />
            <Stack dir="vertical" spacing={0}>
                <Typography variant="caption" color="primary">{label}</Typography>
                <Typography variant="body1">
                    {value}
                </Typography>
            </Stack>
        </Stack>
    );
}

export default KeyInfo;
