import {
    Button,
    Checkbox,
    Divider,
    Grid,
    Link,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Theme,
    Typography,
} from '@mui/material';

import FormErrorDisplay from '../Common/FormErrorDisplay';
import LANG from '../../Lang/StaticTexts';
import { PasswordShowIcon } from '../Icon';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { rulesConfirm } from '../../Lib/RegistrationValidation';
import useValidatedForm from '../../Hooks/useValidatedForm';

/**
 * Data of form in {@link ConfirmForm}
 */
export type ConfirmFormData = {
    salutation: string,
    titlePrefix: string,
    titleSuffix: string,
    firstName: string,
    lastName: string,
    phoneNumber?: string,
    name: string,
    legalTerms?: string,
};

/**
 * Properties for {@link ConfirmForm} component
 */
export type ConfirmFormProps = {
    /**
     * Callback when registration form is submitted
     */
    onSubmit: (data: ConfirmFormData) => void,

    /**
     * Initial values
     */
    values?: Partial<ConfirmFormData>,
};

/**
 * Styles used in {@link ConfirmForm} component
 */
const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    formGroupHeading: {
        marginTop: spacing(1),
    },
    terms: {
        backgroundColor: palette.grey[100],
        marginTop: spacing(2),
        marginBottom: spacing(2),
        padding: spacing(2),
    },
    checkbox: {
        marginTop: spacing(-0.5),
    },
    formHelperProminent: {
        color: palette.info.main,
        fontWeight: '700',
        fontSize: 14,
    },
    iconVisible: {
        color: palette.info.main,
        fontSize: 55,
    },
}));

/**
 * A form to display to the use in order to confirm terms an complete his account.
 */
const ConfirmForm = ({
    onSubmit,
    values = {},
}: ConfirmFormProps) => {
    // Stylings
    const classes = useStyles();

    // Form state management
    const {
        getFieldProps, getFieldErrorProps, handleSubmit,
    } = useValidatedForm<ConfirmFormData>(rulesConfirm, { defaultValues: values });

    return (
        <Stack
            dir="vertical"
            justifyContent="space-between"
            component="form"
            onSubmit={handleSubmit((data) => onSubmit({ ...data }))}
            role="form"
        >
            <Typography variant="h5" gutterBottom>
                {LANG.confirmFormTitle}
            </Typography>
            <Typography variant="h2" gutterBottom>
                Nur noch 1 Schritt bis zur Apothekenbörse
            </Typography>
            <Typography variant="body2" gutterBottom>
                {LANG.confirmFormSubtitle}
            </Typography>
            <Grid container columnSpacing={2} rowSpacing={0}>
                <Grid item md={12} xs={12} pt={1.5} pb={1.5}>
                    <Divider />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography variant="h6" className={classes.formGroupHeading}>
                        Benutzerkonto
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        label="Anzeigename / Pseudonym"
                        fullWidth
                        {...getFieldProps('name')}
                    />
                </Grid>
                <Grid item md={8} xs={12}>
                    <Stack direction="row">
                        <PasswordShowIcon color="inherit" className={classes.iconVisible} />
                        <Stack dir="vertical" spacing={0}>
                            <Typography className={classes.formHelperProminent} variant="body2" pt={1}>
                                 Der Anzeigename ist für andere Benutzer sichtbar.
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                                Sie kommunizieren auf der Plattform anonym. Anderen Benutzern wird nur dieser
                                Anzeigename gezeigt.
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item md={12} xs={12} pt={1.5} pb={1.5}>
                    <Divider />
                </Grid>
                <Grid item md={12} xs={12} pb={1}>
                    <Typography variant="h6" className={classes.formGroupHeading}>
                        Überprüfen Sie ihre Daten
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Ihre persönlichen Daten sind für andere Benutzer nich sichtbar.
                    </Typography>
                </Grid>
                <Grid item md={2} xs={6}>
                   <TextField
                       label="Anrede"
                       select
                       fullWidth
                       {...getFieldProps('salutation')}
                   >
                       <MenuItem value="Herr">Herr</MenuItem>
                       <MenuItem value="Frau">Frau</MenuItem>
                       <MenuItem value=" ">Divers</MenuItem>
                   </TextField>
                </Grid>
                <Grid item md={2} xs={6}>
                    <TextField
                        label="Titel vorgestellt"
                        placeholder="Mag."
                        fullWidth
                        {...getFieldProps('titlePrefix')}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        label="Vorname"
                        placeholder="Vorname"
                        fullWidth
                        {...getFieldProps('firstName')}
                    />
                </Grid>
                <Grid item md={3} xs={8}>
                    <TextField
                        label="Nachname"
                        placeholder="Nachname"
                        fullWidth
                        {...getFieldProps('lastName')}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <TextField
                        label="Titel nachgestellt"
                        placeholder="Bsc."
                        fullWidth
                        {...getFieldProps('titleSuffix')}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        label="Telefonnummer"
                        type="tel"
                        fullWidth
                        {...getFieldProps('phoneNumber')}
                    />
                </Grid>
                <Grid item md={8} xs={12}>
                    <Typography color="text.secondary" variant="body2" pt={1}>
                        Hinterlegen Sie optional Ihre Telefonnummer, diese können Sie wenn gewünscht
                        bei einer Inserat-Schaltung übernehmen damit Sie direkt kontaktiert werden
                        können.
                    </Typography>
                </Grid>
            </Grid>

            <Paper className={classes.terms}>
                <Stack direction="row" alignItems="flext-start">
                    <Checkbox className={classes.checkbox} {...getFieldProps('legalTerms')} />
                    <Typography>
                        Ich habe die AGB (<Link target="_blank" href="/agb">hier geht es zu den AGB</Link>)
                        gelesen und akzeptiere diese. Ich nehme zur Kenntnis, dass ich Emails
                        erhalten werde, die wichtige Informationen und Aktiviäten meines
                        Benutzerkontos betreffen.
                    </Typography>
                </Stack>
                <FormErrorDisplay {...getFieldErrorProps('legalTerms')} />
            </Paper>
            <Button size="large" variant="contained" type="submit">
                Benutzerkonto bestätigen &amp; loslegen
            </Button>
        </Stack>
    );
};

export default ConfirmForm;
