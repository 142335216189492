import React, { useEffect } from 'react';

import Layout from './Layout/Layout';
import Routes from './Routes';

const App = () => {
    // Matomo tag manager, embedded if env is present
    useEffect(() => {
        const matomoUrl = process.env.REACT_APP_MATOMO_BACKEND_URL;
        if (matomoUrl) {
            // @ts-ignore
            var _mtm = window._mtm = window._mtm || [];
            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.src=matomoUrl; s.parentNode?.insertBefore(g,s);
        }
    }, []);

    return (
        <Layout>
            <Routes />
        </Layout>
    );
};

export default App;
