import { Button, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React, { forwardRef, useState } from 'react';

/**
 * A password field with visibility toggle
 */
const PasswordField = forwardRef<HTMLInputElement, TextFieldProps>(
    ({ InputProps, helperText, ...textFieldProps }, ref) => {
        // State of password
        const [showPassword, setShowPassword] = useState(false);
        const handleClickShowPassword = () => {
            setShowPassword(!showPassword);
        };

        return (
            <TextField
                {...textFieldProps}
                ref={ref}
                type={showPassword ? "text" : "password"}
                InputProps={{
                    ...InputProps,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                onClick={handleClickShowPassword}
                            >
                                {showPassword ? 'verstecken' : 'anzeigen'}
                            </Button>
                        </InputAdornment>
                    ),
                }}
            />
        );
    },
);

export default PasswordField;
