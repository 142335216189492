import { GET_IMAGES } from './useAdvertismentImages';
import { UPLOAD_IMAGE_BULK } from './useMutateAdvertisment';
import { gql } from '../Types/Client';
import { mergeStates } from '../Lib/Apollo';
import { useMutation } from '@apollo/client';

// Delete advertisment images mutation
export const DELETE_MULTI_IMAGES = gql(/* GraphQL */ `
    mutation DeleteAdvertismentImages($deleteAdvertismentImagesWhere: [AdvertismentImageWhereUniqueInput!]!) {
        deleteAdvertismentImages(where: $deleteAdvertismentImagesWhere) {
            id
        }
    }
`);

/**
 * Minimal current file data with marker for deletion
 */
export type CurrentFile = {
    id: string,
    sort: number,
    shouldDelete?: boolean,
};


/**
 * Use advertisment image mutation
 */
const useMutateAdvertismentImages = (id: string) => {
    const [uploadFiles, uploadState] = useMutation(UPLOAD_IMAGE_BULK, { refetchQueries: [GET_IMAGES] });
    const [deleteFiles, deleteState] = useMutation(DELETE_MULTI_IMAGES, { refetchQueries: [GET_IMAGES] });

    // Compose mutation state
    return {
        ...mergeStates(uploadState, deleteState),
        mutate: (current: CurrentFile[], upload: File[]) => {
            const maxSort = Math.max(0, ...current.map(({ sort }) => (sort)));
            const deletions = current.filter(({ shouldDelete }) => (shouldDelete === true))
                .map(({ id }) => ({ id }));

            ((deletions.length > 0) ? deleteFiles({
                variables: { deleteAdvertismentImagesWhere : deletions },
            }) : Promise.resolve())
                .then(() => {
                    // Error return plain
                    if (!upload?.length) {
                        return;
                    }

                    return uploadFiles({
                        variables: {
                            data: upload.map((foto, i) => ({
                                image: { upload: foto },
                                sort: `${i+1+maxSort}.0`,
                                advertisment: { connect: { id: id } },
                            })),
                        }
                    });
                });
        },
    };
};

export default useMutateAdvertismentImages;
