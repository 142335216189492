/**
* Terms document, follows the keystone editor structure. Generated by creating a document in the
* backend and copying its structure.
*/
const termsDocument = [
    {
        "type": "paragraph",
        "children": [
            {
                "text": "ALLGEMEINE NUTZUNGSBEDINGUNGEN DER WEBSITE ",
                "bold": true
            },
            {
                "type": "link",
                "href": "http://www.apothekenboerse.at",
                "children": [
                    {
                        "text": "www.apothekenboerse.at",
                        "bold": true
                    }
                ]
            },
            {
                "text": ""
            }
        ]
    },
    {
        "type": "paragraph",
        "textAlign": "center",
        "children": [
            {
                "text": ""
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Die Plattform "
            },
            {
                "text": "www.apothekenboerse.at",
                "bold": true
            },
            {
                "text": " wird vom Österreichischen Apothekerverband - Interessenvertretung der selbständigen Apotheker, 1090 Wien, Spitalgasse 31, als Diensteanbieter, Medieninhaber und Herausgeber (im Folgenden „"
            },
            {
                "text": "Betreiber“",
                "italic": true
            },
            {
                "text": ") betrieben."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "1. Präambel",
                "bold": true
            }
        ]
    },
    {
        "type": "ordered-list",
        "children": [
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Mit der Anmeldung als Nutzer (im Folgenden „Nutzer“) auf der Plattform des Betreibers, abzurufen unter "
                            },
                            {
                                "text": "https://www.apothekenboerse.at",
                                "bold": true
                            },
                            {
                                "text": " ",
                                "italic": true
                            },
                            {
                                "text": "(im Folgenden kurz: die Plattform)"
                            },
                            {
                                "text": " ",
                                "italic": true
                            },
                            {
                                "text": "akzeptieren Sie diese nachstehenden allgemeinen Nutzungsbedingungen für die Nutzung der Plattform (im Folgenden „ANB“). Davon abweichende Regelungen bedürfen der Schriftform. Als Nutzer sind ausschließlich natürliche Personen zugelassen. \n"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "2. Registrierung von Nutzern:",
                "bold": true
            }
        ]
    },
    {
        "type": "ordered-list",
        "children": [
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Bevor der Nutzer die Plattform nutzen kann, muss sich der Nutzer registrieren. Die Registrierung wird mit der Annahme dieser Nutzungsbedingungen abgeschlossen. Hiervon ausgenommen sind Mitglieder des Österreichischen Apothekerverbands, da diese bereits angelegt sind."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Die Registrierung erfolgt durch den Nutzer, wobei vom Betreiber je nach gewählter Rolle (Mitglied der Gehaltskasse oder Dritte) entsprechende Nachweise, zwecks Authentifizierung und Freischaltung des Nutzer-Kontos durch den Betreiber, gefordert werden. Ein solcher Nachweis ist bei ÖAV-Mitgliedern nicht notwendig."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Nutzer ist verpflichtet, jene Daten, die zur Registrierung notwendig sind, wahrheitsgemäß und vollständig dem Betreiber bekanntzugeben."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Sollten sich an den vom Nutzer bekanntgegeben Daten Änderungen ergeben, verpflichtet sich der Nutzer, diese Änderungen umgehend dem Betreiber bekannt zu geben."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Nutzer hat keinen Anspruch auf Registrierung. Durch die Registrierung erhält der Nutzer keinen Rechtsanspruch auf Nutzung der Plattform. Der Betreiber"
                            },
                            {
                                "text": " ",
                                "italic": true
                            },
                            {
                                "text": "behält sich das Recht vor, Nutzer ohne Angabe von Gründen nicht zu registrieren und/oder bestehende Nutzer zu löschen oder zu sperren. Allfällige Ansprüche des Nutzers daraus sind ausgeschlossen."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Nutzer verpflichtet sich, sein Passwort geheim zu halten und das Passwort nicht an Dritte weiterzugeben. Ebenso verpflichtet sich der Betreiber, das Passwort geheim zu halten."
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": ""
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "3. Rechte und Pflichten der Nutzer:",
                "bold": true
            }
        ]
    },
    {
        "type": "ordered-list",
        "children": [
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Nutzer verpflichtet sich, die Plattform nur innerhalb der technisch vorgesehenen Möglichkeiten zu nutzen. Der Nutzer verpflichtet sich, jegliche Handlungen zu unterlassen, die die Funktionalität oder den Betrieb der Plattform, sowie der Hard- und Software des Betreibers gefährden. Insbesondere sind dem Nutzer folgende Handlungen untersagt: \n\n- Schwachstellen des Systems oder des Netzwerks des Betreibers zu scannen oder zu testen\n- Sicherheitssysteme oder Zugangssysteme der angebotenen Dienstleistung zu umgehen\n- Schadprogramme in das System des Betreibers einzubinden.\n"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Nutzer hat das Recht, Informationen auf der Plattform einzusehen und zu verwenden. Eine entgeltliche Weitergabe an Dritte ist unzulässig."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Nutzer ist verpflichtet ausschließlich aktuelle, korrekte und vollständige Informationen (z.B. in Form von Inseraten) auf der Plattform zu teilen."
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": ""
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "4. Rechte und Pflichten des Betreibers:",
                "bold": true
            }
        ]
    },
    {
        "type": "ordered-list",
        "children": [
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Betreiber stellt sicher, dass die dem Nutzer mögliche Pseudonymisierung per selbstdefinierten Benutzernamen, nicht aufgehoben wird. Die Nutzung eines Pseudonyms als Benutzername auf der Plattform ist nur für Mitglieder des Apothekerverbands sowie Mitglieder der Österreichischen Gehaltskasse möglich."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Betreiber hat das Recht, die vom Nutzer bereitgestellten Daten auf jede erdenkliche Art zu nutzen und zu verwerten, sofern eine Rückführbarkeit auf einzelne Nutzer nicht möglich ist – hiervon ausgenommen ist das Recht auf kommerzielle Nutzung."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Betreiber verpflichtet sich die bereitgestellten Daten keiner kommerziellen Nutzung zuzuführen."
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": ""
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "5. Gewährleistungs- und Haftungsausschluss:",
                "bold": true
            }
        ]
    },
    {
        "type": "ordered-list",
        "children": [
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Betreiber"
                            },
                            {
                                "text": " ",
                                "italic": true
                            },
                            {
                                "text": "haftet weder für eigene Handlungen noch für Handlungen von Erfüllungsgehilfen (mit Ausnahme von Personenschäden), sofern diese nicht grob fahrlässig oder vorsätzlich verschuldet wurden. Der Ersatz von Schäden aller Art aus Ansprüchen Dritter ist ausgeschlossen.\n\nAlle Ansprüche des Nutzers sowie Schadenersatzansprüche wegen unmittelbarer und mittelbarer Schäden, Vermögensschäden und entgangenem Gewinn sind ausgeschlossen, sofern dem Betreiber"
                            },
                            {
                                "text": " ",
                                "italic": true
                            },
                            {
                                "text": "kein grobes Verschulden angelastet werden kann."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Betreiber"
                            },
                            {
                                "text": " ",
                                "italic": true
                            },
                            {
                                "text": "übernimmt keinerlei Haftung für Schäden, die aus einer Weitergabe der Daten des Nutzers oder Daten Dritter entstehen."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Betreiber"
                            },
                            {
                                "text": " ",
                                "italic": true
                            },
                            {
                                "text": "haftet weder für die Speicherung, die Übermittlung, noch die korrekte Darstellung der übertragenen Inhalte. Weiters übernimmt der Betreiber keine Haftung für Aktualität, Korrektheit oder Qualität sowie jegliche Auswirkungen durch Gebrauch oder Missbrauch der dargestellten Plattform.\n\nDer Betreiber"
                            },
                            {
                                "text": " ",
                                "italic": true
                            },
                            {
                                "text": "übernimmt schließlich keine Haftung und leistet keine Gewähr für die durchgehende Verfügbarkeit des Dienstes sowie für Schäden aus allfälligen Datenverlusten."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Betreiber haftet nicht für technisch bedingte Unterbrechungen, Netzausfälle oder sonstige Störungen des Portals. Die Haftung für Datenverlust ist ausgeschlossen. Sämtliche im Rahmen des Portals angebotenen Leistungen stehen im Ausmaß von 99%, gerechnet auf ein Jahr zur Verfügung (kurz: die Verfügbarkeit). Der Betreiber wird – sofern möglich – Wartungsarbeiten zwischen 20 Uhr und 6 Uhr Früh durchzuführen. Angekündigte Wartungsarbeiten schmälern die Verfügbarkeit nicht."
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "6. Anwendbares Recht und Gerichtsstand:",
                "bold": true
            }
        ]
    },
    {
        "type": "ordered-list",
        "children": [
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Es gilt österreichisches Recht. Die Anwendung des UN-Kaufrechts und von Verweisungsbestimmungen auf materielles ausländisches Recht ist ausgeschlossen. Für Streitigkeiten aus und im Zusammenhang mit diesen Bedingungen und/oder der Nutzung der Plattform"
                            },
                            {
                                "text": " ",
                                "italic": true
                            },
                            {
                                "text": "ist ausschließlich das für Wien-Innere Stadt sachlich zuständige Gericht zuständig."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Änderungen oder Ergänzungen dieser ANB bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für das Abgehen vom Schriftformerfordernis."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Sollten einzelne Bestimmungen dieser ANB nichtig, undurchsetzbar und/oder ungültig sein oder werden, hat dies nicht die Nichtigkeit, Undurchsetzbarkeit und/oder Ungültigkeit der gesamten ANB zur Folge hat. Die Parteien verpflichten sich für diesen Fall, anstelle der nichtigen, undurchsetzbaren und/oder ungültigen Bestimmungen eine Regelung zu vereinbaren, die dem mit der nichtigen, undurchsetzbaren und/oder ungültigen Regelung verfolgten Zweck wirtschaftlich am Nächsten kommt."
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": ""
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "7. Telefonische Kontaktaufnahme und Kontaktaufnahme per E-Mail:",
                "bold": true
            }
        ]
    },
    {
        "type": "ordered-list",
        "children": [
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Nutzer erklärt sich mit einer telefonischen Kontaktaufnahme, sowie der Kontaktaufnahme per E-Mail durch den Betreiber zu Zwecken der Information und Werbung über dessen Produkte und Produktweiterentwicklungen sowie über Neuheiten einverstanden."
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Der Nutzer kann diese Zustimmung jederzeit durch E-Mail an "
                            },
                            {
                                "text": "apothekenboerse@apothekerverband.at",
                                "bold": true
                            },
                            {
                                "text": " oder einen Anruf unter "
                            },
                            {
                                "text": "01/40414",
                                "bold": true
                            },
                            {
                                "text": " widerrufen."
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": ""
            }
        ]
    }
];

export default termsDocument;
