import {
    List,
} from '@mui/material';
import React from 'react';
import { TreeRenderProps } from  'react-complex-tree';

/**
 * Properties for {@link ListContainer}, not properly exported
 */
type ListContainerProps = Parameters<Required<TreeRenderProps>['renderItemsContainer']>[0];

/**
 * List container for items in list
 */
const ListContainer = ({ children, containerProps }: ListContainerProps) => {
    const { ref, ...passdProps } = containerProps;
    return (
        <List {...passdProps} disablePadding>
            {children}
        </List>
    );
};


export default ListContainer;
