import {
    IconButton,
    ImageListItem,
    Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { DeleteIcon } from '../Icon';
import { ImageType } from 'react-images-uploading';
import defaultThumbnail from '../Advertisement/defaultThumbnail.png';
import {
    makeStyles,
} from '@mui/styles';

/**
 * Properties for {@link QueuedFoto} component.
 */
export type QueuedFotoProps = {
    /**
     * Current filter values to display
     */
    image: ImageType,

    /**
     * Handler for removal of image
     */
    onRemove?: () => void,
};

/**
 * Styles used in {@link QueuedFoto} component.
 */
const useStyles = makeStyles({
    item: {
        position: 'relative',
        '& .MuiIconButton-root': {
            opacity: 0,
            transition: 'opacity 1s ease',
        },
        '&:hover .MuiIconButton-root': {
            opacity: 1,
        },
    },
    deleteButton: {
        position: 'absolute',
        bottom: 5,
        right: 5,
        backgroundColor: '#ffffff',
    },
    image: {
        borderRadius: 5,
        height: '120px !important',
        objectFit: 'cover',
    },
});

/**
 * Display current active fiters
 */
const QueuedFoto = ({
    image: { data_url },
    onRemove = () => {},
}: QueuedFotoProps) => {
    const classes = useStyles();
    return (
        <ImageListItem className={classes.item}>
            <img src={data_url} alt="Zum Upload gewähltes Bild" className={classes.image} />
            <Tooltip title="Bild entfernen">
                <IconButton onClick={() => onRemove()} className={classes.deleteButton}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </ImageListItem>
    );
}

export const FileQueuedFoto = ({ image, ...props }: QueuedFotoProps) => {
    const [currentDataUrl, setCurrent] = useState(defaultThumbnail);
    useEffect(() => {
        if (image.file) {
            const reader = new FileReader();
            reader.onload = () => setCurrent((reader.result as string) ?? defaultThumbnail);
            reader.readAsDataURL(image.file);
        }
    }, [image.file]);

    return (
        <QueuedFoto {...props} image={{ data_url: currentDataUrl }} />
    );
}

export default QueuedFoto;
