import { gql } from '../Types/Client';
import { useMutation } from '@apollo/client';

// Modify search profile mutation
export const REDEEM_PASSWORD_CODE = gql(/* GraphQL */ `
    mutation Mutation($email: String!, $token: String!, $password: String!) {
        redeemUserPasswordResetToken(email: $email, token: $token, password: $password) {
            code
        }
    }
`);

/**
 * Redemption data
 */
export type RedemptionData = {
    email: string,
    token: string,
    password: string,
};

/**
 * Error message for codes translated
 */
const errorMessages = {
    FAILURE: 'Der Code konnte nicht eingelöst werden. Überprüfen Sie den Code und die Email-Adresse.',
    TOKEN_EXPIRED: 'Der Code ist bereits abgelaufen. Bitte fordern Sie einen neuen Code an.',
    TOKEN_REDEEMED: 'Der Code wurde bereits eingelöst. Bitte fordern Sie einen neuen Code an.',
}

/**
 * Use mutation to request password link
 */
const useRequestPasswordLink = () => {
    const [mutate, mutationState] = useMutation(
        REDEEM_PASSWORD_CODE, { variables: { email: '', token: '', password: '' } },
    );

    // Compose mutation state
    return {
        ...mutationState,
        redeem: (data: RedemptionData) => (
            mutate({ variables: data })
                .then((response) => {
                    const responseCode = response?.data?.redeemUserPasswordResetToken?.code;
                    if (responseCode) {
                        return Promise.reject(new Error(errorMessages[responseCode]));
                    }
                    return true;
                })
        ),
    };
};

export default useRequestPasswordLink;
