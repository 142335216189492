import {
    Button,
    Divider,
    IconButton,
    Link,
    List,
    Stack,
} from '@mui/material';
import React, { Fragment } from 'react';

import { DeleteIcon } from '../Icon';
import InactiveLockItem from '../Post/InactiveLockItem';
import NoResultsMessage from '../Common/NoResultsMessage';
import PostListItem from '../Post/ListItem';
import QueryState from '../Common/QueryState';
import {
    makeStyles,
} from '@mui/styles';
import useDeleteUserBookmark from '../../Hooks/useDeleteUserBookmark';
import { useNavigate } from 'react-router-dom';
import useUserBookmarks from '../../Hooks/useUserBookmarks';

/**
 * Properties for {@link PostList}
 */
export type PostListProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,
};

/**
 * Styles used in {@link AgentItem} component.
 */
const useStyles = makeStyles({
    /* Display button as small link text */
    buttonLink: {
        fontWeight: 'normal',
        fontSize: '0.8rem',
    },
});

/**
 * Logo with cube and word mark and subline
 */
const PostList = ({
    className,
}: PostListProps) => {
    const { data, ...queryState } = useUserBookmarks();
    const { mutate } = useDeleteUserBookmark();
    const navigate = useNavigate();
    const posts = data?.bookmarks ?? [];
    const classes = useStyles();

    return (
        <List className={className}>
            {posts.map((item, i) => (
                <Fragment key={`post_list_item_${item?.id ?? i}`}>
                    {item.advertisment
                        ? (
                            <PostListItem
                                secondaryAction={(
                                    <Button onClick={() => navigate(`/inserat/${item?.advertisment?.id}`)}>
                                        zum Inserat &gt;
                                    </Button>
                                )}
                                lastRow={(
                                    <Stack
                                        direction="row"
                                        spacing={0.5}
                                    >
                                        <Link
                                            onClick={() => mutate(item!.id)}
                                            color="text.primary"
                                            className={classes.buttonLink}
                                            component="button"
                                        >
                                            Von Merkliste entfernen
                                        </Link>
                                    </Stack>
                                )}
                                item={item.advertisment}
                            />
                        ) : (
                            <InactiveLockItem secondaryAction={(
                                <IconButton aria-label="delete" onClick={() => mutate(item!.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            )} />
                        )
                    }
                    <Divider component="li"  />
                </Fragment>
            ))}

            <QueryState {...queryState} />
            <NoResultsMessage
                items={posts}
                detailMessage="Sie haben noch keine Inserate in Ihrer Merkliste. Starten Sie jetzt eine Suche um passende Objekte zu finden!"
                actionLabel="Suche starten"
                onAction={() => navigate('/inserate')}
            />
        </List>
    );
};

export default PostList;
