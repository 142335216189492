import {
    Stack,
    StackProps,
    Theme,
    useMediaQuery,
} from '@mui/material';

import React from 'react';

/**
 * Properties for {@link ResponsiveStack}
 */
export type ResponsiveStackProps = {
    /**
     * Props applied to desktop
     */
    desktopProps?: Partial<StackProps>,
} & StackProps;

/**
 * Default responsive stack. Defaults to a row on desktop.
 */
const ResponsiveStack: React.FC<ResponsiveStackProps> = ({
    desktopProps,
    direction = 'row',
    ...stackProps
}) => {
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const responsiveProps = matches ? { direction, ...desktopProps } : { direction: 'column' } as const;

    return (
        <Stack {...stackProps} {...responsiveProps} />
    );
};

export default ResponsiveStack;
