import { GET_ALL_BOOKMARKS } from './useUserBookmarks';
import { GET_BY_AD } from './useBookmark';
import { gql } from '../Types/Client';
import { useMutation } from '@apollo/client';
import useNotifications from './useNotifications';

// The query delete
export const DELETE_USER_BOOKMARK = gql(/* GraphQL */ `
    mutation DeleteUserBookmark($id: ID!) {
        deleteBookmark(where: { id: $id }) {
            id
        }
    }
`);

/**
 * Delete a users bookmark
 */
const useDeleteUserBookmark = () => {
    // Notifications and appropriate mutation
    const { addSuccess, addError } = useNotifications();

    // Mutation
    const [triggerMutation, { loading, data, error }] = useMutation(
        DELETE_USER_BOOKMARK, {
            variables: { id: '' },
            refetchQueries: [GET_ALL_BOOKMARKS, GET_BY_AD],
            onCompleted: () => addSuccess('Inserat von Merkliste entfernt'),
            onError: () => addError('Inserat konnte nicht von Merkliste entfernt werden'),
        },
    );
    const mutate = (id: string) => triggerMutation({ variables: { id }});
    return { mutate, loading, data, error };
};

export default useDeleteUserBookmark;
