import {
    Box,
    Divider,
    IconButton,
    Paper,
    Stack,
    Theme,
    Tooltip,
} from '@mui/material';

import { ArrowRightIcon } from '../Icon';
import ChatMessageInput from './ChatMessageInput';
import ChatMessages from './ChatMessages';
import ConversationItem from './ConversationItem';
import ListingEmpty from '../Common/ListingEmpty';
import React from 'react';
import { makeStyles } from '@mui/styles';
import useChat from '../../Hooks/useChat';
import { useNavigate } from 'react-router-dom';
import useScrollTrigger from '../../Hooks/useScrollTrigger';

/**
 * Styles used in {@link Chat} component
 */
const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    outer: {
        height: '100%',
        minHeight: '60vh',
        flexDirection: 'column',
        display: 'flex',
    },
    inner: {
        backgroundColor: palette.grey[100],
        flex: 1,
        padding: spacing(1),
        maxHeight: '50vh',
        overflowY: 'scroll',
    },
}));

/**
 * Properties for {@link Chat}
 */
export type ChatProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * Conversation id to load
     */
    id?: string,
};

/**
 * Displays chat interface
 */
const Chat = ({
    className,
    id,
}: ChatProps) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { messages, conversation, sendMessage, ...state } = useChat(id);
    const disableChatView = !conversation || state.loading || state.error !== undefined;

    const containerRef = useScrollTrigger<HTMLDivElement, Array<any>>(messages);

    return (
        <Box className={[classes.outer, className].join(' ')}>
            <Stack direction="row" spacing={2}>
                <Divider orientation="vertical" variant="middle" />
                {
                    conversation
                        ? (
                            <ConversationItem
                                conversation={conversation}
                                component="div"
                                divider={false}
                                disableGutters
                                ContainerComponent="div"
                                secondaryAction={
                                    conversation.advertisment
                                        ? (
                                            <Tooltip title="zum Inserat">
                                                <IconButton onClick={() => navigate(`/inserat/${conversation.advertisment?.id}`)}>
                                                    <ArrowRightIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                        : undefined
                                }
                                ContainerProps={{ style: { flex: 1 }}}
                            />
                        )
                        : (
                            <ListingEmpty component="div" message="Konversation auswählen" items={null} />
                        )
                }

            </Stack>
            <Paper className={classes.inner} square ref={containerRef}>
                <ChatMessages messages={messages ?? []} />
            </Paper>
            <ChatMessageInput disabled={disableChatView} onSend={sendMessage} />
        </Box>
    );
};

export default Chat;
