import { TreeItem as CTreeItem, TreeItemIndex } from 'react-complex-tree';
import useLocationsTree, { TreeItem } from '../../Hooks/useLocationsTree';

import { useMemo } from 'react';

/**
 * Turn to item data source entry for tree
 */
const toItemEntry = ({ name, id, type, children }: TreeItem): any => {
    const derivedId = `${type}-${id}`;
    return [
        [derivedId, {
            index: derivedId,
            data: name,
            canRename: false,
            canMove: false,
            isFolder: (children?.length ?? 0) > 0,
            children: children?.map(({ type, id }: TreeItem) => (`${type}-${id}`)) ?? [],
        }],
        ...(children?.flatMap(toItemEntry) ?? []),
    ];
};

export const toComplexTreeData = (tree: Array<TreeItem>) => {
    // Derive from tree data soruce
    const allItems = Object.fromEntries(tree.flatMap(toItemEntry));
    const rootChildren = tree.map(({ type, id }: TreeItem) => (`${type}-${id}`));

    /**
     * Export with a root node
     */
    const data: Record<string, CTreeItem<string>> = {
        root: {
            index: 'root',
            canMove: false,
            isFolder: true,
            children: rootChildren,
            data: 'Root',
            canRename: false,
        },
        ...allItems,
    };

    return data;
};

/**
 * Use the data as needed by complex tree
 * @return [description]
 */
const useLocationTreeData = () => {
    const { data, ...state } = useLocationsTree();
    const flattened = useMemo(() => toComplexTreeData(data ?? []), [data]);

    return { data: flattened, ...state };
};

export default useLocationTreeData;

/**
 * Helper function, check if item has children
 */
const hasChildren = ({ children }: { children?: Array<any> }) => (children && children.length > 0);

/**
 * Get data of selected roots
 */
export const getSelectionFromIndices = (
    selected: Array<TreeItemIndex>, data: Record<string, CTreeItem<string>>,
): Array<TreeItem> => {
    let allParents = selected.filter((index) => hasChildren(data[index]));
    const allSelectedChildren = selected.filter((index) => (!allParents.includes(index))) as Array<TreeItemIndex>;

    // In single selection mode the parent might not be selected but only a child
    if (allParents.length < 1) {
        // Try to get optional parent
        allParents = Object.values(data)
            .filter((item) => (item.children?.some((childId) => (selected.includes(childId)))))
            .map(({ index }) => (index));
    }

    // Reconvert to tree item data
    return allParents.map((index) => {
        const item = data[index];
        const selectedChildren = item.children
            ?.filter((childIndex) => allSelectedChildren.includes(childIndex))
            ?.map((childIndex) => {
                const child = data[childIndex];
                return {
                    id: `${child.index}`.replace('region-', ''),
                    name: child.data,
                    type: 'region' as const,
                };
            });

        return {
            id: `${item.index}`.replace('state-', ''),
            name: item.data,
            type: 'state' as const,
            children: selectedChildren,
        };
    });
};

/**
 * Get data of selected roots
 */
export const getSelectedRoots = (selected: Array<TreeItem>) => {
    return selected.map((item) => {
        const selectedChildrenCount = item.children?.length ?? 0;
        return { label: item.name, childCount: selectedChildrenCount };
    });
};

/**
 * Get text for all selected root items
 */
export const getSelectedRootsText = (selected: Array<TreeItem>) => {
    return getSelectedRoots(selected)
        .map(({ label, childCount }) => `${label}: ${childCount > 0 ? childCount : 'Alle'}`);
};
