import { gql } from '../Types/Client';
import { useQuery } from '@apollo/client';

// The query to get all filtered
export const GET_ONE_BY_ID = gql(/* GraphQL */ `
    query GetAdvertismentById($id: ID!) {
        advertisment(where: { id: $id }) {
            createdAt
            description
            grossProfit
            id
            name
            operatingResult
            netSales
            operatingTurn
            status
            thumbnail
            transferDate
            type
            region { id, regionName }
            state { id, stateName, abbreviation }
            updatedAt
            user {
                id, name, userSource
            }
        }
    }
`);

/**
 * Use advertisment from server.
 */
const useAdvertisment = (id: number) => {
    const { loading, data, error } = useQuery(
        GET_ONE_BY_ID,
        { variables: { id: `${id}` }, fetchPolicy: 'cache-and-network' },
    );
    return { loading, data, error };
};

export default useAdvertisment;
