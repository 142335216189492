import {
    Button,
    Card,
    CardContent,
    Container,
    Typography,
} from '@mui/material';

import ErrorBrowserHelp from './Components/Common/ErrorBrowserHelp';

type ErrorProps = {
  error: Error
  resetErrorBoundary: () => void
};

const hideErrorMessage = process.env.NODE_ENV === 'production';

function AppError({
    error,
    resetErrorBoundary,
}: ErrorProps) {
    const containerStyles = {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <Container maxWidth="sm" style={containerStyles}>
            <Card raised={false}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>
                        Leider ist ein Fehler aufgetreten
                    </Typography>
                    {!hideErrorMessage && (
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {error.message}
                        </Typography>
                    )}
                    <Typography gutterBottom>
                        Es tut uns leid, die Seite ist leider abgestürzt. Versuchen sie bitte die
                        Seite neu zu laden und die Aktion noch einmal auszuführen.
                    </Typography>
                    <ErrorBrowserHelp />
                    <Button onClick={resetErrorBoundary} variant="contained" color="primary">
                        Zurück zur Webseite
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
}

export default AppError;
