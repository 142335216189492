import {
    Box,
} from '@mui/material';
import React from 'react';

/**
 * Properties for {@link Thumbnail}
 */
export type ThumbnailProps = {
    /**
     * The source of the image component
     */
    src: string,
};

/**
 * User area
 */
const Thumbnail = ({
    src,
}: ThumbnailProps) => {
    return (
        <Box sx={{ borderRadius: 10 }}>
            <img src={src} style={{ width: 160, height: 100, objectFit: 'cover', borderRadius: 5 }} alt="Thumbnail von Apothekenfoto" />
        </Box>
    );
};

export default Thumbnail;
