import { DocumentRenderer, DocumentRendererProps } from '@keystone-6/document-renderer';
import {
    Stack,
    Theme,
    Typography,
} from '@mui/material';

import Link from '../Common/AppLink';
import React from 'react';
import Youtube from '../Embed/Youtube';
import {
    makeStyles,
} from '@mui/styles';
import { renderComponentBlocks } from '@apo/shared-config';

/**
 * Definitions of one or more youtube regexes to find embedded youtube links and transform to videos
 */
const YOUTUBE_EMBED_REGEX = [
    /https:\/\/youtu\.be\/([A-ZA-z0-9-]+)(\?|$).*/,
];

/**
 * Stylings used in {@link AllFotos} component.
 */
const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
    contentDisplay: {
        '& p:first-child': {
            marginTop: 0,
        },
        '& p:last-child': {
            marginBottom: 0,
        },
        '& .MuiTypography-h3': {
            textTransform: 'uppercase',
        },
        '& p': {
             whiteSpace: 'pre-wrap',
        },
    },
}));

/**
 * Create the renderers for the relationships
 */
const renderers: DocumentRendererProps['renderers'] = {
    inline: {
        relationship: ({ relationship, data }) => {
            if (relationship === 'content') {
                if (data === null || data.data === undefined) {
                  return <del>[Link: nicht mehr verfügbarer Inhalt]</del>
                } else {
                  return <><Link to={`/content/${data.data.id}`}>{data.data.title}</Link>{' '}</>;
                }
            }
            if (relationship === 'download') {
                if (data === null || data.data === undefined || !data.data.file?.url) {
                  return <del>[Download: nicht mehr verfügbarer Inhalt]</del>
                } else {
                  return <><Link to={data.data.file.url} target="_blank">{data.data.name}</Link>{' '}</>;
                }
            }
            return null;
        },
        link: ({ children, href }) => {
            // If any youtube embed matches
            const matchYoutubeIndex = YOUTUBE_EMBED_REGEX.findIndex((regex) => regex.test(href));
            if (matchYoutubeIndex > -1) {
                const youtubeMatch = href.match(new RegExp(YOUTUBE_EMBED_REGEX[matchYoutubeIndex]));
                console.log("Link", href);
                if (youtubeMatch && youtubeMatch[1]) {
                    return <Youtube embedId={youtubeMatch[1]} />;
                }
            }

            const target = href.startsWith('http') ? { target: '_blank' } : {};
            return <Link to={href} variant="inherit" {...target}>{children}</Link>;
        },
    },
    block: {
        heading: ({ level, children, textAlign }) => {
            return <Typography variant={`h${level}`} style={{ textAlign }} gutterBottom>{children}</Typography>;
        },
        paragraph: ({ children, textAlign }) => {
            return <Typography variant="body1" style={{ textAlign }} gutterBottom>{children}</Typography>;
        },
    },
};

/**
 * Properties of {@link Display} component
 */
export type DisplayProps = {
    /**
     * The document to display
     */
    document: any,
};

/**
 * Display of content document as rendered output
 */
const Display = ({
    document: cmsDoc,
}: DisplayProps) => {
    const classes = useStyles();
    return (
        <Stack spacing={2} className={classes.contentDisplay}>
            {cmsDoc
                ? (
                    <DocumentRenderer
                        document={cmsDoc}
                        renderers={renderers}
                        componentBlocks={renderComponentBlocks}
                    />
                )
                : <Typography>Keine Inhalte</Typography>}
        </Stack>
    );
}

export default Display;
