import {
    Button,
    Stack,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import DescriptionForm, { FormData as DescriptionData } from './DescriptionForm';
import FotoForm, { FormData as FotoData } from './FotoForm';
import MainForm, { FormData as MainData } from './MainForm';

import { ApolloType } from '../../Lib/Apollo';
import React from 'react';
import useSteppedForm from '../../Hooks/useSteppedForm';

/**
 * Form data managed in stepped form
 */
export type FormData = MainData & DescriptionData & FotoData;

/**
 * Properties for {@link Form} component.
 */
export type FormProps = {
    /**
     * Handler when form is submitted
     */
    onSubmit: (values: FormData) => void,

    /**
     * Initial data to edit
     */
    edit?: ApolloType<FormData>,

    /**
     * Form id, use `<button form="[id-goes-here]" />` to submit form from outside form
     */
    id: string,
};

/**
 * Labels used for steps
 */
const stepLabels = {
    main: 'Inserat',
    description: 'Beschreibung',
    fotos: 'Fotos',
} as const;

/**
 * Display form for creating a new ad.
 *
 * Note: {@link memo} is nescessary because otherwise if `onValuesChange` changes state in the
 * parent component, the component itself is rerendered, causing {@link useChangeEffect} to fire
 * and causing an infite lopp and this poor performance or crash.
 */
const Form = ({ onSubmit, id, edit }: FormProps) => {
    // Form state management
    const {
        activeStep,
        steps,
        Current,
        componentProps,
        buttons,
        isLastStep,
    } = useSteppedForm<
        ['main', 'description', 'fotos'],
        { main: MainData, description: DescriptionData, fotos: FotoData}
    >({
        stepComponents: {
            main: MainForm,
            description: DescriptionForm,
            fotos: FotoForm,
        },
        // @ts-ignore
        onSubmit: (steppedData) => onSubmit({
            ...steppedData.main,
            ...steppedData.description,
            ...steppedData.fotos,
        }),
        completeLabel: 'Inserat erstellen',
        defaultValues: {
            // @ts-ignore
            main: edit ?? {},
            // @ts-ignore
            description: edit ?? {},
            // @ts-ignore
            fotos: edit ?? {},
        },
    });

    return (
        <Stack
            justifyContent="space-between"
            id={id}
            spacing={2}
        >
            <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                    <Step key={label}>
                        {/* @ts-ignore */}
                        <StepLabel>{stepLabels[label]}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {/* @ts-ignore */}
            <Current {...componentProps} />

            <Typography variant="body2" color="text.secondary">
                *Pflichtfelder
            </Typography>
            <Stack
                direction="row"
                justifyContent="space-between"
            >
                <Button {...buttons[0]}>{buttons[0].label}</Button>
                <Button
                    {...buttons[1]}
                    type="submit"
                    variant={isLastStep ? 'contained' : 'text'}
                >
                    {buttons[1].label}
                </Button>
            </Stack>
        </Stack>
    );
};

export default Form;
