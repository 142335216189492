import {
    FieldError,
} from 'react-hook-form';

/**
 * All rules and messages
 */
export type RuleKey = 'required' | 'min' | 'max' | 'minLength' | 'maxLength' | 'pattern' | 'validate';
const messageTemplates: Record<RuleKey, string> = {
    required: 'Pflichteingabe. Bitte geben Sie einen Wert an.',
    min: 'Wert muss mindestens %d betragen',
    max: 'Wert muss höchstens %d betragen',
    minLength: 'Der Wert muss eine Mindestlänge von %d haben',
    maxLength:  'Der Wert muss eine Maximallänge von %d haben',
    pattern: 'Format stimmt nicht mit dem erwarteten Format überein.',
    validate: 'Format stimmt nicht mit dem erwarteten Format überein.',
};

/**
 * Get error message for a rule
 */
const messageForRule = (rule: string, ruleValue: any) => {
    const template: string = Object.keys(messageTemplates).includes(rule)
        // @ts-ignore
        ? messageTemplates[rule]
        : undefined;

    if (!template || ruleValue?.errorMessage) {
        return typeof ruleValue === 'string' ? ruleValue : (ruleValue?.errorMessage || 'Ungültiger Wert.');
    }

    return template.replace(/%([ds]{1})/g, (match, number) => {
        return `${ruleValue}`;
    });
};

/**
 * Get an error message for an error
 */
export const getErrorMessage = (error?: FieldError, fieldRules?: Record<any, any>) => (
    error
        ? (
            error.message?.length
                ? error.message
                : messageForRule(error.type, fieldRules?.[error.type])
        )
        : undefined
);
