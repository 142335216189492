import {
    Button,
    Card,
    CardContent,
    Stack,
    Theme,
} from '@mui/material';
import ColumnLayout, { Column, useColumnExtraStyles } from './ColumnLayout';

import { ArrowBackIcon } from '../Icon';
import CMSContentDisplay from '../Content/CMSContentDisplay';
import Container from '../Common/Container';
import CubeLogo from '../../Layout/CubeLogo';
import Link from '../Common/AppLink';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

/**
 * Styles used in {@link About} component.
 */
const useStyles = makeStyles((theme: Theme) => ({
    infoColumn: {
        minHeight: 'auto',
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
        },
    },
    button: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    logo: {
        marginLeft: theme.spacing(-3),
    },
    header: {
        backgroundColor: theme.palette.primary.main,
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        color: theme.palette.primary.contrastText,
    },
}));

/**
 * Sign in form with info messages
 */
const About = ({ loginHelp = false }: { loginHelp?: boolean }) => {
    const classes = { ...useColumnExtraStyles(), ...useStyles() };
    const navigate = useNavigate();
    return (
        <Container wrap={false} pageContainer>
            <Card>
                <div className={classes.header}>
                    <CubeLogo className={classes.logo} />
                </div>
                <CardContent>
                    <CMSContentDisplay contentKey={loginHelp ? 'login-help' : 'info'} hideTitle />
                </CardContent>
                <ColumnLayout columnAlign="center">
                    <Column className={classes.infoColumn}>
                        <Link
                            variant="body1"
                            color="primary"
                            onClick={() => {}}
                            className={classes.moreInfo}
                            to="/"
                        >
                            <ArrowBackIcon color="inherit" className={classes.linkIcon} />
                            Zurück
                        </Link>
                    </Column>
                    <Column className={classes.infoColumn}>
                        <Stack alignItems="center">
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => navigate('/')}
                                className={classes.button}
                            >
                                jetzt einloggen
                            </Button>
                        </Stack>
                    </Column>
                </ColumnLayout>
            </Card>
        </Container>
    );
}

export default About;
