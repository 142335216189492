import {
    Chip,
    IconButton,
    InputAdornment,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { AddIcon } from '../Icon';
import type { AdvertismentType } from '@apo/shared-config';
import LocationSelect from '../LocationSelector/Modal';
import MonthPicker from '../Common/MonthPicker';
import PickerField from '../Common/PickerField';
import SelectField from '../Common/SelectField';
import Slider from '../Common/Slider';
import { TreeItem } from '../../Hooks/useLocationsTree';
import { getSelectedRootsText } from '../LocationSelector/LocationsData';
import { monthDisplay as monthFormat } from '../../Lib/Dates';
import { numberFormat } from '../../Lib/Util';

/**
 * Maximum range for profit
 */
export const MAX_RANGE_PROFIT = [0, 10000000];
const isMax = (profit?: number[]) =>
    (profit && profit[0] === MAX_RANGE_PROFIT[0] && profit[1] === MAX_RANGE_PROFIT[1]);

/**
 * Type of filter data in component
 */
export type FilterData = Partial<{
    type: AdvertismentType | '',
    transferDate: Date,
    location: Array<TreeItem>,
    profit: number[],
    excludeRegions: Array<TreeItem>,
}>;


/**
 * Properties for {@link FilterForm} component.
 */
export type FilterFormProps = {
    /**
     * Initial data
     */
    values?: FilterData,

    /**
     * Change listener
     */
    onChange?: (data: FilterData) => void,

    /**
     * Flag if multiple location and profit range are allowed selection should be enabled
     */
    multiple?: boolean,
};

/**
 * Display filter form.
 */
const FilterForm = ({
    values: propValues = {},
    onChange = () => {},
    multiple = false
}: FilterFormProps) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [values, setValues] = useState<FilterData>({ profit: MAX_RANGE_PROFIT, ...propValues });
    const [sliderValues, setSliderValues] = useState(MAX_RANGE_PROFIT);
    useEffect(() => {
        setValues({ ...propValues, profit: propValues.profit ?? MAX_RANGE_PROFIT });
        setSliderValues(propValues.profit ?? MAX_RANGE_PROFIT);
    }, [propValues])

    // Update single value in form data, also notify change listener
    const setValue = <TKey extends keyof FilterData>(key: TKey, value: FilterData[TKey]) => {
        setValues((prev) => {
            const updated = {
                ...prev,
                [key]: value,
            };
            onChange({
                ...updated,
                profit: (isMax(updated.profit) ? undefined : updated.profit),
            });
            return updated;
        });
    };

    // Location selection
    const handleLocationSelect = (values: Array<TreeItem>) => {
        setValue('location', values);
        setModalOpen(false);
    };

    const handleSliderChange = (event: any, newValue: number | number[]) => {
        setSliderValues(multiple ? newValue as number[] : [0, newValue as number]);
    };
    const commitSliderChange = () => {
        setValue('profit', sliderValues);
    };

    const locationValues = getSelectedRootsText(values.location || []);

    return (
        <>
            <Stack spacing={0}>
                <SelectField
                    label="Objekttyp"
                    placeholder="Objekttyp"
                    margin="dense"
                    select
                    SelectProps={{ displayEmpty: true }}
                    value={values.type}
                    onChange={(e) => { setValue('type', e.target.value as AdvertismentType); }}
                >
                    <MenuItem value="withLicense">Apothekenanteil mit Konzession</MenuItem>
                    <MenuItem value="withoutLicense">Apothekenanteil ohne Konzession</MenuItem>
                    <MenuItem value="lease">Pacht</MenuItem>
                </SelectField>
                <PickerField
                    label="Übernahmezeitpunkt"
                    placeholder="Übernahmezeitpunkt"
                    margin="dense"
                    fullWidth
                    Picker={MonthPicker}
                    onValueChange={(monthValue) => { setValue('transferDate', monthValue); }}
                    // @ts-ignore
                    valueDisplay={(value) => (value ? monthFormat(value) : '')}
                    value={values.transferDate}
                />
                <TextField
                    label="Standort"
                    placeholder="Standort"
                    margin="dense"
                    onClick={() => setModalOpen(true)}
                    InputLabelProps={{ shrink: locationValues.length > 0 }}
                    inputProps={{ style: { flex: 0 } }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" component="label">
                                <IconButton
                                    onClick={() => setModalOpen(true)}
                                    aria-label="Auswahlfenster für Standort öffnen"
                                >
                                    <AddIcon color="inherit" />
                                </IconButton>
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <Stack py={1} flexWrap="wrap" direction="row" flex={1} gap={0.25} justifyContent="flexStart">
                                {locationValues.map((text) => (<Chip label={text} />))}
                            </Stack>
                        ),
                    }}
                />

                <Stack direction="row" alignItems="center">
                    <Typography color="text.secondary" variant="h5">
                        Umsatz
                    </Typography>
                    <Stack p={1} spacing={0.5} flex={1}>
                        <Slider
                            getAriaLabel={() => 'profit range'}
                            value={multiple ? sliderValues : sliderValues?.[1]}
                            onChange={handleSliderChange}
                            onChangeCommitted={commitSliderChange}
                            valueLabelDisplay="off"
                            step={100000}
                            max={MAX_RANGE_PROFIT[1]}
                        />
                        <Stack spacing={0} justifyContent="space-between" direction="row" width="100%">
                            <Typography color="text.secondary">
                                {!multiple ? null : (numberFormat(sliderValues?.[0] ?? 0))}
                            </Typography>
                            <Typography color="text.secondary">
                                {numberFormat(sliderValues?.[1] ?? 0)}
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <LocationSelect
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSelect={handleLocationSelect}
                multiple={multiple}
                values={values.location}
            />
        </>
    );
}

export default FilterForm;
