import { AuthContextProvider, Credentials } from '../Hooks/useAuth';

import Loading from '../Components/Public/Loading';
import useAuthenticate from '../Hooks/useAuthenticate';
import useEndSession from '../Hooks/useEndSession';
import useInitialAuthentication from '../Hooks/useInitialAuthentication';

/**
 * The wrapped provider for authentication with {@link @apollo/client}
 */
const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { authenticate } = useAuthenticate();
    const { data, loading } = useInitialAuthentication();
    const authenticateAndStore = (credentials: Credentials) => {
        return authenticate(credentials)
            .then(successfulResponse => {
                return successfulResponse?.item;
            });
    };
    const { end } = useEndSession();

    // Load while checking initial login status
    if (loading) {
        return <Loading />;
    }

    return (
        <AuthContextProvider
            onSignIn={authenticateAndStore}
            onSignOut={() => end().then(() => {})}
            initial={data?.authenticatedItem}
        >
            {children}
        </AuthContextProvider>
    );
};

export default Providers;
