import {
    List,
    ListItem,
    Skeleton,
} from '@mui/material';

import React from 'react';

/**
 * Loading indicator
 */
const ListContainer = () => (
    <List disablePadding aria-label="Daten werden geladen">
        <ListItem aria-hidden><Skeleton width="100%" /></ListItem>
        <ListItem aria-hidden><Skeleton width="100%" /></ListItem>
        <ListItem aria-hidden><Skeleton width="100%" /></ListItem>
        <ListItem aria-hidden><Skeleton width="100%" /></ListItem>
        <ListItem aria-hidden><Skeleton width="100%" /></ListItem>
        <ListItem aria-hidden><Skeleton width="100%" /></ListItem>
        <ListItem aria-hidden><Skeleton width="100%" /></ListItem>
        <ListItem aria-hidden><Skeleton width="100%" /></ListItem>
        <ListItem aria-hidden><Skeleton width="100%" /></ListItem>
    </List>
);


export default ListContainer;
