import {
    AppBar,
    Badge,
    IconButton,
    Toolbar,
} from '@mui/material';
import { NavProfileIcon, SignOutIcon } from '../Components/Icon';
import React, { useCallback } from 'react';

import AppBarSpacer from './AppBarSpacer';
import CubeLogo from './CubeLogo';
import Link from '../Components/Common/AppLink';
import ResponsiveMenu from './ResponsiveMenu';
import { UserUserRoleType } from '../Types/Client/graphql';
import { useAppBarStyles } from './Styles';
import useAuth from '../Hooks/useAuth';
import {
    useNavigate,
} from 'react-router-dom';
import useNewMessageCount from '../Hooks/useNewMessageCount';

/**
 * Layouts header, display an app bar and a global menu.
 */
const Header = () => {
    // History is used
    const navigate = useNavigate();
    const goToHome = useCallback(() => navigate('/home'), [navigate]);
    const classes = useAppBarStyles();
    const { isAuthenticated, signOut, authentication } = useAuth();
    const { total } = useNewMessageCount();

    // No header when not authenticated
    if (!isAuthenticated) { return null; }
    const role = authentication?.userRole;

    // Main UI
    return (
        <>
            <AppBarSpacer />
            <AppBar position="absolute" color="primary">
                <Toolbar>
                    <CubeLogo onClick={goToHome} />
                    <ResponsiveMenu>
                        <Link to="/home" className={classes.navItem}>
                            Marktplatz
                        </Link>
                        {
                            role !== UserUserRoleType.Seller ? null : (
                                <Link to="/erstellen" className={classes.navItem}>
                                    Inserat erstellen
                                </Link>
                            )
                        }
                        <Link to="/nachrichten" className={classes.navItem}>
                            <Badge color="secondary" badgeContent={total}>
                                <span className="badge-content">Postfach</span>
                            </Badge>
                        </Link>
                        <div className={classes.iconMenu}>
                            <IconButton
                                onClick={() => navigate('/benutzer')}
                                color="inherit"
                                className={classes.navIcon}
                                aria-label="Zum Benutzerkonto"
                            >
                                <NavProfileIcon color="inherit" className={classes.icon} />
                            </IconButton>
                            <IconButton
                                onClick={() => signOut().then(() => navigate('/'))}
                                color="inherit"
                                className={classes.navIcon}
                                aria-label="Zum Postfach"
                            >
                                <SignOutIcon color="inherit" className={classes.icon} />
                            </IconButton>
                        </div>
                    </ResponsiveMenu>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Header;
