import ImageUploading, {
    ImageListType,
    ImageUploadingPropsType,
} from 'react-images-uploading';

import React from 'react';

/**
 * Properties for {@link FotoUpload} component.
 */
export type FotoUploadProps = {
    /**
     * Change listener
     */
    onChange: (fotos: Array<File>) => void,

    /**
     * Content render component
     */
    Content: React.FC<Parameters<Exclude<ImageUploadingPropsType['children'], undefined>>[0]>,
};

/**
 * Foto upload container
 */
const FotoUpload = ({ Content, onChange }: FotoUploadProps) => {
    const [images, setImages] = React.useState<ImageListType>([]);
    const maxNumber = 8;

    const changeHandler = (imageList: ImageListType, addUpdateIndex?: number[]) => {
        // data for submit
        setImages(imageList);
        onChange(imageList.map(({ file }) => (file!)));
    };

    return (
        <div className="App">
            <ImageUploading
                multiple
                value={images}
                onChange={changeHandler}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                maxFileSize={2000000}
            >
                {(props) => (<Content {...props} />)}
            </ImageUploading>
        </div>
    );
}

export default FotoUpload;
