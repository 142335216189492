import {
    Link,
    Typography,
} from '@mui/material';

import React from 'react';

/**
 * Error browser info display component
 */
const ErrorBrowserHelp: React.FC<{}> = (): React.ReactElement<any, any> => (
    <>
        <Typography gutterBottom>
            Sollte der Fehler bestehen bleiben versuchen Sie die Seite noch einmal im
            Inkognito-Modus bzw. Privaten Modus zu besuchen. Siehe:
        </Typography>
        <Typography gutterBottom>
            <Link href="https://support.mozilla.org/de/kb/privater-modus" target="_blank">
                Firefox: Privater Modus
            </Link>
            {' | '}
            <Link href="https://support.google.com/chrome/answer/95464?hl=de&co=GENIE.Platform%3DDesktop" target="_blank">
                Chrome: Inkognito Modus
            </Link>
            {' | '}
            <Link href="https://support.microsoft.com/de-de/microsoft-edge/inprivate-browsen-in-microsoft-edge-cd2c9a48-0bc4-b98e-5e46-ac40c84e27e2" target="_blank">
                Edge: InPrivate Modus
            </Link>
        </Typography>
    </>
);

export default ErrorBrowserHelp;
