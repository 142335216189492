import React, { useState } from 'react';

import { Button } from '@mui/material';
import Dialog from './Dialog';

/**
 * Request a callback, button with attached dialog.
 */
const RequestButton = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button
                variant="contained"
                size="large"
                sx={{ minWidth: '25%' }}
                onClick={() => setOpen(true)}
            >
                Jetzt Rückruf vereinbaren
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                onSubmit={() => {}}
            />
        </>
    );
};

export default RequestButton;
