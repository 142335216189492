import { Navigate, useNavigate } from 'react-router-dom';
import {
    Stack,
    Typography,
} from '@mui/material';

import ConfirmForm from '../User/ConfirmForm';
import Container from '../Common/Container';
import FormState from '../Common/FormState';
import FormStateMessage from '../Common/FormStateMessage';
import { NavProfileIcon } from '../Icon';
import React from 'react';
import { UserUserStateType } from  '../../Types/Client/graphql';
import useAuth from '../../Hooks/useAuth';
import useUpdateUser from '../../Hooks/useUpdateUser';

const UserAgents = () => {
    const { authentication } = useAuth();
    const navigate = useNavigate();
    const { mutate, ...state } = useUpdateUser();

    if (authentication?.userState === UserUserStateType.Active) {
        return (<Navigate to="/home" replace />);
    } 

    if (authentication?.userState !== UserUserStateType.PendingProfile) {
        return (
            <Container size="default">
                <Stack spacing={2}>
                    <NavProfileIcon style={{ width: 76, height: 76 }} />
                    <Typography variant="h2">
                        Konto nicht freigeschalten
                    </Typography>
                    <Typography variant="body1">
                        Diese Konto ist nicht freigeschalten. Bitte warten Sie auf ihre Freischaltung
                        durch einen Mitarbeiter des Service-Teams. Sie werden per Mail benachrichtigt
                        sobald ihr Account freigeschalten ist.
                    </Typography>
                </Stack>
            </Container>
        );
    }

    const { id, __typename, ...authData } = authentication;
    return (
        <Container size="default">
            <FormState {...state}
                Component={FormStateMessage}
                componentProps={{
                    loadingMessage: 'Daten werden gespeichert',
                    completedMessage: 'Benutzerkonto wurde aktiviert',
                    completedActionLabel: 'weiter',
                    onAction: () => navigate('/home', { replace: true}),
                }}
            >
                <ConfirmForm
                    // @ts-ignore
                    values={authData}
                    onSubmit={({ legalTerms, ...accountData }) => {
                        mutate({
                            ...accountData,
                            email: authentication.email!,
                         })
                    }}
                />
            </FormState>
        </Container>
    );
}

export default UserAgents;
