import { isValidGHKPNum as isValidMemberNumber } from './Mod11';
/**
 * Match a password value against all policies
 */
export const matchPasswordPolicies = (value?: string) => ({
    hasUppercase: (value?.match(/[A-Z]/) && true) ?? false,
    hasLowercase: (value?.match(/[a-z]/) && true) ?? false,
    hasNumber: (value?.match(/[0-9]/) && true) ?? false,
    hasLength: (value?.length || 0) >= 8,
});

/**
 * Check if a password is valid when it matches all policies
 */
export const isValidPassword = (value?: string) => (
    Object.values(matchPasswordPolicies(value)).every((result) => (result === true))
);

/**
 * Check if a provided value is a valid email address input
 */
export const isValidEmail = (value?: string) => (
    String(value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) ? true : false
);
isValidEmail.errorMessage = 'Bitte geben Sie eine gültige Email-Adresse an.';

/**
 * Check if a password is valid when it matches all policies
 */
export const isValidFile = (value?: File) => (
    value && typeof value === 'object' && value instanceof File
);

/**
 * Check for a valid username
 */
export const isValidUsername = (value?: string, contextValues?: Record<string, unknown>) => (
    (value && /^[a-z0-9_]{3,}$/i.test(value) && (
        !contextValues || !contextValues.lastName || !containsPartial(value, contextValues.lastName as string)
    )) ? true : false
);
isValidUsername.errorMessage = 'Mindestens 3 Zeichen, ohne Sonderzeichen, darf keine Teile Ihres Nachnamens enthalten.';

/**
 * Check if a string contains any partial of another string
 */
export const containsPartial = (value: string, match: string, length: number = 4) => {
    // Must be at least correct length
    if (value.length < length || match.length < length) {
        return false;
    }

    // Get all partial substring of according length
    const allPartials = new Array(match.length - length + 1)
        .fill(1)
        .map((_, i) => (match.toLowerCase().substring(i, length + i)));

    return allPartials.some((partial) => value.toLowerCase().includes(partial));
};

/**
 * Rules applied to person profile data
 */
export const rulesProfile = {
    // Profile essentials
    salutation: { required: true },
    firstName: { required: true },
    lastName: { required: true },
};

/**
 * Rules applied to confirm form
 */
export const rulesConfirm = {
    ...rulesProfile,
    name: { required: true, validate: isValidUsername },
    legalTerms: { required: true },
};

/**
 * Rules applied to all foreign (unknown) peoply
 */
export const rulesDefault = {
    // Account essentials
    password: { required: true, validate: isValidPassword },
    email: { required: true, validate: isValidEmail  },
    legalTerms: { required: true },
    document: { required: true },

    // Profile
    ...rulesProfile,

    // Company
    company: { required: true },
    jobRole: { required: true },
};

export const rulesPharmacists = {
    // Account essentials
    name: { required: true, validate: isValidUsername },
    password: { required: true, validate: isValidPassword },
    email: { required: true, validate: isValidEmail  },
    legalTerms: { required: true },
    document: { required: true },

    // Profile
    ...rulesProfile,

    // Emplyee id
    employeeId: { required: true, validate: isValidMemberNumber },
};

export const rulesRedeemPassword = {
    email: { required: true, validate: isValidEmail  },
    password: { required: true, validate: isValidPassword },
    token: { required: true },
};

export const rulesForgotPassword = {
    email: { required: true, validate: isValidEmail  },
};
