import {
    ListItemButton,
} from '@mui/material';
import React from 'react';
import { TreeRenderProps } from  'react-complex-tree';

/**
 * Properties for {@link ListItem}, not properly exported
 */
type ListItemProps = Parameters<Required<TreeRenderProps>['renderItem']>[0];

/**
 * Render a list item
 */
const ListItem = ({
    title, arrow, depth, context, children
}: ListItemProps) => {
    // Extract incompatible html props
    const {
        action,
        ref,
        ...props
    } = { ...context.itemContainerWithoutChildrenProps, ...context.interactiveElementProps };

    return (
        <>
            <ListItemButton
                {...props}
                disableGutters
                dense
                sx={{ paddingLeft: depth }}
            >
                {arrow}
                {title}
            </ListItemButton>
            {children}
        </>
    );
};


export default ListItem;
