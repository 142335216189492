import {
    Alert,
    Button,
    Stack,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';
import useRedeemPasswordCode, { RedemptionData } from '../../Hooks/useRedeemPasswordCode';

import PasswordField from './PasswordField';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import { rulesRedeemPassword } from '../../Lib/RegistrationValidation';
import useForm from '../../Hooks/useValidatedForm';

/**
 * Properties for {@link PasswordResetForm} component
 */
export type PasswordResetFormProps = {
    /**
     * A code passed to populate reset code
     */
    code?: string,

    /**
     * An email address passed to form
     */
    email?: string,

    /**
     * Callback on successful password reset
     */
    onSuccess?: () => void,
};

/**
 * Sign in form with info messages
 */
const PasswordResetForm = ({ code, email, onSuccess }: PasswordResetFormProps) => {
    const { redeem, loading } = useRedeemPasswordCode();
    const { getFieldProps, handleSubmit, watch, reset } = useForm<RedemptionData>(
        rulesRedeemPassword,
        { defaultValues: { token: code, email } },
    );
    const password = watch('password');
    const [error, setError] = useState<string>();

    return (
        <Stack
            spacing={1}
            alignItems="flex-end"
            component="form"
            onSubmit={handleSubmit((data) => {
                redeem(data)
                    .then(() => onSuccess?.())
                    .catch((e) => setError(e.message));
                reset();
            })}
            role="form"
        >
            <TextField
                label="Email"
                fullWidth
                {...getFieldProps('email')}
            />
            <TextField
                label="Bestätigungs-Code"
                fullWidth
                {...getFieldProps('token')}
            />

            <Stack width="100%" style={{ marginTop: 8 }}>
                <PasswordField
                    label="neues Passwort"
                    placeholder="neues Passwort"
                    required
                    fullWidth
                    margin="dense"
                    {...getFieldProps('password')}
                />
                <PasswordStrengthIndicator value={password} />
            </Stack>
            {error ? (<Alert severity="error">{error}</Alert>) : null}
            <Button
                variant="contained"
                type="submit"
                disabled={loading}
            >
                Passwort zurücksetzen
            </Button>
        </Stack>
    );
}

export default PasswordResetForm;
