/* eslint-disable */
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentProps,
} from '@mui/material';
import React, { PropsWithChildren, ReactNode } from 'react';

import Dialog from './Dialog';

/**
 * Properties for {@link FormDialog} component
 */
export type FormDialogProps = PropsWithChildren<{
    /**
     * Dialog opened state control
     */
    open?: boolean,

    /**
     * Callback fired when close is requested
     */
    onClose: () => void,

    /**
     * Title displayed in FormDialog
     */
    title: string | ReactNode,

    /**
     * Properties passed to dialog content element
     */
    dialogContentProps?: DialogContentProps,

    /**
     * Either a button label or a custom node indicating that is should be a dialog with a
     * {@link DialogActions} element appended.
     */
    action?: string | ReactNode;

    /**
     * When action is a string you can specify a html for attribute for the rendered {@link BUtton}
     * element here. It will also receive `type="submit"`
     */
    actionSubmit?: string;

    /**
     * Action handler if action is a string
     */
    onAction?: () => void,

    /**
     * Size of dialog
     */
    size?: 'sm' | 'md' | 'lg',
}>;

/**
 * Helper function to get submit attributes
 */
const submitAttrs = (form?: string) => (
    form ? { type: 'submit' as const, form } : {}
);

/**
 * FormDialog location selection component
 */
const FormDialog = ({
    open = true,
    title,
    onClose,
    dialogContentProps,
    action,
    onAction,
    actionSubmit,
    size = 'sm',
    children,
}: FormDialogProps) => {
    const actionContent = !action ? null : (
        typeof action === 'string'
            ? (
                <Button variant="contained" onClick={onAction} {...submitAttrs(actionSubmit)}>
                    {action}
                </Button>
            )
            : action
    );

    return (
        <Dialog open={open} fullWidth maxWidth={size} onClose={onClose} title={title}>
            <DialogContent {...dialogContentProps}>
                {children}
            </DialogContent>
            {actionContent === null ? null : (
                <DialogActions>
                    {actionContent}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default FormDialog;
