import {
    Chip,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { ClassificationTuple, TypesByDataType, classificationSchema } from '@apo/shared-config';
import React, { useState } from 'react';

import { AddIcon } from '../Icon';
import { ApolloType } from '../../Lib/Apollo';
import { Controller } from 'react-hook-form';
import LocationSelect from '../LocationSelector/Modal';
import MonthPicker from '../Common/MonthPicker';
import { NumericFormat } from 'react-number-format';
import PickerField from '../Common/PickerField';
import SelectField from '../Common/SelectField';
import Stack from '../Common/Stack';
import { TreeItem } from '../../Hooks/useLocationsTree';
import { monthDisplay } from '../../Lib/Dates';
import useForm from '../../Hooks/useValidatedForm';
import { valueAsNumber } from '../../Lib/Form';

/**
 * Intermediate types
 */
type ClassificationData<T extends Record<string, any>> = {
    [TKey in keyof T]: TypesByDataType[T[TKey]['dataType']]
};
type ClassDataKey = keyof typeof classificationSchema;

/**
 * Data from inputs in component
 */
export type FormData = {
    type: 'withLicense' | 'withoutLicense' | 'lease',
    state?: TreeItem,
    region?: TreeItem,
} & Partial<ClassificationData<typeof classificationSchema>>;

/**
 * Validation rules by field
 */
const validationRules: Partial<Record<keyof FormData, any>> = {
    type: {
        required: true,
    },
    state: {
        required: true,
    },
    transferDate: {
        valueAsDate: true,
    },
    operatingTurn: {
        min: 0,
        max: 12,
    },
    grossProfit: {
        min: 0,
        max: 100,
        setValueAs: valueAsNumber,
    },
    operatingResult: {
        setValueAs: valueAsNumber,
    },
    netSales: {
        setValueAs: valueAsNumber,
    },
};


/**
 * Properties for {@link Form} component.
 */
export type FormProps = {
    /**
     * Handler when form is submitted
     */
    onSubmit: (values: FormData) => void,

    /**
     * Initial data to edit
     */
    values?: ApolloType<FormData>,

    /**
     * Form id, use `<button form="[id-goes-here]" />` to submit form from outside form
     */
    id: string,

    /**
     * When true disables all validations
     */
    disableValidation?: boolean,
};

/**
 * Field props per defined data type
 */
const fieldPropsPerType = {
    text: {},
    date: {
        valueDisplay: (value?: Date) => (value ? monthDisplay(value) : 'ab sofort'),
        parse:  (strValue?: string) => {
            const parts = strValue?.split('/');
            if (!strValue || parts?.length !== 2) {
                return undefined;
            }
            const date = new Date(parseInt(parts[1]), parseInt(parts[0]), 1);
            // @ts-ignore Check according to https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
            return isFinite(date) ? date : undefined;
        }
    },
    number: {  },
    percentage: {
        InputProps: {
            endAdornment: (
                <InputAdornment position="end">%</InputAdornment>
            )
        },
    },
};

/**
 * Display form for creating a new ad.
 */
const Form = ({ onSubmit, id, values, disableValidation = false }: FormProps) => {
    // Form state management
    const { getFieldProps, handleSubmit, setValue, watch, control } = useForm<FormData>(
        disableValidation ? {} : validationRules, { values: values as FormData }
    );

    // Location selection
    const [modalOpen, setModalOpen] = useState(false);
    const locationValues = watch(['state', 'region'])
    const handleLocationSelect = (values: Array<TreeItem>) => {
        setValue('state', values[0]);
        setValue('region', values[0]?.children?.[0]);
        setModalOpen(false);
    };

    return (
        <Stack
            dir="vertical"
            justifyContent="space-between"
            component="form"
            id={id}
            onSubmit={handleSubmit((data) => {
                // we need to manually transform operatingTurn data, because selects cannot have
                // value transforms in react-hook-form.
                return onSubmit({ ...data, operatingTurn: (valueAsNumber(data.operatingTurn) ?? null) });
            })}
            role="form"
        >
            <div>
                <Typography variant="subtitle1">Apotheke</Typography>
            </div>
            <SelectField
                label="Ich biete"
                placeholder="Objekttyp"
                margin="dense"
                select
                displayEmpty
                {...getFieldProps('type')}
            >
                <MenuItem value="withLicense">Apothekenanteil mit Konzession</MenuItem>
                <MenuItem value="withoutLicense">Apothekenanteil ohne Konzession</MenuItem>
                <MenuItem value="lease">Pacht</MenuItem>
            </SelectField>


            <Typography variant="body2" color="text.secondary">
                Geben Sie Kennzahlen Ihrer Apotheke an. Je mehr Kennzahlen Sie angeben desto besser
                wird Ihr Inserat in der Suche gefunden.
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                    <TextField
                        label="Standort"
                        placeholder="Standort"
                        margin="dense"
                        onClick={() => setModalOpen(true)}
                        fullWidth
                        InputLabelProps={{ shrink: locationValues.length > 0 }}
                        inputProps={{ style: { flex: 0 }, 'aria-readonly': true }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setModalOpen(true)}
                                        aria-label="Auswahlfenster für Standort öffnen"
                                    >
                                        <AddIcon color="inherit" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <Stack py={0.75} flexWrap="wrap" dir="horizontal" flex={1} spacing={0.5} justifyContent="flexStart">
                                    {locationValues.map((value) => (
                                        value ? (<Chip label={value.name} />) : null
                                    ))}
                                </Stack>
                            ),
                        }}
                        {...getFieldProps('state')}
                        // Void native input change events, handled entirely in modal
                        onChange={() => {}}
                        onBlur={() => {}}
                    />
                </Grid>
                {Object.entries(classificationSchema).map(([key, conf]) => {
                    // Some workarounds to narrow correct types from generic strings/objects
                    const itemConf = conf as ClassificationTuple<any>;
                    const dataKey = key as ClassDataKey;
                    return (
                        <Grid item md={4} sm={6} xs={12} key={key}>
                            {
                                itemConf.selection
                                    ? (
                                        <SelectField
                                            key={key}
                                            label={conf.label}
                                            placeholder={conf.label}
                                            margin="dense"
                                            fullWidth
                                            select
                                            {...getFieldProps(dataKey)}
                                        >
                                            <MenuItem value="">k.A.</MenuItem>
                                            {
                                                itemConf.selection.map(({ label, value }) => (
                                                    <MenuItem value={value} key={value}>{label}</MenuItem>
                                                ))
                                            }
                                        </SelectField>
                                    )
                                    :
                                    (itemConf.type === 'date'
                                        ? (
                                            <Controller
                                                key={key}
                                                name={dataKey}
                                                control={control}
                                                render={({ field: { value } }) => (
                                                    <PickerField
                                                        key={key}
                                                        label={conf.label}
                                                        placeholder={conf.label}
                                                        margin="dense"
                                                        fullWidth
                                                        Picker={MonthPicker}
                                                        {...fieldPropsPerType[itemConf.type]}
                                                        {...getFieldProps(dataKey)}
                                                        onValueChange={(v) => {
                                                            setValue(dataKey, v);
                                                        }}
                                                        value={value}
                                                    />
                                                )}
                                            />

                                        )
                                        : (itemConf.type === 'number'
                                            ? (
                                                <Controller
                                                    key={key}
                                                    name={dataKey}
                                                    control={control}
                                                    render={({ field: { value } }) => (
                                                        <NumericFormat
                                                            decimalSeparator=","
                                                            thousandSeparator="."
                                                            decimalScale={0}
                                                            isAllowed={({ floatValue }) => (floatValue || 0) <= 999999999}
                                                            allowNegative={false}
                                                            onValueChange={
                                                                ({ floatValue }) => setValue(dataKey, floatValue)
                                                            }
                                                            inputProps={{ style: { textAlign: 'right' }}}
                                                            fullWidth
                                                            value={value}
                                                            {...getFieldProps(dataKey)}
                                                            {...fieldPropsPerType[itemConf.type]}
                                                            customInput={TextField}
                                                            label={conf.label}
                                                            placeholder={conf.label}
                                                            margin="dense"
                                                        />
                                                    )}
                                                />

                                            )
                                            : (
                                                <TextField
                                                    key={key}
                                                    label={conf.label}
                                                    placeholder={conf.label}
                                                    margin="dense"
                                                    fullWidth
                                                    {...fieldPropsPerType[itemConf.type]}
                                                    {...getFieldProps(dataKey)}
                                                />
                                            )))
                            }
                        </Grid>
                    );
                })}
            </Grid>
            <LocationSelect
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSelect={handleLocationSelect}
            />
        </Stack>
    );
};

export default Form;
