import {
    Divider,
    List,
    ListItem,
} from '@mui/material';

import React from 'react';
import ResultItem from './ResultItem';
import { SearchProfilesQuery } from '../../Types/Client/graphql';

/**
 * Properties for {@link Listing} component.
 */
export type ListingProps = {
    /**
     * Search profile results to display in listing
     */
    results: Exclude<SearchProfilesQuery['searchProfiles'], null | undefined>,

    /**
     * When a listing is selected
     */
    onContact: (id: string) => void,
};

/**
 * Show a listing of user search agents
 */
const Listing = ({
    results,
    onContact,
}: ListingProps) => {
    return (
        <List>
            {results.map((result) => (
                <>
                    <Divider component="li" />
                    <ListItem style={{ paddingTop: 20, paddingBottom: 20 }}>
                        <ResultItem item={result} onContact={() => onContact(result.id)}/>
                    </ListItem>
                </>
            ))}
        </List>
    );
};

export default Listing;
