import { Conversation, ConversationWhereInput } from '../Types/Client/graphql';
import { useApolloClient, useMutation } from '@apollo/client';

import { GET_CONVERSATIONS } from './useConversations';
import { gql } from '../Types/Client';
import useAuth from './useAuth';
import { useState } from 'react';

// The query to get all for user
export const GET_EXISTING_CONVERSATION = gql(/* GraphQL */ `
    query GetExistingConversation($where: ConversationWhereInput!) {
        conversations(where: $where) {
            id
        }
    }
`);


// The query to get all for user
export const GET_SEARCH_PROFILE_USER = gql(/* GraphQL */ `
    query GetSearchProfileUser($id: ID!) {
        searchProfile(where: { id: $id }) {
            user { id }
        }
    }
`);

export const CREATE_CONVERSATION = gql(/* GraphQL */`
    mutation CreateConversation($data: ConversationCreateInput!) {
        createConversation(data: $data) {
            id
        }
    }
`);

/**
 * Creation input
 */
export type CreateInput = {
    advertismentId?: never,
    searchProfileId: string,
} | {
    advertismentId: string,
    searchProfileId?: never,
};

/**
 * Creation input derived from
 */
type DerivedCreateInput = {
    advertismentId?: never,
    userId: string,
    recipientId: string,
} | {
    advertismentId: string,
    userId: string,
    recipientId?: never,
};

/**
 * Turn input into where
 */
const whereFromInput = (input: DerivedCreateInput) => {
    const userIdEquals = { id: { equals: input.userId } };
    const variables: Array<ConversationWhereInput> = [{
        OR: [{ "recipient": userIdEquals }, { "user": userIdEquals }],
    }];
    if (input.advertismentId) {
        variables.push({ advertisment: { id: { equals: input.advertismentId } } });
    } else {
        const profileIdEquals = { id: { equals: input.recipientId } };
        variables.push({
            OR: [
                { "recipient": profileIdEquals, "user": userIdEquals },
                { "user": profileIdEquals, "recipient": userIdEquals }
            ],
        });
        variables.push({ advertisment: null });
    }
    return variables;
};

/**
 * Use a query or create operation to create a new conversation or fetch existing
 */
const useCreateConversation = () => {
    const apolloClient = useApolloClient();
    const { authentication } = useAuth();
    const [mutate, mutationState] = useMutation(
        CREATE_CONVERSATION,
        { refetchQueries: [GET_CONVERSATIONS] },
    );
    const [loading, setLoading] = useState(false);

    /**
     * Handle complex creation
     */
    const handleCreate = (input: CreateInput): Promise<Conversation> => {
        // Only only request at a time
        if (mutationState.loading || loading) {
            return Promise.reject(new Error('already loading'));
        };

        // Local loading state
        setLoading(true);

        // Restart if already done
        mutationState.reset();

        // Create the conversation equality
        let promise: Promise<DerivedCreateInput>;
        if (input.advertismentId) {
            promise = Promise.resolve({
                advertismentId: input.advertismentId,
                userId: authentication!.id,
            });
        } else {
            promise  = apolloClient.query({
                query: GET_SEARCH_PROFILE_USER ,
                variables: { id: input.searchProfileId! },
                fetchPolicy: 'network-only',
            })
                .then(({ data }) => {
                    if (!data || !data?.searchProfile?.user?.id) {
                        throw Error('invalid profile');
                    }
                    return data.searchProfile.user.id;
                })
                .then((profileUserId) => {
                    if (authentication!.id === profileUserId) {
                        throw Error('invalid profile');
                    }
                    return { userId: authentication!.id, recipientId: profileUserId };
                });
        }

        return promise
            .then((variables) => {
                return Promise.all([apolloClient.query({
                    query: GET_EXISTING_CONVERSATION ,
                    variables: { where: { AND: whereFromInput(variables) } },
                    fetchPolicy: 'network-only',
                }), Promise.resolve(variables)]);
            })
            .then(([result, variables]) => {
                // Return result if found
                if (result?.data?.conversations?.[0]?.id) {
                    return result.data.conversations[0];
                }

                // Or create one
                return mutate({
                    variables: {
                        data: {
                            user: { connect: { id: variables.userId } },
                            advertisment: variables.advertismentId ? { connect: { id: variables.advertismentId } } : null,
                            recipient: variables.advertismentId ? undefined : { connect: { id: variables.recipientId } },
                        },
                    },
                }).then((result) => result.data?.createConversation!);
            })
            .finally(() => setLoading(false));

    };

    return {
        create: handleCreate,
        ...mutationState,
        loading: loading || mutationState.loading,
    };
};

export default useCreateConversation;
