import {
    Breakpoint,
    Grid,
    GridProps,
    Typography,
    TypographyProps,
} from '@mui/material';

import React from 'react';

/**
 * Properties for {@link LabelledValue}
 */
export type LabelledValueProps = {
    /**
     * Label to display
     */
    label: string,

    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * Breakpoint, minimal size when column layout is used. Down that breakpoint layout is stacked.
     */
    breakpoint?: Breakpoint,

    /**
     * Whether or not to wrap the value in a typography element
     */
    wrap?: boolean | TypographyProps,

    /**
     * Properties passed to labels {@link Typography} element
     */
    labelTypographyProps?: Exclude<TypographyProps, 'component'>,
} & Pick<GridProps, 'children' | 'spacing' | 'rowSpacing'>;

/**
 * Default properties of label typography
 */
const defaultLabelTypographyProps: Exclude<TypographyProps, 'component'> = {
    variant: 'body1',
    color: 'primary',
};

/**
 * Display a value with a label in a row layout. Does enforce a breakpoint, defaulting to `md`.
 */
const LabelledValue = ({
    label,
    className,
    breakpoint = 'md',
    wrap = true,
    labelTypographyProps = {},
    children,
}: LabelledValueProps) => {
    // Props based on breakpoint
    const breakpointLabelProps = { [breakpoint]: 4 };
    const breakpointValueProps = { [breakpoint]: 8 };

    // Wrap value displayed if specified
    const valueDisplay = (wrap)
        ? (<Typography {...(wrap === true ? {} : wrap)}>{children}</Typography>)
        : children;

    // Final label typography
    const labelProps = { ...defaultLabelTypographyProps, ...labelTypographyProps };

    return (
        <Grid container className={className}>
            <Grid item xs={12} {...breakpointLabelProps}>
                <Typography {...labelProps}>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={12} {...breakpointValueProps}>
                {valueDisplay}
            </Grid>
        </Grid>
    );
};

export default LabelledValue;
