import { CssBaseline, ThemeProvider } from '@mui/material';

import AppError from '../AppError';
import { ErrorBoundary } from 'react-error-boundary';
import Footer from './Footer';
import Header from './Header';
import Providers from './Providers';
import React from 'react';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import theme from './Theme/Theme';

/**
 * Properties for {@link Layout}
 */
type Props = {
    children: React.ReactNode,
};

/**
 * The overall layout for the application.
 */
const Layout = ({ children }: Props) => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ErrorBoundary FallbackComponent={AppError}>
                        <Providers>
                            <CssBaseline />
                            <Header />
                            {children}
                            <Footer />
                        </Providers>
                    </ErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
        </Router>
    );
};

export default Layout;
