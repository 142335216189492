import Form, { FormData } from '../Advertisement/Form';

import Container from '../Common/Container';
import CreationState from '../Advertisement/CreationState';
import FormState from '../Common/FormState';
import React from 'react';
import Stack from '../Common/Stack';
import {
    Typography,
} from '@mui/material';
import useMutateAdvertisment from '../../Hooks/useMutateAdvertisment';
import { useParams } from 'react-router-dom';

const Creation = () => {
    // Mutation, for real world application we need to derive data from form and id if we are in
    // edit mode. Continue on branch APB-25 and merge again once data is provided.
    const { id } = useParams<{ id?: string }>();
    const { mutate, editObject, ...state } = useMutateAdvertisment(id);
    const handleSubmit = (data: FormData) => {
        mutate(data);
    };

    return (
        <Container size="default">
            <Stack dir="vertical">
                <Typography variant="h1">
                    {id ? 'Inserat bearbeiten' : 'Inserat erstellen'}
                </Typography>
                <FormState {...state} Component={CreationState}>
                    <Form id="create-form" onSubmit={handleSubmit} edit={{ ...editObject, fotos: [] }} />
                </FormState>
            </Stack>
        </Container>
    );
}

export default Creation;
