import {
    ListItem,
    Skeleton,
} from '@mui/material';
import React, { ReactElement } from 'react';

/**
 * Properties for {@link ListLoadingSekeleton}
 */
export type ListLoadingSekeletonProps = {
    /**
     * Number of rows to display
     */
    rows?: number,

    /**
     * Custom list item content when loading
     */
    rowSkeleton?: ReactElement,
};


/**
 * Loading sekelton for lists
 */
const ListLoadingSekeleton: React.FC<ListLoadingSekeletonProps> = ({
    rows = 5,
    rowSkeleton = (<Skeleton width="100%" aria-hidden />),
}) => (
    <>
        {new Array(rows).fill(1).map((n, i) => (
            <ListItem key={`loading_list_${i}`} aria-label="Wird geladen">
                {rowSkeleton}
            </ListItem>
        ))}
    </>
);

export default ListLoadingSekeleton;
