import {
    MenuItem,
    TextField,
    TextFieldProps,
} from '@mui/material';

import React from 'react';
import useLocationsTree from '../../Hooks/useLocationsTree';

/**
 * Properties for {@link StateSelectField}
 */
export type StateSelectFieldProps = TextFieldProps & {};

/**
 * Select field to select an id of a state. Is not reactive to value updates and can be considered
 * uncontrolled.
 */
const StateSelectField = (textFieldProps: StateSelectFieldProps) => {
    const { data, loading } = useLocationsTree();

    return (
        <TextField  {...textFieldProps} select>
            <MenuItem value="">{loading ? 'Lade...' : ''}</MenuItem>
            {data?.map(({ id, name }) => (
                <MenuItem value={id}>{name}</MenuItem>
            ))}
        </TextField>
    );
};

export default StateSelectField;
