import { ConversationMessage } from '../Types/Client/graphql';
import { intelligentFormat } from '../Lib/Dates';
import useAuth from './useAuth';
import { useMemo } from 'react';

/**
 * Message group
 */
export type MessageGroup = {
    own: boolean,
    displayedTime: string,
    messages: Array<string>,
};

type MessageGroupTimestamped = MessageGroup & { timestamp: Date };

const useDisplayGroupedMessages = (
    messages: Array<ConversationMessage>,
): Array<MessageGroup> => {
    const { authentication } = useAuth();
    return useMemo(() => {
        const reversed = [...messages];
        return reversed.reduce((memo, message) => {
            const messageAsGroup: MessageGroupTimestamped = {
                own: message.user?.id === authentication?.id,
                displayedTime: intelligentFormat(message.createdAt!),
                messages: [message.message!],
                timestamp: message.createdAt!,
            };
            if (memo.length < 1) {
                return [messageAsGroup];
            }
            const all = memo;
            const last = memo.pop()!;
            if (
                (messageAsGroup.own !== last.own)
                || ((messageAsGroup.timestamp.getTime() - last.timestamp.getTime()) > 600000)
            ) {
                return [...all, last, messageAsGroup];
            }
            return [...all, {
                ...messageAsGroup,
                messages: [...last.messages, message.message!],
            }];
        }, [] as Array<MessageGroupTimestamped>);
    }, [messages, authentication?.id]);
};

export default useDisplayGroupedMessages;
