import {
    Stack,
    Typography,
} from '@mui/material';

import FormStateMessage from '../Common/FormStateMessage';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Properties for {@link RegistrationState}
 */
export type RegistrationStateProps = {
    /**
     * The state to display
     */
    state: 'loading' | 'completed',
};

/**
 * Texts for loading and completed state
 */
const TEXTS_LOADING = (
    <Stack spacing={1} alignItems="center">
        <Typography variant="h3">
            Ihr Inserat wird erstellt...
        </Typography>
        <Typography variant="body1">
            Die Daten werden bearbeitet und überprüft. Bitte einen Moment Geduld.
        </Typography>
    </Stack>
);
const TEXTS_COMPLETED = (
    <Stack spacing={1} alignItems="center">
        <Typography variant="h3">
            Inserat erfolgreich erstellt
        </Typography>
        <Typography variant="body1">
            Ihr Inserat ist damit für andere Benutzer sichtbar. Gehen Sie im Profilbereich auf
            "Meine Inserate" um Ihr Inserat anzusehen oder zu bearbeiten.
        </Typography>
    </Stack>
);

/**
 * Logo with cube and word mark and subline
 */
const RegistrationState = ({
    state,
}: RegistrationStateProps) => {
    const navigate = useNavigate();
    return (
        <FormStateMessage
            state={state}
            loadingMessage={TEXTS_LOADING}
            completedMessage={TEXTS_COMPLETED}
            completedActionLabel="zurück zur Startseite"
            onAction={() => navigate('/')}
        />
    );
};

export default RegistrationState;
