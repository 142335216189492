import { gql } from '../Types/Client';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

/**
 * Tree item type
 */
export type TreeItem = {
    id: string,
    name: string,
    type: 'region' | 'state',
    children?: Array<TreeItem>,
};

// The query to get all filtered
export const GET_LOCATIONS = gql(/* GraphQL */ `
    query States {
        states(orderBy: { stateName: asc }) {
            id
            abbreviation
            stateName
            regions(orderBy: { regionName: asc }) {
                regionName
                id
                code
                type
            }
        }
    }
`);

/**
 * Use locations (states and regions) from server as a hierarchical tree structure.
 */
const useLocationsTree = () => {
    const { loading, data, error } = useQuery(GET_LOCATIONS);
    const treeData = useMemo(() => (
        data?.states?.map(({ regions, id, stateName }) => ({
            type: 'state' as const,
            name: stateName || '',
            id,
            children: regions?.map(({ regionName, id }) => ({
                type: 'region' as const,
                name: regionName || '',
                id,
            })),
        }))
    ), [data]);

    return { loading, data: treeData, error };
};

export default useLocationsTree;
