import React, { forwardRef, useEffect, useState } from 'react';
import {
    TextField,
    TextFieldProps,
} from '@mui/material';

/**
 * Properties for {@link SelectField}
 */
export type SelectFieldProps = TextFieldProps & {};

/**
 * Select field to integrate with react-hook-form nicely.
 */
const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>((textFieldProps, ref) => {
    // Internal value is always at least an empty string
    const [propsValue, setPropsValue] = useState(textFieldProps.value || '');
    useEffect(() => setPropsValue(textFieldProps.value || ''), [textFieldProps.value])

    // Syncing the set value with the native input element
    const myRef = React.useRef<HTMLSelectElement | null>(null);
    React.useEffect(() => {
        if (myRef.current) {
            setPropsValue(myRef.current.value || '');
        }
    }, [ref]);

    // Handle change with the internal value transform
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPropsValue(e.target.value);
        textFieldProps.onChange?.(e);
    };

    // Ignore blur
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {};

    return (
        <TextField {...textFieldProps}
            onChange={handleChange}
            onBlur={handleBlur}
            select
            value={propsValue}
            inputRef={(input) => {
                if (!input) return;
                myRef.current = input.node;
                if (typeof ref === 'function') {
                    ref(input.node);
                } else if (ref) {
                    ref.current = input.node;
                }
            }}
        />
    );
});

export default SelectField;
