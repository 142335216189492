import { GET_AUTHENTICATED } from './useInitialAuthentication';
import { ProfileDataFormData } from '../Components/User/ProfileDataForm';
import { gql } from '../Types/Client';
import { isCompleted } from '../Lib/Apollo';
import useAuth from '../Hooks/useAuth';
import { useMutation } from '@apollo/client';
import useNotifications from './useNotifications';

// Modify search profile mutation
export const UPDATE_USER = gql(/* GraphQL */ `
    mutation UpdateUser($data: UserUpdateInput!, $id: ID!) {
        updateUser(where: {id: $id}, data: $data) {
            id
        }
    }
`);


/**
 * Use search profile mutation. Includes notifications for errors and success.
 */
const useUpdateUser = () => {
    // Notifications and appropriate mutation
    const { addSuccess, addError } = useNotifications();
    const { authentication } = useAuth();

    const [mutate, mutationState] = useMutation(
        UPDATE_USER, {
            onCompleted: () => addSuccess('Benutzerdaten wurden bearbeitet'),
            onError: () => addError('Benutzerdaten konnten nicht bearbeitet werden'),
            refetchQueries: [GET_AUTHENTICATED],
        }
    );
    // Compose mutation state
    return {
        ...mutationState,
        completed: isCompleted(mutationState),
        // Ignore phone for now, not yet present
        mutate: ({ ...data }: ProfileDataFormData) => {
            return mutate({ variables: { data, id: authentication?.id ?? '' } });
        },
    };
};

export default useUpdateUser;
