import { Advertisment } from '../../Types/Client/graphql';
import AllFotos from './AllFotos';
import QueryState from '../Common/QueryState';
import React from 'react';
import useAdvertismentImages from '../../Hooks/useAdvertismentImages';

/**
 * Properties for {@link Actions} component.
 */
export type ActionsProps = {
    /**
     * Advertisment
     */
    advertisement: Omit<Advertisment, 'user'>,
};

/**
 * Single advertisment display page
 */
const Actions = ({ advertisement }: ActionsProps) => {
    const { imageUrls, loading, ...state } = useAdvertismentImages(advertisement.id);

    return (
        <QueryState {...state} loading={false}>
            <AllFotos
                fotos={imageUrls}
                loading={loading}
            />
        </QueryState>
    );
}

export default Actions;
