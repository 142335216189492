import {
    Alert,
    Button,
    CardActionArea,
    ImageList,
    ImageListItem,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {
    ErrorsType,
    ImageUploadingPropsType,
} from 'react-images-uploading';

import QueuedFoto from './QueuedFoto';
import React from 'react';
import { LocationIcon as UploadIcon } from '../Icon';
import {
    makeStyles,
} from '@mui/styles';

/**
 * Styles used in {@link QueuedFoto} component.
 */
const useStyles = makeStyles({
    uploadIndicator: {
        minHeight: 120,
        backgroundColor: '#f4f4f4',
        textAlign: 'center',
    },
    uploadIndicatorActive: {
        minHeight: 120,
        backgroundColor: '#444',
        textAlign: 'center',
    },
    buttonRemoveAll: {
        fontSize: '0.7rem',
        fontWeight: 'normal',
        paddingLeft: 0,
        paddingRight: 0,
    },
    spacedAlert: {
        margin: 10,
    },
});

/**
 * Error display helper
 */
const errorDisplay = (errors: ErrorsType) => {
    let msg = '';

    if (errors?.maxNumber) {
        msg = 'Es sind maximal 8 Fotos erlaubt';
    } else if (errors?.acceptType) {
        msg = 'Es sind nur die Bild-Formate Jpg, Gif und Png erlaubt';
    } else if (errors?.maxFileSize) {
        msg = 'Bild übersteigt die maximale Datei-Größe';
    } else if (errors?.resolution) {
        msg = 'Bild übersteigt die maximale Auflösung';
    }
    return <span>{msg}</span>;
};

/**
 * Foto upload container
 */
const FotoUploadContent = ({
    imageList,
    onImageUpload,
    onImageRemoveAll,
    onImageRemove,
    isDragging,
    dragProps,
    errors,
}: Parameters<Exclude<ImageUploadingPropsType['children'], undefined>>[0]) => {
    const classes = useStyles();
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    // Drag leave fix with nested structure inside drop container
    const onDragLeaveFix = (original: any) => ((e: any) => {
        if (!e.relatedTarget || !e.relatedTarget.closest('.upload__image-wrapper')) {
            original(e);
        }
    });

    return (
        <div
            className="upload__image-wrapper"
            {...dragProps}
            onDragLeave={onDragLeaveFix(dragProps.onDragLeave)}
        >
            <ImageList cols={isDesktop ? 5 : 2}>
                <ImageListItem>
                    <CardActionArea
                        onClick={onImageUpload}
                        className={isDragging
                            ? classes.uploadIndicatorActive
                            : classes.uploadIndicator
                        }
                    >
                        {
                            errors
                                ? (
                                    <Alert
                                        severity="error"
                                        className={classes.spacedAlert}
                                    >
                                        {errorDisplay(errors)}
                                    </Alert>
                                )
                                : (
                                    <Typography variant="body2">
                                        Fotos hier ablegen oder auswählen
                                    </Typography>
                                )
                        }

                        <UploadIcon />
                    </CardActionArea>
                </ImageListItem>
                {imageList.map((image, index) => (
                    <QueuedFoto
                        key={index}
                        image={image}
                        onRemove={() => onImageRemove(index)}
                    />
                ))}
            </ImageList>
            <Button
                onClick={onImageRemoveAll}
                className={classes.buttonRemoveAll}
            >
                Alle entfernen
            </Button>
        </div>
    );
}

export default FotoUploadContent;
