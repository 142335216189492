import { useApolloClient, useMutation } from '@apollo/client';

import { gql } from '../Types/Client';

// The query terminate session
export const END_SESSION = gql(/* GraphQL */ `
    mutation EndSession {
        endSession
    }
`);

export type UserCredentials = { email: string, password: string };

/**
 * Autheticate a user on the server
 */
const useEndSession = () => {
    const client = useApolloClient();
    const [mutate, mutationState] = useMutation(
        END_SESSION, {
            onCompleted: () => setTimeout(() => client.resetStore(), 200),
        },
    );
    return { ...mutationState, end: mutate  };
};

export default useEndSession;
