import { SearchParameterTypeType, SearchParameterWhereInput, SearchProfileWhereInput } from '../Types/Client/graphql';

import { AdvertismentType } from '@apo/shared-config';
import { gql } from '../Types/Client';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export type SearchProfileFilter = {
    type?: AdvertismentType | '',
    netSales?: number,
    grossProfit?: number,
    transferDate?: Date,
    operatingTurn?: number,
    operatingResult?: number,
    stateId?: string,
    regionId?: string, // Only used if stateId is set
};

const GET_MATCHING_SEARCH_PROFILES = gql(/* GraphQL */ `
    query SearchProfiles($where: SearchProfileWhereInput!) {
        searchProfiles(where: $where) {
            id,
            user {
                id
                name
                userSource
            }
        }
    }`);

function filterToWhereInput(filters: SearchProfileFilter): SearchProfileWhereInput {
    const baseSearchParameters: SearchParameterWhereInput = {};
    const searchParameters: SearchParameterWhereInput[] = [baseSearchParameters];

    if (filters.type) {
        baseSearchParameters.type = { equals: filters.type as SearchParameterTypeType };
    }

    if (filters.netSales) {
        baseSearchParameters.netSalesMin = { lte: filters.netSales };
        baseSearchParameters.netSalesMax = { gte: filters.netSales };
    }

    if (filters.grossProfit) {
        baseSearchParameters.grossProfitMin = { lte: filters.grossProfit };
        baseSearchParameters.grossProfitMax = { gte: filters.grossProfit };
    }

    if (filters.transferDate) {
        const transferDateWhere: SearchParameterWhereInput = {};

        if (filters.transferDate < new Date()) {
            // if possible transfer date is in the past, include search profiles
            // that allow transfer date to be as of now as well as search profiles
            // with matching transfer date range

            transferDateWhere.OR = [
                { transferDateIsAsOfNow: { equals: true } },
                { transferDateMin: { lte: filters.transferDate } },
                { transferDateMax: { gte: filters.transferDate } }
            ];
        }
        else {
            transferDateWhere.transferDateMin = { lte: filters.transferDate };
            transferDateWhere.transferDateMax = { gte: filters.transferDate };
        }

        searchParameters.push(transferDateWhere);
    }

    if (filters.operatingTurn) {
        // TODO - operatingTurn not available in graphql schema?
    }

    if (filters.operatingResult) {
        baseSearchParameters.operatingResultMin = { lte: filters.operatingResult };
        baseSearchParameters.operatingResultMax = { gte: filters.operatingResult };
    }

    if (filters.stateId) {
        baseSearchParameters.states = { some: { id: { equals: filters.stateId } } };

        if (filters.regionId) {
            baseSearchParameters.regions = { some: { id: { equals: filters.regionId } } };
        }
    }

    const result: SearchProfileWhereInput = {
        isPublic: { equals: true },
        searchParameters: { AND: searchParameters },
    };

    return result;
}

export default function useMatchingSearchProfiles(filters: SearchProfileFilter) {
    const where = filterToWhereInput(filters);
    const { loading, data, error } = useQuery(
        GET_MATCHING_SEARCH_PROFILES,
        { variables: { where }, fetchPolicy: 'cache-and-network' },
    );

    // Clean duplocates in matches
    const cleanedData = useMemo(() => {
        return data?.searchProfiles?.filter(
            (itemValue, index, haystack) =>  (
                haystack.findIndex(({ user }) => (user?.id === itemValue?.user?.id)) === index
            )
        ) ?? [];
    }, [data]);
    return { loading, data: cleanedData, error };
}
