import Container from '../Common/Container';
import ProfileData from '../User/ProfileData';
import React from 'react';
import {
    Stack,
} from '@mui/material';
import UserContent from '../User/UserContent';

const UserAgents = () => {
    return (
        <Container size="default" wrap={false}>
            <Stack spacing={2}>
                <ProfileData />
                <UserContent />
            </Stack>
        </Container>
    );
}

export default UserAgents;
