import {
    Alert,
    Button,
    Stack,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import ColumnLayout, { Column, useColumnExtraStyles } from './ColumnLayout';
import { Credentials, useAuth, useAuthenticate } from '../../Hooks/useAuth';

import { ArrowBackIcon } from '../Icon';
import CubeLogo from '../../Layout/CubeLogo';
import Link from '../Common/AppLink';
import { Navigate } from 'react-router-dom';
import PasswordField from '../User/PasswordField';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';

/**
 * Properties for {@link SignIn} component
 */
export type SignInProps = {
    /**
     * Type of user flow, determines sign up url
     */
    type?: 'pharmacists' | 'default',
};

/**
 * Styles used in {@link SignIn} component.
 */
const useStyles = makeStyles((theme: Theme) => ({
    register: {
        padding: theme.spacing(1),
        borderRadius: theme.spacing(0.25),
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[400],
        width: '100%',
    },
}));

/**
 * Sign in form with info messages
 */
const SignIn = ({ type }: SignInProps) => {
    const classes = { ...useColumnExtraStyles(), ...useStyles() };
    const authenticate = useAuthenticate();
    const { error, isLoading, isAuthenticated } = useAuth();
    const { register, handleSubmit } = useForm<Credentials>();

    if (isAuthenticated) {
        return (<Navigate to="/home" replace />);
    }

    return (
        <ColumnLayout>
            <Column className={classes.infoColumn} color="primary">
                <CubeLogo className={classes.logo} />
                <div>
                    <Typography variant="h1" color="inherit" className={classes.lead}>
                        Melden Sie sich bei Ihrem Benutzerkonto an
                    </Typography>
                </div>
                <Link
                    component="button"
                    variant="body1"
                    color="inherit"
                    // @ts-ignore
                    back
                    className={classes.moreInfo}
                >
                    <ArrowBackIcon color="inherit" className={classes.linkIcon} />
                    zurück
                </Link>
            </Column>
            <Column className={classes.formColumn}>
                <Stack spacing={0} paddingLeft={1}>
                    <Typography variant="h2" gutterBottom={false} className={classes.pageTitle}>
                        Login
                    </Typography>
                    <Typography variant="subtitle2">
                        Bitte geben Sie ihre Benutzerdaten ein.
                    </Typography>
                </Stack>

                <Stack className={classes.register}>
                    <Typography>
                        Noch kein Account?: <Link to={`/register${type === 'pharmacists' ? '/apotheker' : ''}`} component="button">hier gehts zur Registrierung</Link>
                    </Typography>
                </Stack>

                <Stack
                    spacing={1}
                    className={classes.fullWidth}
                    alignItems="flex-end"
                    component="form"
                    onSubmit={handleSubmit((data) => authenticate(data))}
                    role="form"
                >
                    <TextField
                        label="Email-Adresse"
                        fullWidth
                        {...register('email')}
                    />
                    <PasswordField
                        label="Passwort"
                        placeholder="Passwort"
                        required
                        fullWidth
                        {...register('password')}
                    />
                    <Link to="/passwort">Passwort vergessen?</Link>
                    {error
                        ? (<Alert severity="error">{error.message}</Alert>)
                        : null
                    }
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isLoading}
                    >
                        einloggen
                    </Button>
                </Stack>
                <Typography variant="body2" color="text.secondary" className={classes.help}>
                    Ich benötige Hilfe: <Link to="/hilfe-login">Hilfe zum Login</Link>
                </Typography>
            </Column>
        </ColumnLayout>
    );
}

export default SignIn;
