const texts = {
    /**
     * Advertisements page
     */
    endOfSearchResults: `
        Sie haben das Ende der Liste erreicht, es liegen keine weiteren Resultate vor. Wir
        benachrichtigen Sie, wenn ein neues Inserat geschalten wird.
    `,
    noResultsSearch: `
        Für Ihren Suchauftrag liegen derzeit keine passenden Angebote vor.
    `,
    noResultsSearchProfile: `
        Richten Sie sich bequem Ihren persönlichen Suchagenten ein und lassen Sie sich automatisch per E-Mail benachrichtigen, sobald ein passendes Angebot vorliegt.
    `,
    providerServiceInfo: `
        Der Österreichische Apothekerverband bietet Ihnen ein breit gefächertes Service-Angebot – nutzen Sie es. Informieren Sie sich und lassen Sie sich kompetent beraten.
    `,
    fallbackMatches: `
        Passende Anzeigen im selbem Bundesland
    `,

    /**
     * Search agent creation modal
     */
    searchAgentOptionPublic: `
        Wenn Sie diese Option aktivieren, erhalten Sie sofort eine Benachrichtigung per
        Email wenn ein neues Inserat geschalten wird, welches Ihren Wünschen entspricht!
    `,

    searchAgentOptionNotifications: `
        Wenn Sie diese Option aktivieren, können Sie von potentiellen Verkäufern gefunden
        und aktiv kontaktiert werden.
    `,


    /**
     * Dashboard
     */
    dashboardTitleSeller: `
        Beginnen Sie hier Ihre Suche nach Interessent:innen, nach Verkaufinseraten oder schalten Sie ein eigenes Verkaufsinserat.
    `,
    dashboardTitle: `
        Beginnen Sie hier Ihre Suche
    `,

    /**
     * Genric terms
     */
    GenericNoResults: `
        Keine Ergebnisse
    `,

    /**
     * Confirm form
     */
    confirmFormTitle: `
        Konto bei ApothekenBörse bestätigen
    `,
    confirmFormSubtitle: `
        Um die ApothekenBörse nutzen zu können, müssen Sie noch ihr Benutzerkonto
        vervollständigen und unsere AGB aktzeptieren.
    `,
};

export default texts;
