import { ArrowMoreIcon as ArrowLeftIcon, ArrowLoginIcon } from '../Icon';
import {
    CardActionArea,
    IconButton,
    Stack,
    Theme,
    Typography,
} from '@mui/material';
import ColumnLayout, { Column, useColumnExtraStyles } from './ColumnLayout';

import CubeLogo from '../../Layout/CubeLogo';
import Link from '../Common/AppLink';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

/**
 * Styles used in {@link LandingPage} component.
 */
const useStyles = makeStyles((theme: Theme) => ({
    nextIndicator: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        height: 36,
        width: 36,
        alignSelf: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    action: {
        padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
        backgroundColor: theme.palette.grey[100],
        borderColor: theme.palette.grey[100],
        borderStyle: 'solid',
        borderWidth: 1,
        '&:hover': {
            borderStyle: 'solid',
            borderColor: theme.palette.grey[400],
        },
        '& .MuiCardActionArea-focusHighlight': {
            backgroundColor: 'transparent',
        },
    },
}));
/**
 * Landing page with login options
 */
const LandingPage = () => {
    const classes = { ...useColumnExtraStyles(), ...useStyles() };
    const navigate = useNavigate();
    return (
        <ColumnLayout>
            <Column className={classes.infoColumn} color="primary">
                <CubeLogo onClick={() => {}} className={classes.logo} />
                <div>
                    <Typography variant="h1" color="inherit" className={classes.lead}>
                        Das beste Rezept für die Apothekennachfolge
                    </Typography>
                    <Typography variant="h3" color="inherit" className={classes.lead}>
                        Finden Sie jetzt die passende Apotheke oder den passenden Käufer.
                    </Typography>
                    <Typography component="p" variant="body1" color="inherit" className={classes.spaceBottom}>
                        Wir begleiten Ihren Kauf oder Verkauf kompetent von A bis Z. Und wir beraten
                        Sie bereits vorab – übrigens auch, wenn Sie (noch) kein Verbandsmitglied sind.
                    </Typography>
                </div>
                <Link
                    component="button"
                    variant="body1"
                    color="inherit"
                    onClick={() => {}}
                    className={classes.moreInfo}
                    to="/info"
                >
                    <ArrowLeftIcon color="inherit" className={classes.linkIcon} />
                    Mehr Erfahren
                </Link>
            </Column>
            <Column className={classes.formColumn}>
                <Stack spacing={0} paddingLeft={1}>
                    <Typography variant="h2" gutterBottom={false} className={classes.pageTitle}>
                        Zugang
                    </Typography>
                    <Typography variant="subtitle2">
                        Mitglieder loggen sich direkt mit ihren Apothekerverbands-Zugangsdaten ein.
                        Angestellte Apotheker und Dritte registrieren sich zunächst für die Plattform
                        und erhalten dann ihre Zugangsdaten.
                    </Typography>
                </Stack>
                <Stack spacing={1} className={classes.fullWidth}>
                    <CardActionArea
                        className={classes.action}
                        onClick={() => window.location.href = `${process.env.REACT_APP_BACKEND_URL}/oauth/signin/apothekerverband`}
                    >
                        <Stack spacing={0} direction="row">
                            <Stack spacing={0} flex={1}>
                                <Typography variant="subtitle1">
                                    Apothekerverband
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Kaufen/Verkaufen
                                </Typography>
                            </Stack>
                            <IconButton className={classes.nextIndicator}>
                                <ArrowLoginIcon color="inherit" />
                            </IconButton>
                        </Stack>
                    </CardActionArea>
                    <CardActionArea
                        className={classes.action}
                        onClick={() => navigate('/login/apotheker')}
                    >
                        <Stack spacing={0} direction="row">
                            <Stack spacing={0} flex={1}>
                                <Typography variant="subtitle1">
                                    Angestellte Apotheker
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Kaufen
                                </Typography>
                            </Stack>
                            <IconButton className={classes.nextIndicator}>
                                <ArrowLoginIcon color="inherit" />
                            </IconButton>
                        </Stack>
                    </CardActionArea>
                    <CardActionArea
                        className={classes.action}
                        onClick={() => navigate('/login')}
                    >
                        <Stack spacing={0} direction="row">
                            <Stack spacing={0} flex={1}>
                                <Typography variant="subtitle1">
                                    Dritte
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    (Steuerberater, Notar, Rechtsanwalt, ...)
                                </Typography>
                            </Stack>
                            <IconButton className={classes.nextIndicator}>
                                <ArrowLoginIcon color="inherit" />
                            </IconButton>
                        </Stack>
                    </CardActionArea>
                </Stack>
                <Typography variant="body2" color="text.secondary" className={classes.help}>
                    Hier bekommen Sie <Link to="/hilfe-login">Hilfe zum Login</Link>
                </Typography>
            </Column>
        </ColumnLayout>
    );
}

export default LandingPage;
