import {
    CardContent,
    Paper,
    Typography,
} from '@mui/material';
import FilterForm, { FilterData } from '../Advertisement/FilterForm';
import React, { useEffect, useMemo, useState } from 'react';
import SidebarContainer, { Main, Sidebar } from '../Common/SidebarContainer';
import { filterBubblesFromFilter, parseAll, serializeAll } from '../../Lib/Filter';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ActiveFilters from '../Advertisement/ActiveFilters';
import Container from '../Common/Container';
import ContainerHeader from '../Common/ContainerHeader';
import Listing from '../Agent/Listing';
import QueryState from '../Common/QueryState';
import useMatchingSearchProfiles from '../../Hooks/useMatchingSearchProfiles';
import useStyles from './usePageStyles';

const Agents = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState<FilterData>(parseAll(searchParams));
    const { data, ...queryState } = useMatchingSearchProfiles(filters);
    const bubbles = useMemo(() => filterBubblesFromFilter(filters, false), [filters]);

    useEffect(() => setSearchParams(serializeAll(filters), { replace: true }), [filters, setSearchParams]);

    // Handle removal of fitler bubble
    const handleBubbleRemoval = (index: number) => {
        const { filterKey, valueKey } = bubbles[index];
        setFilters((prev) => {
            const copy = { ...prev };
            let filterOverride = prev[filterKey];
            // Override part or total
            if (valueKey !== undefined) {
                if (typeof valueKey === 'number') {
                    // @ts-ignore
                    filterOverride = [...filterOverride];
                    // @ts-ignore
                    filterOverride.splice(valueKey, 1);
                } else {
                    // @ts-ignore
                    delete filterOverride[valueKey];
                }
                // @ts-ignore
                copy[filterKey] = filterOverride;
            } else {
                delete copy[filterKey];
            }
            return copy;
        });
    };

    return (
        <Container size="default">
            <ContainerHeader
                className={classes.specialHeader}
                title="Interessenten"
                subtitle={`${data.length} Kontakte`}
                sidebarFirst
            />
            <SidebarContainer alignItems="flex-start">
                <Sidebar>
                    <Paper className={classes.filterContainer}>
                        <CardContent className={classes.filterContent}>
                            <Typography variant="h4" color="text.primary">Filter</Typography>
                            <FilterForm onChange={setFilters} values={filters} />
                        </CardContent>
                    </Paper>
                </Sidebar>
                <Main>
                    <ActiveFilters
                        filters={bubbles}
                        onRemove={handleBubbleRemoval}
                        onReset={() => setFilters({})}
                    />
                    <QueryState {...queryState}>
                        <Listing
                            results={data}
                            onContact={(id) => navigate(`/nachricht?profile=${id}`)}
                        />
                    </QueryState>
                </Main>
            </SidebarContainer>
        </Container>
    );
}

export default Agents;
