import {
    Slider,
    SliderProps,
} from '@mui/material';

import React from 'react';
import {
    makeStyles,
} from '@mui/styles';

const useStyles = makeStyles({
    root:{
        color: '#707070 !important',
        height: 2,
        padding: '13px 0',
        '& .MuiSlider-thumb': {
            height: 27,
            width: 27,
            backgroundColor: '#fff',
            border: '1px solid currentColor',
            '&:hover': {
                boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
            },
            '& .airbnb-bar': {
                height: 9,
                width: 1,
                backgroundColor: 'currentColor',
                marginLeft: 1,
                marginRight: 1,
            },
        },
        '& .MuiSlider-track': {
            height: 2,
        },
        '& .MuiSlider-rail': {
            color: '#d8d8d8',
            opacity: 1,
            height: 2,
        },
    },
});

const CustomSlider = (props: SliderProps) => {
    const classes = useStyles();
    return (
        <Slider
            {...props}
            className={classes.root}
        />
    );
};

export default CustomSlider;
