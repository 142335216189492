import './Fonts.css';

import { adaptV4Theme, createTheme } from '@mui/material/styles';

import background from './background.jpg';
import { grey as baseGrey } from '@mui/material/colors';
import { deDE } from '@mui/material/locale';

/**
 * Color definitions
 */
const colorPrimary = '#d40e14';
const colorSecondary = '#d40e14';
const grey = { ...baseGrey, ...{
    100: '#f4f4f4',
    400: '#CACACA',
    600: '#868686',
    700: '#707070',
} };

/**
 * Some basics used in many
 */
const heading: { color: string, fontWeight: number } = {
    color: colorPrimary,
    fontWeight: 500,
};

const applySpacing = (factor: number) => `${factor}rem`;
const { breakpoints, shadows, palette: { augmentColor, common } } = createTheme();

/**
 * Create a theme
 */
const theme = createTheme(adaptV4Theme({
    /**
     * Adjust the color palette
     */
    palette: {
        primary: {
            main: colorPrimary,
        },
        secondary: {
            main: colorSecondary,
        },
        success: augmentColor({ color: { main: '#25C600' }, name: 'successcolor' }),
        error: augmentColor({ color: { main: '#E96100' }, name: 'errorcolor' }),
        warning: augmentColor({ color: { main: '#E9CD00' }, name: 'warningcolor' }),
        info: augmentColor({ color: { main: '#006AC6' }, name: 'infocolor' }),
        text: {
            primary: '#020202',
            secondary: '#868686',
        },
        common: {
            black: '#020202',
        },
        divider: grey[400],
    },

    /**
     * Disable shadow alltogether
     */
    // @ts-ignore
    shadows: [...new Array(2).fill('none'), ...shadows.slice(0, 23)],

    /**
     * Adjust the typography
     */
    typography: {
        fontFamily: [
            'ApothekeFontCalibLight',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    /**
     * Spacing function to be used in custom stylings
     */
    spacing: applySpacing,
    /**
     * Default props configuration
     */
    props: {
        // Force outlined input stye
        MuiTextField: {
            variant: 'outlined',
            margin: 'normal',
        },
        MuiChip: {
            variant: 'outlined',
        },
        MuiSelect: {
            MenuProps: { PaperProps: { square: true } },
        },
        MuiDialogTitle: {
            variant: 'h3',
        },
        MuiCardHeader: {
            titleTypographyProps: { variant: 'h3' },
        },
        MuiListItem: {
             disableGutters: true,
        },
        MuiTooltip: {
            arrow: true,
        },
    },
    /**
     * Style overrides
     */
    overrides: {
        /**
         * General layout styles
         */
        // Some basic styles
        MuiCssBaseline: {
            '@global': {
                // Custom toolbar primary that flexes accoross the toolbar
                '.toolbar-primary': {
                    flex: 1,
                },
            },
            html: {
                minHeight: '100%',
            },
            body: {
                minHeight: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#fafafa',
                backgroundImage: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
            },
        },
        // Appbar, adjust gaps and flex flow
        MuiAppBar: {
            root: {
                paddingTop: '0',
                paddingBottom: '0',
                marginBottom: '1rem',
                boxShadow: 'none',

                '& .MuiToolbar-root': {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                },
            },
        },
        /**
         * General container has less spacing
         */
        MuiContainer: {
            root: {
                paddingLeft: applySpacing(1),
                paddingRight: applySpacing(1),
                [breakpoints.up('md')]: {
                    paddingLeft: applySpacing(1.5),
                    paddingRight: applySpacing(1.5),
                },
            },
        },
        /**
         * Typography
         */
        MuiTypography: {
            h1: {
                ...heading,
                fontSize: '2rem',
                marginBottom: applySpacing(2),
            },
            h2: { ...heading, fontSize: '1.7rem' },
            h3: { ...heading, fontSize: '1.4rem' },
            h4: { ...heading, fontSize: '1.25rem' },
            h5: { ...heading, fontSize: '1.1rem' },
            h6: { ...heading, fontSize: '1rem' },

            root: {
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                hyphens: 'auto',
            },

            subtitle1: {
                lineHeight: 1.4,
                fontSize: 18,
                textTransform: 'uppercase',
                '&.MuiTypography-gutterBottom': {
                    marginBottom: applySpacing(2),
                },
            },

            caption: {
                textTransform: 'uppercase',
                color: colorPrimary,
                lineHeight: 1.4,
            },

            gutterBottom: {
                marginBottom: applySpacing(1),
            },
        },
        // Icon adjustment
        MuiSvgIcon: {
            root: {
                verticalAlign: 'middle',
            },
        },

        /**
         * Dialogs
         */
        MuiDialogTitle: {
            root: {
                paddingTop: applySpacing(1.5),
                paddingBottom: applySpacing(0.5),
                marginLeft: applySpacing(2),
                marginRight: applySpacing(2),
                marginBottom: applySpacing(1),
                paddingLeft: 0,
                paddingRight: 0,
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: colorPrimary,
                fontWeight: '700',
                '& .MuiIconButton-root': {
                    position: 'absolute',
                    top: '.5rem',
                    right: '.5rem',
                },
            },
        },
        MuiDialogContent: {
            root: {
                paddingLeft: applySpacing(2),
                paddingRight: applySpacing(2),
            },
        },
        MuiDialogActions: {
            root: {
                marginLeft: applySpacing(2),
                marginRight: applySpacing(2),
                paddingTop: applySpacing(1),
                paddingBottom: applySpacing(1),
                paddingLeft: 0,
                paddingRight: 0,
                borderTopWidth: 1,
                borderTopStyle: 'solid',
                borderTopColor: colorPrimary,
            }
        },
        /**
         * Surface elements like papers and cards
         */
        // Custom rounding for paper
        MuiPaper: {
            rounded: {
                borderRadius: applySpacing(1),
            },
        },
        // Adjust padding on cards
        MuiCard: {
            root: {
                '& > .MuiList-root': {
                    '& > .MuiListItem-gutters': {
                        paddingLeft: applySpacing(1),
                        paddingRight: applySpacing(1),
                    },
                    '& > .MuiListItem-container': {
                        '& > .MuiListItem-gutters': {
                            paddingLeft: applySpacing(1),
                            paddingRight: applySpacing(1),
                        },
                        '& > .MuiListItemSecondaryAction-root': {
                            right: applySpacing(1),
                        },
                    },
                },
            },
        },
        MuiCardContent: {
            root: {
                padding: `${applySpacing(2)} ${applySpacing(2)}`,
                '&:last-child': {
                    paddingBottom: applySpacing(2),
                },
            },
        },
        MuiCardHeader: {
            root: {
                paddingTop: applySpacing(2),
                paddingBottom: applySpacing(0.5),
                marginLeft: applySpacing(2),
                marginRight: applySpacing(2),
                marginBottom: applySpacing(2),
                paddingLeft: 0,
                paddingRight: 0,
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: colorPrimary,
                fontWeight: '700',
                '& + .MuiCardContent-root': {
                    paddingTop: 0,
                },
            },
        },

        MuiListItemAvatar: {
            root: {
                [breakpoints.down('md')]: {
                    display: 'none',
                },
            },
        },

        /**
         * Tables
         */
        // Table toolbar spacing
        MuiToolbar: {
            root: {
                paddingTop: applySpacing(0.5),
                paddingBottom: applySpacing(0.5),
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                [breakpoints.down('sm')]: {
                    paddingLeft: applySpacing(1),
                    paddingRight: applySpacing(1),
                    paddingTop: applySpacing(0.5),
                    paddingBottom: applySpacing(0.5),
                },
            },
        },
        // Table row and cell spacing
        MuiTableRow: {
            root: {
                '& .MuiTableCell-root:first-child': {
                    paddingLeft: '1.5rem',
                },
                '& .MuiTableCell-root:last-child': {
                    paddingRight: '1.5rem',
                },
            },
        },
        // Table cell, colors and borders
        MuiTableCell: {
            root: {
                borderBottomWidth: 2,
            },
            body: {
                color: grey[900],
            },
            head: {
                color: grey[600],
                textTransform: 'uppercase',
            },
        },
        // Pagination coloring and sizing
        MuiTablePagination: {
            actions: {
                '& .MuiSvgIcon-root': {
                    fontSize: '3rem',
                },
                '& .MuiIconButton-colorInherit': {
                    color: colorPrimary,
                },
                '& .Mui-disabled': {
                    color: grey[400],
                },
            },
        },

        /**
         * Form elements
         */
        // Button styles, custom spacing and text
        MuiButton: {
            text: {
                textTransform: 'none',
                fontWeight: 700,
                textDecoration: 'underline',
                color: grey[900],
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            contained: {
                paddingTop: applySpacing(.75),
                paddingBottom: applySpacing(.75),
                whiteSpace: 'nowrap',

                '&.Mui-disabled': {
                    backgroundColor: grey[400],
                    color: grey[100],
                },
            },
            sizeLarge: {
                fontSize: '1.15em',
            },
        },

        MuiFormGroup: {
            root: {
                marginBottom: '2rem',
            },
        },

        MuiCheckbox: {
            root: {
                alignSelf: 'flex-start',
                '& + .MuiFormControlLabel-label': {
                    textAlign: 'left',
                    paddingTop: 9,
                    paddingBottom: 9,
                },
            },
        },

        // Input disabled
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#ffffff',
                borderRadius: 3,
                '&.Mui-disabled': {
                    backgroundColor: grey[400],
                },
            },
        },

        MuiFormControl: {
            root: {
                '& + .MuiButton-root': {
                    marginTop: applySpacing(1),
                },
            },
            marginNormal: {
                marginBottom: applySpacing(1),
            },
        },

        MuiBadge: {
            badge: {
                transform: 'scale(1) translate(50%, -25%)',
                backgroundColor: '#25C600',
            },
        },

        MuiTooltip: {
            tooltip: {
                backgroundColor: common.white,
                color: '#020202',
                fontSize: '0.9rem',
                border: '1px solid',
                borderColor: grey[400],
                padding: applySpacing(1),
            },
            arrow: {
                color: common.white,
                '&::before': {
                    boxShadow: `-1px -1px 0 ${grey[400]}`,
                },
            },
        },
        MuiDivider: {
            vertical: {
                '&.MuiDivider-middle': {
                    height: '70%',
                    alignSelf: 'center',
                },
            },
        },
    },
}), deDE);

export default theme;
