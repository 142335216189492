import {
    GrossProfitIcon,
    LocationIcon,
    NetSalesIcon,
    OperatingResultIcon,
    OperatingTurnIcon,
    TransferDateIcon,
} from '../Icon';

import {
    Grid,
} from '@mui/material';
import KeyInfo from './KeyInfo';
import React from 'react';
import { monthDisplay } from '../../Lib/Dates';
import { numberFormat } from '../../Lib/Util';

/**
 * Static config for info types
 */
const infoTypeConfig = {
    transferDate: { icon: TransferDateIcon, label: 'Zeitpunkt', display: monthDisplay, fallback: 'ab sofort' },
    grossProfit: { icon: GrossProfitIcon, label: 'Rohertrag', display: (value: number) => (`${value}%`) },
    netSales: { icon: NetSalesIcon, label: 'Umsatz', display: (value: number) => numberFormat(value) },
    operatingResult: { icon: OperatingResultIcon, label: 'Betriebsergebnis', display: (value: number) => numberFormat(value) },
    operatingTurn: { icon: OperatingTurnIcon, label: 'Turnus', display: (value: number) => (value) },
    location: { icon: LocationIcon, label: 'Standort', display: (value: string) => (value) },
} as const;

// Tuplify possible values provided
type InfosArray = ({ type: 'transferDate', value: Date | undefined | null }
    | { type: 'grossProfit', value: number | undefined | null }
    | { type: 'netSales', value: number | undefined | null }
    | { type: 'operatingResult', value: number | undefined | null }
    | { type: 'operatingTurn', value: number | undefined | null }
    | { type: 'location', value: string | undefined | null })[];

/**
 * Properties for {@link KeyInfos} component.
 */
export type KeyInfosProps = {
    /**
     * Infos to display in component
     */
    infos: InfosArray,
};

/**
 * Helper function to display a value
 */
const valueDisplay = <T,>(value: T | null | undefined, display: (v: T) => string, fallback?: string) => {
    if (null === value || undefined === value) {
        return fallback ?? 'k.A.';
    }
    return display(value);
}

/**
 * Display an advertisements key infos.
 */
const KeyInfos = ({
    infos,
}: KeyInfosProps) => {
    return (
        <Grid container rowSpacing={1} columnSpacing={1}>
            {infos.map(({ type, value }) => (
                <Grid item xs={12} md={6} key={type}>
                    <KeyInfo
                        icon={infoTypeConfig[type].icon}
                        label={infoTypeConfig[type].label}
                        // @ts-ignore
                        value={valueDisplay(value, infoTypeConfig[type].display, infoTypeConfig[type].fallback)}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default KeyInfos;
