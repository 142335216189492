import { AdvertismentStatusType } from '../Types/Client/graphql';
import { gql } from '../Types/Client';
import { useAuth } from './useAuth';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

// The query to get all filtered
export const GET_USER_ADVERTISMENTS = gql(/* GraphQL */ `
    query GetUserAdvertisments($id: ID!) {
        advertisments(where: { user: { id: { equals: $id } } }) {
            createdAt
            description
            grossProfit
            id
            name
            netSales
            operatingResult
            operatingTurn
            updatedAt
            type
            transferDate
            thumbnail
            status
            region { id, regionName }
            state { id, stateName, abbreviation }
        }
    }
`);

// Query new message counts
export const GET_ADVERTISEMENTS_MESSAGE_COUNTS = gql(/* GraphQL */ `
    query GetAdMessageCounts($ids: [ID!]!) {
        conversations(where: { advertisment: { id: { in: $ids } } }) {
            advertisment { id }
            newMessageCount
        }
    }
`);

/**
 * Use advertisments from server.
 */
const useUserAdvertisments = () => {
    const { authentication } = useAuth();
    const userId = authentication?.id ?? '';
    const { loading, data, error } = useQuery(
        GET_USER_ADVERTISMENTS,
        { variables: { id: userId } },
    );
    const adIds = useMemo(() => (
        data?.advertisments
            ?.filter((ad) => ad.status === AdvertismentStatusType.Active)
            .map((ad) => ad.id)
        ?? []
    ), [data]);
    const { data: messageCountData } = useQuery(
        GET_ADVERTISEMENTS_MESSAGE_COUNTS,
        { variables: { ids: adIds }, fetchPolicy: 'network-only', skip: loading || error !== undefined },
    );

    const adsWithMessageCountData = useMemo(() => (
        data?.advertisments
            ?.map((ad) => {
                const count = messageCountData?.conversations
                    ?.find((conversation) => (conversation.advertisment?.id === ad.id))
                    ?.newMessageCount
                    ?? 0;
                return { ...ad, newMessageCount: count };
            })
        ?? []
    ), [data, messageCountData]);

    return { loading, data: adsWithMessageCountData, error };
};

export default useUserAdvertisments;
