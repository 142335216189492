/* eslint-disable */
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentProps,
} from '@mui/material';
import React, { PropsWithChildren, ReactNode, useState } from 'react';

import Dialog from './Dialog';

/**
 * Properties for {@link MobileDialog} component
 */
export type MobileDialogProps = PropsWithChildren<{
    /**
     * Callback fired when close is requested
     */
    onClose?: () => void,

    /**
     * Title displayed in MobileDialog
     */
    title: string | ReactNode,

    /**
     * Properties passed to dialog content element
     */
    dialogContentProps?: DialogContentProps,

    /**
     * Either a button label or a custom node indicating that is should be a dialog with a
     * {@link DialogActions} element appended.
     */
    actionLabel?: string | ReactNode;
}>;

/**
 * MobileDialog location selection component
 */
const MobileDialog = ({
    title,
    onClose,
    dialogContentProps,
    actionLabel,
    children,
}: MobileDialogProps) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
        onClose?.();
    };

    return (
        <>
            <Button variant="contained" onClick={() => setOpen(true)}>
                {title}
            </Button>
            <Dialog open={open} fullScreen onClose={handleClose} title={title}>
                <DialogContent {...dialogContentProps}>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}>
                        {actionLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MobileDialog;
