import {
    Advertisment,
    Content_Content_Document,
    SearchParameter,
    User,
} from '../Types/Client/graphql';

type AllNullable<T, ExceptKeys = void> = {
    [P in keyof T]?: P extends ExceptKeys
        ? T[P]
        : (T[P] | null)
};

export type AppUser = Pick<User, 'id'> & AllNullable<User>;

/**
 * Title for types as map
 * @TODO type strictly, we need to figure out if graphql generator can type more strictly or move
 *     options to shared-config
 */
export const titleForTypes: Record<string, string> = {
    withLicense: 'Apothekenanteil mit Konzession',
    withoutLicense: 'Apothekenanteil ohne Konzession',
    lease: 'Pacht',
};

/**
 * Title for an advertisment with location name
 */
export const adTitle = (item: Pick<Advertisment, 'type' | 'region' | 'state'>) => {
    const typeTitle = item.type ? titleForTypes[item.type] : 'k.A.';
    const locationTitle = item.region
        ? ` im Bezirk ${item.region.regionName}`
        : (item.state ? ` in ${item.state.stateName}` : '');

    return `${typeTitle}${locationTitle}`;
};


/**
 * Title for a search profiles by its params
 */
export const searchProfileTitle = (item: Pick<SearchParameter, 'type'>) => {
    return item.type ? titleForTypes[item.type] : 'k.A.';
};

/**
 * Uppercase first helper
 */
export const ucFirst = (value: string, prefix: string = '') => {
    return `${prefix}${value.charAt(0).toUpperCase()}${value.slice(1)}`;
};

/**
 * Get a short display of the location selection
 */
export const locationShortDisplay = ({ region, state }: Pick<Advertisment, 'region' | 'state'>) => (
    region ? region.regionName : state?.stateName
);


/**
 * Full name saulation from user object
 */
export const fullSalutation = (userData?: AppUser | null) => {
    if (!userData) {
        return '-';
    }
    return [
        userData.salutation,
        userData.titlePrefix,
        userData.firstName,
        userData.lastName,
        userData.titleSuffix,
    ].filter((part) => (part && part.length > 0)).join(' ');
};

/*
 * Preview for a rich text field
 */
export const documentPreviewText = (document: Content_Content_Document) => {
   let previewText = '';

   for (
       let i = 0, maxLength = document.document.length;
       i < maxLength && previewText.length < 140;
       i++
   ) {
       const node = document.document[i];
       if (node.type === 'paragraph') {
           const onlyText = node.children.map(({ text }: any) => (text)).join('\n\n');
           previewText = `${previewText}\n\n${onlyText}`;
       }
   }

   if (previewText.length < 140) {
       return previewText;
   }

   return `${previewText.substring(0, 140)}...`;
};

/**
 * Local number formatting, in fixed locale
 */
export const numberFormat = (num: number) => (
    `${String(Math.floor(num)).replace(/(.)(?=(\d{3})+$)/g,'$1.')}${
        num % 1 === 0
            ? ''
            : ','.concat(String(num - Math.floor(num)).substring(2))
    }`
);
