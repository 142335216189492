import ApiClientProvider from '../Providers/ApiClientProvider';
import ClientAuthProvider from '../Providers/ClientAuthProvider';
import NotificationProvider from '../Providers/NotificationProvider';
import Notifications from './Notification/Notifications';

/**
 * All the providers
 */
const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <NotificationProvider>
        <ApiClientProvider>
            <ClientAuthProvider>
                {children}
                <Notifications />
            </ClientAuthProvider>
        </ApiClientProvider>
    </NotificationProvider>
);

export default Providers;
