import {
    Stack,
    Typography,
} from '@mui/material';

import { FilterData } from './FilterForm';
import LANG from '../../Lang/StaticTexts';
import Listing from '../Advertisement/Listing';
import QueryState from '../Common/QueryState';
import React from 'react';
import useAdvertisments from '../../Hooks/useAdvertisments';
import { useNavigate } from 'react-router-dom';

/**
 * Properties for {@link RelatedInexactMatches} component.
 */
export type RelatedInexactMatchesProps = {
    /**
     * Advertisment to contact user
     */
    filters: FilterData,
};

/**
 * Fallback to related inexact matches
 */
const RelatedInexactMatches = ({ filters }: RelatedInexactMatchesProps) => {
    const navigate = useNavigate();
    const locationsWithChildren = filters.location?.filter(({ children }) => ((children?.length))) || [];
    const showFallback = locationsWithChildren.length > 0;

    // Get the same advertisements without children of states
    const { data, ...queryState } = useAdvertisments({
        ...filters,
        location: locationsWithChildren.map(({ children, ...state }) => (state)),
        excludeRegions: locationsWithChildren.flatMap(({ children }) => (children ?? [])),
    }, !showFallback);
    const hasFallbacks = !!data?.advertisments?.length;

    // No selected children, cannot fallback to parents
    if (!showFallback || !data?.advertisments?.length) {
        return null;
    }

    return (
        <QueryState {...queryState} loadingIndicator={(null)}>
                <Stack spacing={2} pt={3}>
                {hasFallbacks
                    ? (<Typography variant="h4">{LANG.fallbackMatches}</Typography>)
                    : null
                }
                <Listing
                    results={data?.advertisments ?? []}
                    onSelect={(id) => navigate(`/inserat/${id}`)}
                />
            </Stack>
        </QueryState>
    );
}

export default RelatedInexactMatches;
