import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { GET_CONVERSATIONS } from './useConversations';
import { GET_CONVERSATION_MESSAGE_COUNT } from './useNewMessageCount';
import { gql } from '../Types/Client';

// The query to get all for user
export const GET_CONVERSATION_MESSAGES = gql(/* GraphQL */ `
    query Query($id: ID!) {
        conversationMessages(where: { conversation: { id: { equals: $id } } }, orderBy: [{ createdAt: asc }]) {
            id
            user {
                id
                name
                userSource
            }
            message
            createdAt
        }
        conversation(where: { id: $id }) {
            id
            partner {
                id
                name
            }
            advertisment {
                id
                name
            }
            lastSeenRecipient
            lastSeenUser
        }
    }
`);

export const POST_MESSAGE = gql(/* GraphQL */`
    mutation PostConversationMessage($conversation: ID!, $message: String!) {
        createConversationMessage(data: { message: $message, conversation: { connect: { id: $conversation } } }) {
            id
            user {
                id
                name
            }
            message
            createdAt
        }
    }
`);

export const POST_SEEN = gql(/* GraphQL */`
    mutation SetConversationSeen($conversation: ID!) {
        conversationSeen(conversationId: $conversation) {
            lastSeenUser
        }
    }
`);


/**
 * Uses a single chat with all messages and sending
 */
const useChat = (id?: string) => {
    const { data, ...queryState } = useQuery(
        GET_CONVERSATION_MESSAGES,
        { variables: { id: id || '' }, skip: !id, fetchPolicy: 'cache-and-network' },
    );
    const [messages, setMessages] = useState(data?.conversationMessages ?? []);

    const [seenMutation] = useMutation(
        POST_SEEN,
        {
            variables: { conversation: id || '' },
            refetchQueries: [GET_CONVERSATIONS, GET_CONVERSATION_MESSAGE_COUNT],
        },
    );
    const [messageMutation, mutationState] = useMutation(
        POST_MESSAGE,
        {
            variables: { conversation: id || '', message: '' },
            refetchQueries: [GET_CONVERSATION_MESSAGES],
        }
    );

    const sendMessage = (message: string) => {
        mutationState.reset();
        setMessages((prev) => ([
            ...prev,
            { message, id: 'unsaved', createdAt: new Date() },
        ]));
        messageMutation({ variables: { conversation: id || '', message } });
    };

    // Post seen when conversation changes
    useEffect(() => {
        if (id) {
            seenMutation();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, mutationState.data]);

    // Derive messages when new ones loaded
    useEffect(() => {
        setMessages(data?.conversationMessages ?? []);
    }, [data])

    return {
        messages,
        conversation: data?.conversation,
        sendMessage,
        ...queryState
    };
};

export default useChat;
