import {
    LinearProgress,
    Stack,
    Typography,
} from '@mui/material';

import AgentItem from '../Agent/AgentItem';
import React from 'react';
import { SearchProfile } from '../../Types/Client/graphql';
import useMutateSearchProfile from '../../Hooks/useMutateSearchProfile';

/**
 * Properties for {@link UserAgentItem} component.
 */
export type UserAgentItemProps = {
    /**
     * When a UserAgentItem is selected
     */
    onShowResults: () => void,

    /**
     * Search profile
     */
    profile: SearchProfile,
};

/**
 * Show a UserAgentItem of user search agents
 */
const UserAgentItem = ({
    onShowResults,
    profile,
}: UserAgentItemProps) => {
    const { id, __typename, ...profileWithoutId } = profile;
    const {
        mutate, delete: triggerDelete, isDeleting,
    } = useMutateSearchProfile(profile.id);

    if (isDeleting) {
        return (
            <Stack>
                <LinearProgress variant="indeterminate" />
                <Typography>Lösche Suchagenten...</Typography>
            </Stack>
        );
    }

    return (
        <AgentItem
            profile={profile}
            onShowResults={onShowResults}
            onDelete={triggerDelete}
            onChangeSettings={({ sendNotificationMails, isPublic }) => {
                // @ts-ignore
                mutate({
                    ...profileWithoutId,
                    sendNotificationMails,
                    isPublic,
                });
            }}
        />
    );
};

export default UserAgentItem;
