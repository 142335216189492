import { Advertisment, AdvertismentStatusType } from '../Types/Client/graphql';

import { GET_ALL } from './useAdvertisments';
import { GET_USER_ADVERTISMENTS } from './useUserAdvertisments';
import { MODIFY } from './useMutateAdvertisment';
import { useMutation } from '@apollo/client';
import useNotifications from './useNotifications';

/**
 * Use advertisment state mutation. Includes notifications for errors and success.
 */
const useToggleAdvertisementStatus = () => {
    // Notifications and appropriate mutation
    const { addSuccess, addError } = useNotifications();
    const [mutate, mutationState] = useMutation(
        MODIFY, {
            onCompleted: () => addSuccess('Inserat wurde gespeichert'),
            onError: () => addError('Inserat konnte nicht gespeichert werden'),
            refetchQueries: [GET_USER_ADVERTISMENTS, GET_ALL],
        }
    );

    // Compose mutation state
    return {
        ...mutationState,
        toggle: (ad: Advertisment) => {
            const status = ad.status === AdvertismentStatusType.Active
                ? AdvertismentStatusType.Inactive : AdvertismentStatusType.Active;
            return mutate({ variables: { data: { status }, id: ad.id } });
        },
    };
};

export default useToggleAdvertisementStatus;
