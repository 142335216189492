import React, { PropsWithChildren } from 'react';

import LoadingError from './LoadingError';

/**
 * Properties for {@link QueryState} component
 */
export type QueryStateProps = PropsWithChildren<{
    /**
     * Error object from the state, when given component will display error state
     */
    error?: Error,

    /**
     * Loading state flag, when true will be in loading state. Loading has precedence over error and
     * success state.
     */
    loading?: boolean,

    /**
     * Any react node to display as loading indicator.
     */
    loadingIndicator?: React.ReactNode,
}>;

/**
 * Query state to display current query state
 */
const QueryState: React.FC<QueryStateProps> = ({
    error,
    loading,
    children,
    loadingIndicator = (<span>Loading...</span>),
}): React.ReactElement<any, any> => {
    if (loading) {
        return (<>{loadingIndicator}</>);
    }

    if (error) {
        return <LoadingError error={error} />;
    }

    return <>{children}</>;
};

export default QueryState;
