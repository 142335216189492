import {
    Button,
    FormHelperText,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { AgentIcon } from '../Icon';
import EnabledStatus from '../Agent/EnabledStatus';
import FormDialog from '../Common/FormDialog';
import LANG from '../../Lang/StaticTexts';
import Stack from '../Common/Stack';
import { makeStyles } from '@mui/styles';

/**
 * Options provided during activation
 */
export type ActivateSearchAgentOptions = {
    isPublic: boolean,
    allowNotifications: boolean,
};

/**
 * Properties for {@link ActivateSearchAgent} component.
 */
export type ActivateSearchAgentProps = {
    onActivate?: (options: ActivateSearchAgentOptions) => void,
    onlyButton?: boolean,
};

const useStyles = makeStyles(() => ({
    icon: {
        width: 38,
        height: 38,
        marginTop: -5,
        marginBottom: -5,
        marginRight: 5,
    },
}));

/**
 * Activate the search agent ui
 */
const ActivateSearchAgent = ({
    onActivate,
    onlyButton,
}: ActivateSearchAgentProps) => {
    const [open, setOpen] = useState(false);
    const [isPublic, setPublic] = useState(false);
    const [allowNotifications, setNotifications] = useState(false);
    const classes = useStyles();

    const handleActivate = () => {
        setOpen(false);
        onActivate?.({ isPublic, allowNotifications });
    };

    const button = (
        <Button variant="contained" onClick={() => setOpen(true)} fullWidth size="large">
            <AgentIcon color="inherit" className={classes.icon} />
            Suchagenten einrichten
        </Button>
    );

    return (
        <>
            {onlyButton ? button : (
                <Stack alignItems="center" spacing={1} pt={3}>
                    <Typography variant="body1" component="span" align="center">
                        Sofort-Nachricht bei neuen Angeboten?
                    </Typography>
                    <Tooltip title={(
                        <Typography variant="inherit">
                            <strong>NICHTS VERPASSEN</strong>: Ihr Suchagent benachrichtigt Sie automatisch bei passenden Angeboten.
                        </Typography>
                    )}>
                        {button}
                    </Tooltip>
                </Stack>
            )}
            <FormDialog
                open={open}
                title="Suchagent einrichten"
                onClose={() => setOpen(false)}
                onAction={handleActivate}
                action="Suchagent erstellen"
            >
                <Stack alignItems="center" spacing={2}>
                    <div>
                        <EnabledStatus
                            label="Benachrichtigungen"
                            enabled={allowNotifications}
                            onChange={setNotifications}
                        />
                        <FormHelperText>
                            {LANG.searchAgentOptionPublic}
                        </FormHelperText>
                    </div>
                    <div>
                        <EnabledStatus
                            label="In Suche erscheinen"
                            enabled={isPublic}
                            onChange={setPublic}
                        />
                        <FormHelperText>
                            {LANG.searchAgentOptionNotifications}
                        </FormHelperText>
                    </div>
                </Stack>
            </FormDialog>
        </>
    );
}

export default ActivateSearchAgent;
