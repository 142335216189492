/**
* Terms document, follows the keystone editor structure. Generated by creating a document in the
* backend and copying its structure.
*/
const imprintDocument = [
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Falls Sie allgemeine Fragen zur ApothekenBörse oder der Registrierung haben, können Sie uns gerne kontaktieren:"
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "T. +43 1 404 14-300\nM. "
            },
            {
                "type": "link",
                "href": "mailto://service@apothekerverband.at",
                "children": [
                    {
                        "text": "service@apothekerverband.at"
                    }
                ]
            },
        ]
    }
];

export default imprintDocument;
