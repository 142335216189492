import {
    Button,
    Divider,
    Link,
    Stack,
    Theme,
} from '@mui/material';
import React, { useMemo, useState } from 'react';

import { makeStyles } from '@mui/styles';
import useChangeEffect from '../../Hooks/useChangeEffect';

/**
 * Picker for month values
 */
export type MonthPickerProps = {
    /**
     * Typed value
     */
    initial?: Date,

    /**
     * Change handler
     */
    onChange?: (value?: Date) => void,
};


/**
 * Styles used in {@link BottomBackLink} component.
 */
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(0.5),
    },
    text: {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
    },
    contained: {},
    divider: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
}));

// Some date variables
const monthNames = ['Jän', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
const currentYear = new Date().getFullYear();
const yearSelection = new Array(4).fill(1).map((_x, i) => (i + currentYear));
const monthDisplayGroups = [[0, 1, 2, 3], [4, 5, 6, 7], [8, 9, 10, 11]];

/**
 * Month picker
 */
const MonthPicker = ({
    initial,
    onChange,
}: MonthPickerProps) => {
    const initialDate = (initial && initial instanceof Date && initial) || undefined;
    const [selectedMonth, setSelectedMonth] = useState(initialDate?.getMonth());
    const [selectedYear, setSelectedYear] = useState(initialDate?.getFullYear() ?? currentYear);
    const dateFromSelection = useMemo(() => {
        return selectedMonth !== undefined && selectedYear !== undefined
            ? new Date(selectedYear, selectedMonth, 1)
            : undefined;
    }, [selectedMonth, selectedYear]);
    useChangeEffect(() => { onChange?.(dateFromSelection); }, [dateFromSelection]);
    const { divider: dividerClass, ...classes } = useStyles();

    const handleReset = () => {
        setSelectedMonth(undefined);
        setSelectedYear(currentYear);
    };

    return (
        <Stack p={1}>
            <Stack direction="row">
                {yearSelection.map((name) => (
                    <Button
                        key={name}
                        onClick={() => setSelectedYear(name)}
                        variant={name === selectedYear ? 'contained' : 'text'}
                        classes={classes}
                    >
                        {name}
                    </Button>
                ))}
            </Stack>
            <Divider className={dividerClass} />
            {monthDisplayGroups.map((group, groupIndex) => (
                <Stack direction="row">
                    {group.map((monthIndex) => (
                        <Button
                            key={monthIndex}
                            onClick={() => setSelectedMonth(monthIndex)}
                            variant={monthIndex === selectedMonth ? 'contained' : 'text'}
                            size="small"
                            classes={classes}
                        >
                            {monthNames[monthIndex]}
                        </Button>
                    ))}
                </Stack>
            ))}
            <Divider className={dividerClass} />
            <Link onClick={handleReset} component="button">Ab sofort</Link>
        </Stack>
    );
};

export default MonthPicker;
