import React, { PropsWithChildren } from 'react';

/**
 * Properties for {@link StatefulTab}
 */
export type StatefulTabProps = {
    /**
     * Current active tab
     */
    current: number,

    /**
     * Tab index number
     */
    index: number,

    /**
     * Tab index name
     */
    name: string,

    /**
     * Class name passed to the root container
     */
    className?: string,
} & PropsWithChildren<{}>;

/**
 * Display a tab only when the active tab indicates as such.
 */
const StatefulTab = ({
    className,
    children,
    current,
    index,
    name,
}: StatefulTabProps) => {
    const hidden = current !== index;

    return (
        <div
            role="tabpanel"
            hidden={hidden}
            id={`tab-content-${name}`}
            aria-labelledby={`tab-content-${name}`}
            className={className}
        >
            {hidden ? null : children}
        </div>
    );
};

export default StatefulTab;
