import { gql } from '../Types/Client';
import { useQuery } from '@apollo/client';

// The query to get all for user
export const GET_CONVERSATIONS = gql(/* GraphQL */ `
    query GetConversationsList {
        conversations(orderBy: { lastActivity: desc }) {
            id
            partner {
                id
                name
            }
            advertisment {
                id
                name
            }
            newMessageCount
            lastSeenRecipient
            lastSeenUser
        }
    }
`);

/**
 * Use users conversations list from server.
 */
const useConversations = () => {
    const { data, ...queryState } = useQuery(
        GET_CONVERSATIONS, { fetchPolicy: 'cache-and-network' }
    );
    return { conversations: data?.conversations ?? [], ...queryState };
};

export default useConversations;
