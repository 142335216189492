import {
    Badge,
    Button,
    Divider,
    IconButton,
    List,
    Stack,
} from '@mui/material';
import React, { Fragment } from 'react';

import AppLink from '../Common/AppLink';
import { MessagesIcon } from '../Icon';
import NoResultsMessage from '../Common/NoResultsMessage';
import PostListItem from '../Post/ListItem';
import QueryState from '../Common/QueryState';
import StatusToggle from '../Advertisement/StatusToggle';
import {
    makeStyles,
} from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import useUserAdvertisments from '../../Hooks/useUserAdvertisments';

/**
 * Properties for {@link PostList}
 */
export type PostListProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,
};

/**
 * Styles used in {@link AgentItem} component.
 */
const useStyles = makeStyles({
    /* Display button as small link text */
    buttonLink: {
        fontWeight: 'normal',
        fontSize: '0.8rem',
    },
});

/**
 * Logo with cube and word mark and subline
 */
const PostList = ({
    className,
}: PostListProps) => {
    const { data, ...queryState } = useUserAdvertisments();
    const navigate = useNavigate();
    const classes = useStyles();

    return (
        <Stack alignItems="flex-start">
            <List className={className} sx={{ width: '100%' }}>
                {data.map((item, i) => (
                    <Fragment key={`post_list_item_${item.id}`}>
                        <PostListItem
                            item={item}
                            showCreatedDate
                            secondaryAction={item.newMessageCount < 1 ? undefined : (
                                <Badge badgeContent={item.newMessageCount} color="success">
                                    <IconButton edge="end" aria-label="delete">
                                        <MessagesIcon />
                                    </IconButton>
                                </Badge>
                            )}
                            lastRow={(
                                <Stack
                                    direction="row"
                                    spacing={0.5}
                                    divider={<Divider orientation="vertical" flexItem />}
                                >
                                    <AppLink to={`/inserat/${item?.id}`} color="text.primary" className={classes.buttonLink}>
                                        Inserat anzeigen
                                    </AppLink>
                                    <AppLink to={`/bearbeiten/${item?.id}`} color="text.primary" className={classes.buttonLink}>
                                        Inserat bearbeiten
                                    </AppLink>
                                    <StatusToggle className={classes.buttonLink} item={item} />
                                </Stack>
                            )}
                        />
                        <Divider component="li"  />
                    </Fragment>
                ))}
            </List>
            <QueryState {...queryState} />
            <NoResultsMessage items={data} detailMessage="Wenn Sie an einem Verkauf interessiert sind, erstellen Sie ihr erstes Inserat." />
            <Button size="large" variant="contained" onClick={() => navigate('/erstellen')}>
                Inserat erstellen
            </Button>
        </Stack>
    );
};

export default PostList;
