"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Options config
 */
const optionsConfig = [
    { label: 'Info - Startseite mehr erfahren', value: 'info' },
    { label: 'Hilfe Login', value: 'login-help' },
];
/**
 * Export the config
 */
exports.default = optionsConfig;
