/**
* Terms document, follows the keystone editor structure. Generated by creating a document in the
* backend and copying its structure.
*/
const imprintDocument = [
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Offenlegung nach § 25 Mediengesetz, Angaben nach E-Commerce Gesetz"
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Österreichischer Apothekerverband\nInteressenvertretung der selbständigen Apotheker\nSpitalgasse 31, 1090 Wien\nT. +43 1 404 14-300\nF. +43 1 408 62 80\nM. service@apothekerverband.at\n"
            },
            {
                "type": "link",
                "href": "https://www.apothekerverband.at",
                "children": [
                    {
                        "text": "www.apothekerverband.at"
                    }
                ]
            },
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Der Österreichische Apothekerverband unterliegt als Verein der Aufsicht des Bundesministeriums für Inneres; Vertretungs- und zeichnungsberechtigt für den Österreichischen Apothekerverband ist der Präsident, in seiner Verhinderung der 1. Vizepräsident und bei deren Verhinderung der 2. Vizepräsident."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Die Mitglieder des Vereinsvorstands finden Sie hier.  \n  \nUID Nummer: ATU 37632102;"
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Tätigkeitsbereich:"
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Vertretung der Interessen sowie Information, Beratung und Unterstützung der selbständigen Apotheker als deren Interessenvertretung. Der auf freiwilliger Mitgliedschaft basierende Verein bezweckt gemäß seiner Satzung."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Inhalt des Onlineangebotes:"
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Diese Website des Österreichischen Apothekerverbandes wird mit Sorgfalt bearbeitet und laufend aktualisiert. Der Österreichische Apothekerverband übernimmt jedoch keine Haftung für die Richtigkeit, Vollständigkeit, Aktualität oder Qualität der bereitgestellten Informationen. Haftungsansprüche, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung der gebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind ausgeschlossen."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "DATENSCHUTZERKLÄRUNG"
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "DATENSCHUTZ",
                "italic": true
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Wir haben diese Datenschutzerklärung verfasst, um Ihnen gemäß den Vorgaben der EUDatenschutz-Grundverordnung zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar zu beschreiben. Für nähere Details zur Website empfehlen wir die Durchsicht der allgemeinen "
            },
            {
                "type": "link",
                "href": "/agb",
                "children": [
                    {
                        "text": "Nutzungsbedingungen"
                    }
                ]
            },
            {
                "text": "."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "AUTOMATISCHE DATENSPEICHERUNG",
                "italic": true
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie"
            }
        ]
    },
    {
        "type": "unordered-list",
        "children": [
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "die Adresse (URL) der aufgerufenen Webseite"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Browser und Browserversion"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "das verwendete Betriebssystem"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "die Adresse (URL) der zuvor besuchten Seite (Referrer URL)"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Datum und Uhrzeit"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "SPEICHERUNG PERSÖNLICHER DATEN",
                "italic": true
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Wenn Sie sich auf unserer Plattform registrieren, stellen Sie uns einige personenbezogene Daten über sich zur Verfügung, wie zB Vorname, Nachname, Geschlecht, Telefonnummer oder Ihre E-Mail-Adresse – zum Teil handelt es sich um Pflichtangaben. Diese Informationen werden in unseren Datenbanken gespeichert, um den von Ihnen bestellten Service bereitzustellen, unsere Dienstleistungen zu verbessern aber auch um Betrug vorzubeugen. Wenn Sie die Pflichtangaben nicht machen möchten, ist eine Registrierung auf der Plattform nicht möglich.  \n  \nEbenso verarbeiten wir personenbezogene Daten, die Sie bei der Aufgabe der Anzeige, bei Anfragen an den Inserenten und bei der Kommunikation mit uns generiert werden. Wir verarbeiten auch Daten, die Sie uns bei der Aktualisierung von Ihrem Account, Ihrer Anzeige und im Chat eingeben"
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln."
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "RECHTE LAUT DATENSCHUTZGRUNDVERORDNUNG",
                "italic": true
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen Datenschutzgesetzes (DSG) grundsätzlich die folgende Rechte zu:"
            }
        ]
    },
    {
        "type": "unordered-list",
        "children": [
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Recht auf Berichtung (Artikel 16 DSGVO)"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Widerspruchsrecht (Artikel 21 DSGVO)"
                            }
                        ]
                    }
                ]
            },
            {
                "type": "list-item",
                "children": [
                    {
                        "type": "list-item-content",
                        "children": [
                            {
                                "text": "Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "type": "paragraph",
        "children": [
            {
                "text": "Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite Sie unter "
            },
            {
                "type": "link",
                "href": "https://www.dsb.gv.at/",
                "children": [
                    {
                        "text": "https://www.dsb.gv.at/"
                    }
                ]
            },
            {
                "text": " finden."
            }
        ]
    }
];

export default imprintDocument;
