import { useCallback, useMemo } from 'react';

import { gql } from '../Types/Client';
import useAuth from './useAuth';
import { useQuery } from '@apollo/client';

// The query to get all for user
export const GET_CONVERSATION_MESSAGE_COUNT = gql(/* GraphQL */ `
    query GetConversationsListMessageCount {
        conversations {
            id
            newMessageCount
        }
    }
`);

/**
 * Use users conversations list from server.
 */
const useNewMessageCount = () => {
    const { isAuthenticated, authentication } = useAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const skipUnauthenticated = useCallback(() => (!isAuthenticated), [isAuthenticated, authentication]);

    // skipPollAttempt does not seem to work reliable, skip is needed
    const { data, ...queryState } = useQuery(
        GET_CONVERSATION_MESSAGE_COUNT,
        { pollInterval: 15000, skipPollAttempt: skipUnauthenticated, skip: !isAuthenticated },
    );
    const total = useMemo(() => (
        data?.conversations?.reduce(
            (memo, { newMessageCount }) => (memo + (newMessageCount || 0)),
            0,
        ) ?? 0
    ), [data]);
    return { total, ...queryState };
};

export default useNewMessageCount;
