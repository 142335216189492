import { gql } from '../Types/Client';
import { useQuery } from '@apollo/client';

export const GET_AUTHENTICATED = gql(/* GraphQL */ `
    query GetAuthenticatedItem {
        authenticatedItem {
            ... on User {
                id
                name
                firstName
                lastName
                salutation
                titlePrefix
                titleSuffix
                email
                createdAt
                userState
                userRole
                phoneNumber
            }
        }
    }
`);

/**
 * Autheticate a user on the server
 */
const useInitialAuthentication = () => {
    return useQuery(
        GET_AUTHENTICATED, { fetchPolicy: 'cache-and-network' },
    );
};

export default useInitialAuthentication;
