import { AdvertismentStatusType, AdvertismentTypeType, AdvertismentWhereInput } from '../Types/Client/graphql';

import type { FilterData } from '../Components/Advertisement/FilterForm';
import { gql } from '../Types/Client';
import { useQuery } from '@apollo/client';

// The query to get all filtered
export const GET_ALL = gql(/* GraphQL */ `
    query GetAdvertisments($where: AdvertismentWhereInput!) {
        advertisments(where: $where) {
            createdAt
            description
            grossProfit
            id
            name
            netSales
            operatingResult
            operatingTurn
            updatedAt
            type
            transferDate
            thumbnail
            status
            region { id, regionName }
            state { id, stateName, abbreviation }
        }
    }
`);

/**
 * Turn filter data to a graphql where expression. It some similarities with
 * {@link ../../../server/tasks/matchSearchProfiles.ts}.
 */
const filterToWhereInput = (filters: FilterData): AdvertismentWhereInput => {
    const and: Array<AdvertismentWhereInput> = [];

    and.push({ status: { equals: AdvertismentStatusType.Active } });

    if (filters.type) {
        // It is nescessary to typecast to enum value here
        and.push({ type: { equals: filters.type as AdvertismentTypeType } });
    }
    
    if (filters.transferDate) {
        and.push({
            OR: [
                { transferDate: { gte: filters.transferDate } },
                { transferDate: null },
            ]
        });
    }
    if (filters.location) {
        const stateLocations = filters.location
            .filter(({ type, children }) => (type === 'state' && (children?.length ?? 0) < 1));
        const regionLocations = filters.location.flatMap(({ children }) => (children || []));

        const OR: Array<AdvertismentWhereInput> = [];
        if (stateLocations.length > 0) {
            OR.push({
                state: { id: { in: stateLocations.map(({ id }) => (id)) } },
            });
        }

        if (regionLocations.length > 0) {
            OR.push({
                region: { id: { in: regionLocations.map(({ id }) => (id)) } },
            });
        }

        and.push({ OR });
    }
    if (filters.excludeRegions) {
        and.push({OR: [
            { region: null },
            { region: { id: { not: { in: filters.excludeRegions.map(({ id }) => (id)) } } } },
        ]});
    }

    if (filters.profit) {
        and.push({ netSales: { gte: filters.profit[0], lte: filters.profit[1] } });
    }

    return { AND: and };
}

/**
 * Use advertisments from server.
 */
const useAdvertisments = (filters?: FilterData, skip: boolean = false) => {
    const where = filters ? filterToWhereInput(filters) : {};
    const { loading, data, error } = useQuery(
        GET_ALL,
        { variables: { where }, fetchPolicy: 'cache-and-network', skip },
    );
    return { loading, data, error };
};

export default useAdvertisments;
