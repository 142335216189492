import {
    TextField,
    Typography,
} from '@mui/material';

import { ApolloType } from '../../Lib/Apollo';
import React from 'react';
import Stack from '../Common/Stack';
import useForm from '../../Hooks/useValidatedForm';

/**
 * Data from inputs in component
 */
export type FormData = {
    description: string,
};

/**
 * Properties for {@link Form} component.
 */
export type FormProps = {
    /**
     * Handler when form is submitted
     */
    onSubmit: (values: FormData) => void,

    /**
     * Initial data to edit
     */
    values?: ApolloType<FormData>,

    /**
     * Form id, use `<button form="[id-goes-here]" />` to submit form from outside form
     */
    id: string,
};

/**
 * Display form for creating a new ad.
 *
 * Note: {@link memo} is nescessary because otherwise if `onValuesChange` changes state in the
 * parent component, the component itself is rerendered, causing {@link useChangeEffect} to fire
 * and causing an infite lopp and this poor performance or crash.
 */
const Form = ({ onSubmit, id, values }: FormProps) => {
    // Form state management
    const { getFieldProps, handleSubmit } = useForm<FormData>({}, { values: values as FormData });

    return (
        <Stack
            dir="vertical"
            justifyContent="space-between"
            component="form"
            id={id}
            onSubmit={handleSubmit((data) => onSubmit(data))}
            role="form"
            spacing={1}
        >
            <div>
                <Typography variant="subtitle1">Beschreibung</Typography>
                <Typography variant="body2" color="text.secondary">
                    Eine optionale Beschreibung für Ihre Anzeige.
                </Typography>
            </div>
            <TextField
                label=""
                placeholder="Beschreiben Sie Ihr Objekt"
                margin="dense"
                multiline
                rows={16}
                {...getFieldProps('description')}
            />
        </Stack>
    );
};

export default Form;
