import {
    Button,
    Stack,
    Theme,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

import {
    makeStyles,
} from '@mui/styles';

/**
 * Properties for {@link Youtube}
 */
export type YoutubeProps = {
    /**
     * Youtube embedding id
     */
    embedId: string,
};

/**
 * Styles for {@link Youtube}
 */
const useStyles = makeStyles(({
    palette,
    spacing,
}: Theme) => ({
    youtube: {
        backgroundColor: palette.grey[900],
        color: palette.text.secondary,
        textAlign: 'center',
        width: 560,
        minHeight: 315,
        display: 'flex',
        flexDirection: 'column',
    },
    privacyNotice: {
        padding: spacing(2),
    },
    frame: {
        flex: 1,
    },
}));

/**
 * Component for embedding youtube videos in a DSGVO compatible fashion. Asks once to allow 3rd
 * Party youtube content.
 */
const Youtube = ({
    embedId,
}: YoutubeProps) => {
    const [consent, setConsent] = useState(false);
    const classes = useStyles();

    if (!consent) {
        return (
            <Stack spacing={1} className={[classes.youtube, classes.privacyNotice].join(' ')}>
                <Typography variant="h3" color="inherit">
                    Externer Inhalt
                </Typography>
                <Typography variant="body2" color="inherit">
                    Dieser Video-Inhalt kommt von einem externen Dienstleister (<strong>Youtube</strong>).
                    Wenn Sie fortfahren tauscht ihr Browser Daten mit
                    dem Netzwerk dieses Dienstleisters aus. Der Apothekenverband hat keinen Zugriff
                    oder Einfluss auf Art und Umfang bzw. Speicherung und Verarbeitung dieser Daten.
                    Weitere Informationen dazu finden Sie in den Datenschutzinformationen von Youtube.
                </Typography>
                <Button variant="contained" onClick={() => setConsent(true)}>erlauben & Video laden</Button>
            </Stack>
        );
    }

    return (
        <div className={classes.youtube}>
        	<iframe
                className={classes.frame}
                title="Eingebundenes Youtube Video"
                width="100%"
                height="100%"
                frameBorder={0}
                allowFullScreen
                src={`https://www.youtube.com/embed/${embedId}?rel=0&showinfo=0`}
            />
        </div>
    );
};

export default Youtube;
