import {
    Stack,
    Typography,
} from '@mui/material';

import FormStateMessage from '../Common/FormStateMessage';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Properties for {@link EditState}
 */
export type EditStateProps = {
    /**
     * The state to display
     */
    state: 'loading' | 'completed',
};

/**
 * Texts for loading and completed state
 */
const TEXTS_LOADING = (
    <Stack spacing={1} alignItems="center">
        <Typography variant="h3">
            Ihr Inserat wird bearbeitet...
        </Typography>
        <Typography variant="body1">
            Die Daten werden bearbeitet und überprüft. Bitte einen Moment Geduld.
        </Typography>
    </Stack>
);
const TEXTS_COMPLETED = (
    <Stack spacing={1} alignItems="center">
        <Typography variant="h3">
            Inserat erfolgreich bearbeitet
        </Typography>
        <Typography variant="body1">
            Ihr Inserat ist damit für andere Benutzer sichtbar mit den neuen Daten sichtbar.
        </Typography>
    </Stack>
);

/**
 * Logo with cube and word mark and subline
 */
const EditState = ({
    state,
}: EditStateProps) => {
    const navigate = useNavigate();
    return (
        <FormStateMessage
            state={state}
            loadingMessage={TEXTS_LOADING}
            completedMessage={TEXTS_COMPLETED}
            completedActionLabel="zurück"
            onAction={() => navigate(-1)}
        />
    );
};

export default EditState;
