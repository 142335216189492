import {
    Stack,
    Theme,
    Typography,
} from '@mui/material';

import CubeLogo from '../../Layout/CubeLogo';
import React from 'react';
import { makeStyles } from '@mui/styles';

/**
 * Styles used in {@link Loading} component.
 */
const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    main: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    },
    message: {
        margin: spacing(4),
        marginTop: `20vh`,
    },
    '@global': {
        body: {
            margin: 0,
        },
    },
}));

/**
 * Loading page when nothing else is to be displayed
 */
const Loading = () => {
    const classes = useStyles();
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100vh"
            className={classes.main}
        >
            <CubeLogo onClick={() => {}} />
            <div className={classes.message}>
                <Typography component="p" variant="body1" color="inherit" align="center">
                    Einen Moment bitte... Die Webseite wird initialisiert.
                </Typography>
            </div>

        </Stack>
    );
}

export default Loading;
