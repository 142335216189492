import { gql } from '../Types/Client';
import { useQuery } from '@apollo/client';

// The query to get all by advertisment
export const GET_IMAGES = gql(/* GraphQL */ `
    query GetAdvertismentImages($id: ID!) {
        advertismentImages(where: { advertisment: { id: { equals: $id } } }, orderBy: [{ sort: asc }]) {
            id,
            sort,
            image{ url }
        }
    }
`);

/**
 * Use advertisment images from server.
 */
const useAdvertismentImages = (id: string) => {
    const { data, ...state } = useQuery(
        GET_IMAGES,
        { variables: { id: `${id}` }, fetchPolicy: 'cache-and-network' },
    );

    const images = data?.advertismentImages ?? [];
    const imageUrls: Array<string> = images
        .map(({ image }) => (image?.url ?? ''))
        .filter((nonNull) => (nonNull !== ''));

    return { imageUrls, images, ...state };
};

export default useAdvertismentImages;
