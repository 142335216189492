import { GET_USER_PROFILES } from './useSearchProfiles';
import { SearchProfileUpdateInput } from '../Types/Client/graphql';
import { gql } from '../Types/Client';
import { useMutation } from '@apollo/client';
import useNotifications from './useNotifications';

// Create search profile mutation
export const CREATE_SEARCH_PROFILE = gql(/* GraphQL */ `
    mutation CreateSearchProfile($data: SearchProfileCreateInput!) {
        createSearchProfile(data: $data) {
            id
            name
            sendNotificationMails
            isPublic
        }
    }
`);

// Modify search profile mutation
export const MODIFY_SEARCH_PROFILE = gql(/* GraphQL */ `
    mutation ModifySearchProfile($data: SearchProfileUpdateInput!, $id: ID!) {
        updateSearchProfile(where: {id: $id}, data: $data) {
            id
            name
            sendNotificationMails
            isPublic
        }
    }
`);

// The query delete
export const DELETE_SEARCH_PROFILE = gql(/* GraphQL */ `
    mutation DeleteSearchProfile($id: ID!) {
        deleteSearchProfile(where: { id: $id }) {
            id
        }
    }
`);


/**
 * Use search profile mutation. Includes notifications for errors and success.
 */
const useMutateSearchProfile = (id?: string) => {
    // Notifications and appropriate mutation
    const { addSuccess, addError } = useNotifications();

    const action = id ? 'bearbeitet' : 'erstellt';
    const [mutate, mutationState] = useMutation(
        id ? MODIFY_SEARCH_PROFILE : CREATE_SEARCH_PROFILE, {
            onCompleted: () => addSuccess(`Suchagent wurde ${action}`),
            onError: () => addError(`Suchagent konnte nicht ${action} werden`),
            refetchQueries: [GET_USER_PROFILES],
        }
    );
    const [triggerDelete, deletionState] = useMutation(
        DELETE_SEARCH_PROFILE, {
            variables: { id: id ?? '' },
            refetchQueries: [GET_USER_PROFILES],
            onCompleted: () => addSuccess('Suchagent wurde gelöscht'),
            onError: () => addError('Suchagent konnte nicht gelöscht werden'),
        }
    );

    // Compose mutation state
    return {
        ...mutationState,
        isDeleting: deletionState.loading,
        mutate: (data: SearchProfileUpdateInput) => {
            // Remove inmutable params from data
            const { searchParameters, ...cleanedData } = data;
            return mutate({ variables: { data: id ? cleanedData : data, id: id ?? '' } });
        },
        delete: id ? triggerDelete : () => {},
    };
};

export default useMutateSearchProfile;
