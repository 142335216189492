import {
    Typography,
    TypographyProps,
} from '@mui/material';

import React from 'react';
import Switch from '../Common/Switch';

/**
 * Properties for {@link EnabledStatus} component.
 */
export type EnabledStatusProps = {
    /**
     * Label
     */
    label: string;

    /**
     * Enabled status
     */
    enabled: boolean;

    /**
     * Listen to status changes
     */
    onChange?: (value: boolean) => void;
} & Omit<TypographyProps, 'onChange'>;

/**
 * Display a labelled property that has an enabled state and can be changed.
 */
const EnabledStatus = ({
    label,
    enabled,
    onChange,
    ...typoProps
}: EnabledStatusProps) => {
    return (
        <Typography variant="body2" {...typoProps}>
            {label}:{' '}
            <Typography
                variant="inherit"
                component="span"
                color={enabled ? 'primary' : 'textSecondary'}
            >
                {onChange
                    ? <Switch checked={enabled} onChange={(_, enabled) => onChange(enabled)} />
                    : null
                }
                {enabled ? 'Ein' : 'Aus'}
            </Typography>
        </Typography>
    );
}

export default EnabledStatus;
