import { RegistrationFormData } from '../Components/User/RegistrationForm';
import { gql } from '../Types/Client';
import { mergeStates } from '../Lib/Apollo';
import { useMutation } from '@apollo/client';

// The query to register a user
export const REGISTER = gql(/* GraphQL */ `
    mutation RegisterUser($email: String!, $password: String!, $name: String!, $firstName: String, $salutation: String, $titlePrefix: String, $lastName: String, $titleSuffix: String, $company: String, $jobRole: String, $employeeId: String, $phoneNumber: String, $stateId: ID) {
        registerLocalUser(email: $email, password: $password, name: $name, firstName: $firstName, salutation: $salutation, titlePrefix: $titlePrefix, lastName: $lastName, titleSuffix: $titleSuffix, company: $company, jobRole: $jobRole, employeeId: $employeeId, phoneNumber: $phoneNumber, stateId: $stateId) {
            uploadToken
        }
    }
`);

// Document upload
export const UPLOAD_USER_DOCUMENT = gql(/* GraphQL */`
    mutation UploadRegistrationDoc($data: UserDocumentCreateInput!) {
        createUserDocument(data: $data) {
            id
        }
    }
`);


export const blankoData = {
    email: '',
    password: '',
    name: '',
    firstName: '',
    lastName: '',
    salutation: '',
    titlePrefix: '',
    titleSuffix: '',
    company: '',
    jobRole: '',
    jobId: '',
    phoneNumber: '',
    stateId: undefined,
};

/**
 * Register a user on the server
 */
const useRegisterUser = () => {
    const [mutate, { data, ...mutationState }] = useMutation(
        REGISTER,
        { variables: blankoData },
    );
    const [uploadDoc, { error, ...uploadDocState }] = useMutation(UPLOAD_USER_DOCUMENT);

    const register = ({ document, ...data }: RegistrationFormData): Promise<boolean> => {
        return mutate({ variables: data })
            .then((registeredUser) => uploadDoc({
                variables: {
                    data: {
                        uploadToken: registeredUser.data?.registerLocalUser.uploadToken,
                        document: { upload: document },
                    },
                },
            }).catch(() => true)) // ignore errors
                .then(() => true);
    };

    return { ...mergeStates(mutationState, uploadDocState), data, register };
};

export default useRegisterUser;
