import { useMutation, useQuery } from '@apollo/client';

import { AdvertismentTypeType } from '../Types/Client/graphql';
import { FormData } from '../Components/Advertisement/Form';
import { GET_ONE_BY_ID } from './useAdvertisment';
import { GET_USER_ADVERTISMENTS } from './useUserAdvertisments';
import { gql } from '../Types/Client';
import { useMemo } from 'react';
import useNotifications from './useNotifications';

// Create advertisment mutation
export const CREATE = gql(/* GraphQL */ `
    mutation CreateAdvertisment($data: AdvertismentCreateInput!) {
        createAdvertisment(data: $data) {
            id
            createdAt
            updatedAt
        }
    }
`);

// Modify advertisment mutation
export const MODIFY = gql(/* GraphQL */ `
    mutation ModifyAdvertisment($data: AdvertismentUpdateInput!, $id: ID!) {
        updateAdvertisment(where: {id: $id}, data: $data) {
            id
            createdAt
            updatedAt
        }
    }
`);

// Bulk image uploading
const UPLOAD_IMAGE_BULK_OPERATION = /* GraphQL */`
    mutation UploadMulti($data: [AdvertismentImageCreateInput!]!) {
        createAdvertismentImages(data: $data) {
            id
        }
    }
`;
export const UPLOAD_IMAGE_BULK = gql(UPLOAD_IMAGE_BULK_OPERATION);


/**
 * Use advertisment mutation. Includes notifications for errors and success.
 */
const useMutateAdvertisment = (id?: string) => {
    // Notifications and appropriate mutation
    const { addSuccess, addError } = useNotifications();

    // Query of existing data
    const { loading, data, error } = useQuery(
        GET_ONE_BY_ID,
        { variables: { id: `${id}` }, skip: id === undefined },
    );
    const editObject = useMemo(() => {
        return data && data?.advertisment && ({
            ...data.advertisment,
            state: data.advertisment.state ? {
                id: data.advertisment.state.id!,
                name: data.advertisment.state.stateName!,
                type: 'state' as const,
            } : undefined,
            region: data.advertisment.region ? {
                id: data.advertisment.region.id!,
                name: data.advertisment.region.regionName!,
                type: 'region' as const,
            } : undefined,
        })
    }, [data]);

    const action = id ? 'bearbeitet' : 'erstellt';
    const [mutate, mutationState] = useMutation(
        id ? MODIFY : CREATE, {
            onCompleted: () => addSuccess(`Inserat wurde ${action}`),
            onError: () => addError(`Inserat konnte nicht ${action} werden`),
            refetchQueries: [GET_USER_ADVERTISMENTS, GET_ONE_BY_ID],
        }
    );
    // @ts-ignore
    const [uploadFiles] = useMutation(UPLOAD_IMAGE_BULK);

    // Compose mutation state
    return {
        ...mutationState,
        mutate: (formData: FormData) => {
            const { fotos, ...adData } = formData;
            const data = {
                ...adData,
                // Autoname
                name: `${id ? 'Updated' : 'Erstellt'} ${new Date().toISOString()}`,
                // Explicit cast for enum
                type: adData.type as AdvertismentTypeType,
                state: { connect: { id: formData.state?.id } },
                region: formData.region ? { connect: { id: formData.region?.id } } : undefined,
            };

            //
            return mutate({ variables: { data, id: id ?? '' } })
                .then((created) => {
                    // Error return plain
                    if (!created.data || !fotos?.length) {
                        return created;
                    }

                    // Upload multiple files
                    return uploadFiles({
                        variables: {
                            data: fotos.map((foto, i) => ({
                                image: { upload: foto },
                                sort: `${i+1}.0`,
                                advertisment: { connect: { id: created.data?.createAdvertisment?.id } },
                            })),
                        }
                    });
                });
        },
        editObject,
        dataLoading: loading,
        loadingError: error,
        completed: mutationState.called && !mutationState.loading && !mutationState.error,
    };
};

export default useMutateAdvertisment;
