import {
    Button,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    Typography,
} from '@mui/material';

import { MessagesIcon } from '../Icon';
import React from 'react';
import { SearchProfilesQuery } from '../../Types/Client/graphql';
import Stack from '../Common/Stack';
import {
    makeStyles,
} from '@mui/styles';
import useAuth from '../../Hooks/useAuth';

/**
 * Properties for {@link ResultItem} component.
 */
export type ResultItemProps = {
    /**
     * Item from matches
     */
    item: Exclude<SearchProfilesQuery['searchProfiles'], null | undefined>[number],

    /**
     * Callback when contact button is clicked
     */
    onContact: () => void,
};

/**
 * Styles used in {@link ResultItem} component.
 */
const useStyles = makeStyles(({ palette }: Theme) => ({
    contactButton: {
        backgroundColor: palette.text.secondary,
        paddingTop: 6,
        paddingBottom: 6,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: palette.divider,
            color: palette.text.primary,
        },
    },
}));

/**
 * Display a result of a person behind search agent.
 */
const ResultItem = ({
    item,
    onContact,
}: ResultItemProps) => {
    const classes = useStyles();
    const { authentication } = useAuth();
    return (
        <>
            <ListItemText
                primary={(
                    <Stack dir="horizontal">
                        <Typography variant="subtitle1" color="primary">
                            {item.user?.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {item.user?.userSource}
                        </Typography>
                    </Stack>
                )}
            />
            {authentication?.id === item.user?.id ? null : (
                <ListItemSecondaryAction>
                    <Button
                        endIcon={<MessagesIcon color="inherit" />}
                        variant="contained"
                        size="small"
                        className={classes.contactButton}
                        onClick={onContact}
                        aria-label="Kontaktieren"
                    >
                        Kontaktieren
                    </Button>
                </ListItemSecondaryAction>
            )}
        </>
    );
}

export default ResultItem;
