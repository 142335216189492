import { Advertisment, AdvertismentStatusType } from '../../Types/Client/graphql';
import {
    Link,
    Skeleton,
} from '@mui/material';

import React from 'react';
import useToggleAdvertisementStatus from '../../Hooks/useToggleAdvertisementStatus';

/**
 * Properties for {@link StatusToggle}
 */
export type StatusToggleProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * The item
     */
    item: Advertisment,
};

/**
 * Logo with cube and word mark and subline
 */
const StatusToggle = ({
    className,
    item,
}: StatusToggleProps) => {
    const { toggle, loading } = useToggleAdvertisementStatus();
    if (item?.status === AdvertismentStatusType.Blocked) {
        return null;
    }


    const label = item?.status === AdvertismentStatusType.Active ? 'deaktivieren' : 'aktivieren';
    return (
        <Link
            onClick={() => {
                if (loading) return;
                toggle(item);
            }}
            color="text.primary"
            className={className}
            component="button"
        >
            {loading ? <Skeleton aria-label="Wird geladen" /> : `Inserat ${label}`}
        </Link>
    );
};

export default StatusToggle;
