import { BookmarkIcon as BookmarkOffIcon, BookmarkOnIcon } from '../Icon';
import {
    Button,
    Grid,
    Skeleton,
    Typography,
} from '@mui/material';
import SidebarContainer, { Main, Sidebar } from '../Common/SidebarContainer';
import { adTitle, locationShortDisplay } from '../../Lib/Util';

import Actions from '../Advertisement/Actions';
import Container from '../Common/Container';
import ContainerHeader from '../Common/ContainerHeader';
import DescriptionText from '../Advertisement/DescriptionText';
import ImagesView from '../Advertisement/ImagesView';
import KeyInfos from '../Advertisement/KeyInfos';
import QueryState from '../Common/QueryState';
import React from 'react';
import Stack from '../Common/Stack';
import {
    Theme,
} from '@mui/material';
import {
    makeStyles,
} from '@mui/styles';
import useAdvertisment from '../../Hooks/useAdvertisment';
import useBookmark from '../../Hooks/useBookmark';
import useIdParam from '../../Hooks/useIdParam';

/**
 * Stylings used in {@link Advertisement} component.
 */
const useStyles = makeStyles(({ spacing }: Theme) => ({
    upper: {
        textTransform: 'uppercase',
    },
    specialHeader: {
        paddingBottom: spacing(0),
        marginTop: spacing(-2),
    },
}));

/**
 * Single advertisment display page
 */
const Advertisement = () => {
    const classes = useStyles();
    const queryId = useIdParam();
    const { data, ...queryState } = useAdvertisment(queryId || 0);
    const {
        isBookmarked,
        handleToggle,
        buttonText,
        loading,
    } = useBookmark(queryId || 0);

    if (!data?.advertisment) {
        return (null);
    }
    const {
        id, user, netSales, grossProfit, operatingTurn, operatingResult, transferDate,
    } = data.advertisment;

    return (
        <Container size="default">
            <QueryState {...queryState} loadingIndicator={(
                <>
                    <ContainerHeader
                        title={(<Skeleton aria-label="Daten werden geladen" />)}
                        titleTypographyProps={{ color: 'primary', className: '' }}
                        subtitle={(<Skeleton aria-hidden />)}
                        className={classes.specialHeader}
                    />
                    <Stack dir="vertical" spacing={2} pt={3} aria-hidden>
                        <Skeleton height={220} />
                        <SidebarContainer>
                            <Main>
                                <Stack spacing={1}>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Stack>
                            </Main>
                            <Sidebar>
                                <Stack alignItems="flex-start">
                                    <Button
                                        variant="text"
                                        onClick={handleToggle}
                                        startIcon={(
                                            loading
                                                ? null
                                                : (isBookmarked
                                                    ? <BookmarkOnIcon color="inherit" />
                                                    : <BookmarkOffIcon color="inherit" />
                                                )
                                        )}
                                    >
                                        {loading ? <Skeleton width={100} aria-label="Status wird geladen" /> : buttonText}
                                    </Button>
                                    <Grid container spacing={2} aria-hidden>
                                        <Grid item md={6} sm={12}>
                                            <Skeleton />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <Skeleton />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <Skeleton />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <Skeleton />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <Skeleton />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <Skeleton />
                                        </Grid>
                                        <Grid item md={12} sm={12}>
                                            <Skeleton height="2.5em" />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Sidebar>
                        </SidebarContainer>
                    </Stack>
                </>
            )}>
                <ContainerHeader
                    title={adTitle(data.advertisment)}
                    titleTypographyProps={{ color: 'primary', className: '' }}
                    subtitle={(
                        <Stack dir="horizontal" justifyContent="space-between" className={classes.upper}>
                            <Typography variant="body2" style={{ fontSize: 16 }}>Objekt: {id}</Typography>
                            <Typography variant="body2" style={{ fontSize: 16 }}>Ersteller: {user?.name}</Typography>
                        </Stack>
                    )}
                    className={classes.specialHeader}
                />
                <Stack dir="vertical">
                    <ImagesView advertisement={data.advertisment} />
                    <SidebarContainer>
                        <Main>
                            <DescriptionText text={data.advertisment?.description} />
                        </Main>
                        <Sidebar>
                            <Stack alignItems="flex-start">
                                <Button
                                    variant="text"
                                    onClick={handleToggle}
                                    startIcon={(
                                        loading
                                            ? null
                                            : (isBookmarked
                                                ? <BookmarkOnIcon color="inherit" />
                                                : <BookmarkOffIcon color="inherit" />
                                            )
                                    )}
                                >
                                    {loading ? <Skeleton width={100} /> : buttonText}
                                </Button>
                                <KeyInfos
                                    infos={[
                                        { type: 'transferDate', value: transferDate },
                                        { type: 'grossProfit', value: grossProfit },
                                        { type: 'netSales', value: netSales },
                                        { type: 'operatingTurn', value: operatingTurn },
                                        { type: 'operatingResult', value: operatingResult },
                                        { type: 'location', value: locationShortDisplay(data.advertisment) },
                                    ]}
                                />
                                <Actions advertisement={data.advertisment} />
                            </Stack>
                        </Sidebar>
                    </SidebarContainer>
                </Stack>
            </QueryState>
        </Container>
    );
}

export default Advertisement;
