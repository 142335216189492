import {
    ImageList,
    ImageListItem,
    Paper,
    Theme,
    useMediaQuery,
} from '@mui/material';

import FullScreenGalleryLauncher from './FullScreenGalleryLauncher';
import React from 'react';
import {
    makeStyles,
} from '@mui/styles';
import placeholder from './ImagePlaceholderBase64';

/**
 * Properties for {@link AllFotos} component.
 */
export type AllFotosProps = {
    /**
     * Fotos to display
     */
    fotos?: Array<string>,

    /**
     * Whether or not to show loading skeleton
     */
    loading?: boolean,
};

/**
 * Stylings used in {@link AllFotos} component.
 */
const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
    paper: {
        backgroundColor: 'transparent',
        position: 'relative',
    },
    placeholder: {
        height: 120,
        backgroundColor: '#f4f4f4',
        borderColor: 'transparent',
        borderRadius: 10,
    },
    foto: {
        borderRadius: 10,
        backgroundColor: '#f4f4f4',
        width: '100%',
        objectFit: 'cover',
    },
    allButton: {
        backgroundColor: palette.common.white,
        borderColor: palette.text.secondary,
        borderStyle: 'solid',
        borderWidth: 1,
        color: palette.text.secondary,
        position: 'absolute',
        borderRadius: 0,
        bottom: spacing(2),
        right: spacing(2),
        fontSize: 12,
        paddingTop: spacing(.5),
        paddingBottom: spacing(.5),
        lineHeight: 0,
        '&:hover': {
            backgroundColor: palette.grey[200],
        },
    },
}));

/**
 * Display all fotos of an advertisement or a placeholder if none are given. Shows an embedded
 * gallery component.
 */
const AllFotos = ({
    fotos = [],
    loading = false,
}: AllFotosProps) => {
    const classes = useStyles();
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const fotoDisplay = (fotos.length < 1 && !loading)
        ? (
            <Paper className={classes.placeholder} elevation={0} />
        )
        : (
            <ImageList
                variant="quilted"
                cols={isDesktop ? 3 : 2}
                rowHeight={200}
                gap={12}
            >
                <ImageListItem cols={2} rows={isDesktop ? 2 : 1}>
                    <img
                        src={fotos[0] ?? placeholder}
                        className={classes.foto}
                        alt="Apothekenbild 1"
                    />
                </ImageListItem>
                <ImageListItem cols={1} rows={1}>
                    <img
                        src={fotos[1] ?? placeholder}
                        className={classes.foto}
                        alt="Apothekenbild 2"
                    />
                </ImageListItem>
                <ImageListItem cols={1} rows={1}>
                    <img
                        src={fotos[2] ?? placeholder}
                        className={classes.foto}
                        alt="Apothekenbild 3"
                    />
                </ImageListItem>
            </ImageList>
        );
    const galleryImages = fotos.map((imgSrc) => ({ original: imgSrc, thumbnail: imgSrc }));

    return (
        <Paper className={classes.paper} elevation={0}>
            {fotoDisplay}
            {
                galleryImages.length > 0
                    ? (
                        <FullScreenGalleryLauncher
                            className={classes.allButton}
                            fotos={galleryImages}
                        />
                    )
                    : null
            }
        </Paper>
    );
}

export default AllFotos;
