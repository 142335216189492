import {
    AdvertismentsIcon,
    AgentIcon,
    BookmarkIcon,
} from '../Icon';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Tab,
    Tabs,
    Theme,
} from '@mui/material';
import React, { useState } from 'react';

import BookmarkList from './BookmarkList';
import NoResultsMessage from '../Common/NoResultsMessage';
import PostList from './PostList';
import StatefulTab from '../Common/StatefulTab';
import UserAgentsListing from '../User/UserAgentsListing';
import { UserUserRoleType } from '../../Types/Client/graphql';
import {
    makeStyles,
} from '@mui/styles';
import useAuth from '../../Hooks/useAuth';

/**
 * Properties for {@link UserContent}
 */
export type UserContentProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,
};


/**
 * Styles used in {@link UserContent} component.
 */
const useStyles = makeStyles(({ palette, spacing, breakpoints }: Theme) => ({
    tabContainer: {
        borderBottom: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: palette.primary.main,
        marginLeft: spacing(2),
        marginRight: spacing(2),
    },
    textColorPrimary: {
        color: palette.primary.main,
        opacity: 0.6,
        fontSize: '1.2em',
        textTransform: 'none',
        [breakpoints.up('md')]: {
            flex: 1,
        },
    },
    selected: {
        opacity: 1,
    },
}));

/**
 * Users content area. Displaying all interactions with post data.
 */
const UserContent = ({
    className,
}: UserContentProps) => {
    const { authentication } = useAuth();
    const role = authentication?.userRole;
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const handleTabChange = (_: React.SyntheticEvent, tabNum: number) => {
        setValue(tabNum);
    };
    return (
        <Card className={className}>
            <Box className={classes.tabContainer}>
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="user tabs"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab
                        icon={<AdvertismentsIcon />}
                        iconPosition="start"
                        label="Meine Inserate"
                        id="posts"
                        classes={classes}
                    />
                    <Divider orientation="vertical" sx={{ minHeight: 40, marginTop: 1 }} />
                    <Tab
                        icon={<BookmarkIcon />}
                        iconPosition="start"
                        label="Meine Merkliste"
                        id="bookmarks"
                        classes={classes}
                    />
                    <Divider orientation="vertical" sx={{ minHeight: 40, marginTop: 1 }} />
                    <Tab
                        icon={<AgentIcon />}
                        iconPosition="start"
                        label="Meine Suchagenten"
                        id="agents"
                        classes={classes}
                    />
                </Tabs>
            </Box>
            <CardContent>
                <StatefulTab name="posts" current={value} index={0}>
                    {
                        role !== UserUserRoleType.Seller
                            ? (
                                <NoResultsMessage
                                    message="Keine Berechtigung"
                                    detailMessage="Melden Sie sich mit Ihrem Apothekerverbandskonto an um Inserate erstellen zu können."
                                />
                            )
                            : (<PostList />)
                    }

                </StatefulTab>
                <StatefulTab name="bookmarks" current={value} index={2}>
                    <BookmarkList />
                </StatefulTab>
                <StatefulTab name="agents" current={value} index={4}>
                    <UserAgentsListing />
                </StatefulTab>
            </CardContent>
        </Card>
    );
};

export default UserContent;
