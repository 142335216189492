import { FilterData, MAX_RANGE_PROFIT } from '../Components/Advertisement/FilterForm';

import { SearchProfileFilter } from '../Hooks/useMatchingSearchProfiles';

/**
 * Value as number transformer, handling NaN values
 */
export const valueAsNumber = (value: string) => {
    const parsed = parseFloat(value);
    if ( isNaN(parsed) ) {
        return undefined;
    }

    return parsed % 1 !== 0 ? parsed : Math.trunc(parsed);
};

/**
 * Generate search profile filter from profile
 */
export const filterToSearchProfileFilter = (data: FilterData): SearchProfileFilter => {
    const { location, profit, type, ...same } = data;

    const stateId = data.location?.find(({ type }) => (type === 'state'))?.id;
    const regionId = stateId && data.location?.find(({ type }) => (type === 'region'))?.id;
    const netSales = profit?.[1];

    return {
        ...same,
        type: type !== '' ? type : undefined,
        netSales: netSales === MAX_RANGE_PROFIT[0] ? undefined : netSales,
        stateId,
        regionId,
    };
}
