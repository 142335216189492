import { Link, Typography } from '@mui/material';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogoStyles as useStyles } from './Styles';

/**
 * Properties for {@link CubeLogo}
 */
export type CubeLogoProps = {
    onClick?: () => void,
    className?: string,
};

/**
 * Logo with cube and word mark and subline
 */
const CubeLogo = ({
    onClick,
    className,
}: CubeLogoProps) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const handleClick = () => (onClick ? onClick() : navigate('/'));

    return (
        <div className={[classes.container, className || ''].join(' ')}>
            <Link component="button" onClick={handleClick} aria-label="Link zur Startseite">
                <img src={`${process.env.PUBLIC_URL}/apothekerverband.png`} alt="Logo Apothekenverband, Würfel" className={classes.logoCube} />
            </Link>
            <span className={classes.rightPart}>
                <Link component="button" onClick={handleClick} aria-label="Link zur Startseite">
                    <img src={`${process.env.PUBLIC_URL}/apotheken_boerse.svg`} alt="Logo Apothekenbörse, Wortmarke"  className={classes.logoWord} />
                </Link>
                <Typography className={classes.providedLine}>
                    Ein Service des <Link href="https://www.apothekerverband.at/" target="_blank" color="inherit">Österreichischen Apothekerverbands</Link>
                </Typography>
            </span>
        </div>
    );
};

export default CubeLogo;
