import {
    ListItemAvatar,
    ListItemText,
    ListItem as MuiListItem,
    Stack,
    Typography,
} from '@mui/material';

import React from 'react';
import Thumbnail from './Thumbnail';
import defaultThumb from '../Advertisement/defaultThumbnail.png';

/**
 * Properties for {@link ListItem}
 */
export type ListItemProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * Pass a secondary option to the list item
     */
    secondaryAction?: React.ReactNode,
};

/**
 * List item for a post
 */
const ListItem = ({
    className,
    secondaryAction,
}: ListItemProps) => {
    return (
        <MuiListItem
            alignItems="center"
            secondaryAction={secondaryAction}
            className={className}
            style={{ paddingTop: 20, paddingBottom: 20, backgroundColor: '#f2f2f2', opacity: 0.64 }}
          >
            <ListItemAvatar sx={{ marginRight: 1 }}>
                <Thumbnail
                    src={defaultThumb}
                />
            </ListItemAvatar>
            <ListItemText
                primary="Inserat nicht mehr verfügbar"
                primaryTypographyProps={{
                    variant: 'h4',
                    gutterBottom: true,
                }}
                secondary={
                    <Stack spacing={1}>
                        <Typography
                            variant="body2"
                            color="text.primary"
                        >
                            Objekt: ---
                        </Typography>
                    </Stack>
                }
            />
        </MuiListItem>
    );
};

export default ListItem;
