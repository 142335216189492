import {
    Box,
    CircularProgress,
    Link,
    Stack,
    Theme,
} from '@mui/material';
import React, { ReactNode, useEffect, useRef } from 'react';

import { CheckIcon } from '../Icon';
import makeStyles from '@mui/styles/makeStyles';

/**
 * Responsive styles for {@link FormStateMessage}
 */
const useStyles = makeStyles(({ palette }: Theme) => ({
    container: {
        position: 'relative',
        display: 'inline-flex',
    },
    finished: {
        color: palette.success.main,
    },
    label: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        fontSize: 148,
        opacity: 0,
        transition: 'opacity .5s ease-out',
    },
    iconDisplay: {
        opacity: 1,
        color: palette.success.main,
    },
}));

/**
 * Properties for {@link FormStateMessage}
 */
export type FormStateMessageProps = {
    /**
     * The state to display
     */
    state: 'loading' | 'completed',
    /**
     * Message for loading state
     */
    loadingMessage: ReactNode,
    /**
     * Message for completed state
     */
    completedMessage: ReactNode,
    /**
     * Optional action label to display when completed
     */
    completedActionLabel?: string
    /**
     * Callback when action is clicked
     */
    onAction?: () => void,
};

// Fixed icon size
const ICON_SIZE = 104;

/**
 * Logo with cube and word mark and subline
 */
const FormStateMessage = ({
    state,
    loadingMessage,
    completedMessage,
    completedActionLabel,
    onAction = () => {},
}: FormStateMessageProps) => {
    const classes = useStyles();
    const isLoading = state === 'loading';

    const progressProps = isLoading ? {} : { variant: 'determinate', value: 100 } as const;
    const iconClass = [classes.icon, isLoading ? '' : classes.iconDisplay].join(' ');

    // Scroll to top of element when state changes
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => ref.current?.scrollIntoView(true), [state]);

    return (
        <Stack alignItems="center" spacing={4} ref={ref} py={2}>
            <Box className={[classes.container, isLoading ? '' : classes.finished].join(' ')}>
                <CircularProgress thickness={1} {...progressProps} style={{ width: ICON_SIZE, height: ICON_SIZE }} color="inherit" />
                <Box className={classes.label}>
                    <CheckIcon className={iconClass} style={{ fontSize: ICON_SIZE }} />
                </Box>
            </Box>
            {isLoading ? loadingMessage : completedMessage}
            {(isLoading || !completedActionLabel)
                ? null
                : <Link onClick={onAction} component="button">{completedActionLabel}</Link>
            }
        </Stack>
    );
};

export default FormStateMessage;
