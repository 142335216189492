import { gql } from '../Types/Client';
import { useMutation } from '@apollo/client';

// Modify search profile mutation
export const REQUEST_PASSWORD_LINK = gql(/* GraphQL */ `
    mutation RequestPasswordLink($email: String!) {
        sendUserPasswordResetLink(email: $email)
    }
`);


/**
 * Use mutation to request password link
 */
const useRequestPasswordLink = () => {
    const [mutate, mutationState] = useMutation(
        REQUEST_PASSWORD_LINK, { variables: { email: '' } },
    );

    // Compose mutation state
    return {
        ...mutationState,
        request: (email: string) => (
            mutate({ variables: { email } })
                .then(() => true)
                .catch(() => true)
        ),
    };
};

export default useRequestPasswordLink;
