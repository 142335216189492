import 'react-image-gallery/styles/css/image-gallery.css';

import {
    Button,
    Theme,
} from '@mui/material';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import React, { MutableRefObject, useMemo, useRef, useState } from 'react';

import { GalleryIcon } from '../Icon';
import {
    makeStyles,
} from '@mui/styles';

/**
 * Properties for {@link FullScreenGalleryLauncher} component.
 */
export type FullScreenGalleryLauncherProps = {
    /**
     * Fotos to display
     */
    fotos: Array<ReactImageGalleryItem>,

    /**
     * Class name for the launcher button to apply styles
     */
    className?: string,
};

/**
 * Stylings used in {@link FullScreenGalleryLauncher} component.
 */
const useStyles = makeStyles(({ palette }: Theme) => ({
    hiddenContainer: {
        display: 'none',
    },

    imagesContainer: {
        '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus': {
            border: `4px solid ${palette.primary.main}`,
        },
        '& .image-gallery-thumbnail:hover': {
            border: `4px solid ${palette.primary.dark}`,
        },
        '& .image-gallery-icon:hover': {
            color: palette.primary.dark,
        },
        '& .image-gallery-thumbnail-image': {
            maxHeight: 60,
            objectFit: 'cover',
        },
    },
}));

/**
 * Display a launcher button to view all fotos of an advertisement in a full screen gallery view.
 */
const FullScreenGalleryLauncher = ({
    fotos,
    className,
}: FullScreenGalleryLauncherProps) => {
    const classes = useStyles();

    // State
    const ref = useRef<ImageGallery>() as MutableRefObject<ImageGallery>;
    const [isShowing, setIsShowing] = useState(false);
    const hasCurrent = useMemo(() => (ref.current !== null), [ref]);

    // We need to hide/unhide when we are in fullscreen mode
    const handleFullScreenExit = (opened: boolean) => {
        setIsShowing(opened);
    };

    return (
        <>
            {
                hasCurrent
                    ? (
                        <Button
                            className={className}
                            variant="contained"
                            onClick={() => ref.current?.fullScreen()}
                            startIcon={<GalleryIcon color="inherit" />}
                        >
                            Alle ansehen
                        </Button>
                    )
                    : null
            }
            <div className={isShowing ? '' : classes.hiddenContainer}>
                <ImageGallery
                    ref={ref}
                    items={fotos}
                    showPlayButton={false}
                    onScreenChange={handleFullScreenExit}
                    additionalClass={classes.imagesContainer}
                />
            </div>
        </>
    );
}

export default FullScreenGalleryLauncher;
