import { useMutation, useQuery } from '@apollo/client';

import { GET_ALL_BOOKMARKS } from './useUserBookmarks';
import { gql } from '../Types/Client';

// The query to get all results for bookmarks of ad
export const GET_BY_AD = gql(/* GraphQL */ `
    query GetBookmarkByAdvertisment($id: ID!) {
        bookmarks(where: { advertisment: { id: { equals: $id } } }) {
            id
            createdAt
            updatedAt
        }
    }
`);

// Create bookmark mutation
export const CREATE = gql(/* GraphQL */ `
    mutation CreateBookmark($data: BookmarkCreateInput!) {
        createBookmark(data: $data) {
            id
            advertisment {
                id
            }
            createdAt
            updatedAt
        }
    }
`);

// Delete bookmark mutation, requires id of original bookmark
export const DELETE = gql(/* GraphQL */ `
    mutation DeleteBookmark($id: ID!) {
        deleteBookmark(where: { id: $id }) {
            id
        }
    }
`);

/**
 * Use bookmark state from server.
 */
const useBookmark = (id: number) => {
    // Base query and unwrapping of data
    const { loading, data, error } = useQuery(
        GET_BY_AD,
        { variables: { id: `${id}` } },
    );
    const found = data?.bookmarks?.[0] ?? null;

    // Configure mutation
    const refetchConfig = { refetchQueries: [GET_BY_AD, 'GetBookmarkByAdvertisment', GET_ALL_BOOKMARKS] };
    const [createMutation, creationStatus] = useMutation(CREATE, refetchConfig);
    const [deleteMutation, deletionStatus] = useMutation(DELETE, refetchConfig);

    /**
     * Combine loading state from all mutations and query, and export data
     */
    return {
        loading: loading || creationStatus.loading || deletionStatus.loading,
        data: found,
        error,
        buttonText: found !== null ? 'Von Merkliste entfernen' :  'Auf die Merkliste',
        isBookmarked: found !== null,
        handleToggle: () => {
            if (found) {
                deleteMutation({ variables: { id: `${found.id}` } });
            } else {
                createMutation({ variables: { data: { advertisment: { connect: { id: `${id}` } } } } });
            }
        },
    };
};

export default useBookmark;
