import {
    Chip,
    List,
} from '@mui/material';

import { Conversation } from '../../Types/Client/graphql';
import ConversationListItem from './ConversationItem';
import ListLoader from '../Common/ListLoadingSkeleton';
import ListingEmpty from '../Common/ListingEmpty';
import QueryState from '../Common/QueryState';
import React from 'react';
import useConversations from '../../Hooks/useConversations';

/**
 * Properties for {@link ConversationList}
 */
export type ConversationListProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * Listener for select action
     */
    onSelect: (conversation: Conversation) => void,

    /**
     * Selected id
     */
    selected?: null | string,
};

/**
 * Logo with cube and word mark and subline
 */
const ConversationList = ({
    className,
    onSelect,
    selected,
}: ConversationListProps) => {
    const { conversations, ...state } = useConversations();
    
    return (
        <List className={className} disablePadding>
            <QueryState {...state} loadingIndicator={<ListLoader rows={8} />}>
                {conversations.map((item, i) => (
                    <ConversationListItem
                        key={`post_list_item_${i}`}
                        conversation={item}
                        onClick={() => onSelect(item)}
                        selected={item.id === selected}
                        secondaryAction={
                            (item.newMessageCount ?? 0) < 1
                                ? undefined
                                : <Chip size="small" color="success" variant="filled" label={`${item.newMessageCount}`} />
                        }
                    />
                ))}
                <ListingEmpty items={conversations} message="Noch keine Nachrichten" />
            </QueryState>
        </List>
    );
};

export default ConversationList;
