import {
    Alert,
    Container,
    IconButton,
    Stack,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import SidebarContainer, { Main, Sidebar } from '../Common/SidebarContainer';

import Chat from './Chat';
import { Conversation } from '../../Types/Client/graphql';
import ConversationList from './ConversationList';
import { MenuIcon } from '../Icon';
import PageContainer from '../Common/PageContainer';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Properties for {@link MessageCenter}
 */
export type MessageCenterProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,
};

/**
 * User area
 */
const MessageCenter = ({
    className,
}: MessageCenterProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const selectedId = searchParams.get('c');

    // Selection handler
    const selectConversation = ({ id }: Conversation) => {
        setSearchParams(prev => {
            return new URLSearchParams({ c: id });
        }, { replace: true });
    };
    const unselect = () => setSearchParams(new URLSearchParams(), { replace: true });

    return (
        <Container className={className}>
            <PageContainer back title="Ihr persönliches Postfach">
                <SidebarContainer>
                    {(!isDesktop && selectedId)
                        ? null
                        : (
                            <Sidebar>
                                <Stack spacing={1}>
                                    <Alert severity="info">
                                        <Typography variant="h5" color="inherit">
                                            Ihre Daten sind uns wichtig!
                                        </Typography>
                                        <Typography variant="body2">
                                            Um Ihre persönlichen Informationen zu schützen, kommunizieren
                                            Sie auf der Plattform pseudonymisiert.
                                        </Typography>
                                    </Alert>
                                    <ConversationList onSelect={selectConversation} selected={selectedId} />
                                </Stack>
                            </Sidebar>
                        )
                    }
                    {(!isDesktop && !selectedId)
                        ? null
                        : (
                            <Main>
                                {isDesktop ? null : (
                                    <IconButton
                                        sx={{ position: 'absolute' }}
                                        onClick={() => unselect()}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                <Chat id={selectedId || undefined} />
                            </Main>
                        )
                    }
                </SidebarContainer>
            </PageContainer>
        </Container>
    );
};

export default MessageCenter;
