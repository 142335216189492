import {
    InputAdornment,
    TextField,
    Theme,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import { AddIcon } from '../Icon';
import {
    makeStyles,
} from '@mui/styles';
import { useDropzone } from 'react-dropzone';

/**
 * Properties for {@link FileDrop} component
 */
export type FileDropProps = {
    /**
     * Change of file listener
     */
    onChange: (file?: File) => void,

    /**
     * File input label
     */
    label: string,
};

/**
 * Styles used in {@link FileDrop} component
 */
const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
    droparea: ({
        color: palette.grey[400],
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }),
    dropareaDragging: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.main,
        },
        backgroundColor: palette.grey[300],
    },
    dropareaActive: {},
    error: {
        color: palette.error.main,
        fontSize: 12,
    },
}));

/**
 * Component to drop a single file
 */
const FileDrop = ({ onChange, label }: FileDropProps) => {
    const [file, setFile] = useState<File>();
    const [error, setError] = useState<string>();
    const onDrop = useCallback((acceptedFiles: File[], rejections: Array<any>) => {
        setFile(acceptedFiles[0]);
        onChange(acceptedFiles[0]);

        if (acceptedFiles.length < 1 && rejections.length > 0) {
            setError('Nur Bildformate erlaubt (max 1Mb).');
        } else {
            setError(undefined);
        }
    }, [onChange]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
    } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
        },
        maxSize: 1000000,
    });
    const classes = useStyles();
    const styleSuffix = isDragActive ? 'Dragging' : (file !== undefined ? 'Active' : '');

    return (
        <div {...getRootProps()}>
            <TextField
                fullWidth
                label={label}
                placeholder={label}
                value={file?.name}
                InputLabelProps={{ shrink: (file !== undefined || error !== undefined) }}
                className={[classes.droparea, classes[`droparea${styleSuffix}`]].join(' ')}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" component="label">
                            {error && (<span className={classes.error}>{error}</span>)}
                            <AddIcon color="inherit" />
                        </InputAdornment>
                    ),
                    readOnly: true,
                    className: classes[`droparea${styleSuffix}`],
                }}
            />
            <input {...getInputProps()} />
        </div>
    );
};

export default FileDrop;
