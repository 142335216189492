import React, { PropsWithChildren } from 'react';
import { Stack, Theme } from '@mui/material';

import { ArrowBackIcon } from '../Icon';
import Link from '../Common/AppLink';
import { makeStyles } from '@mui/styles';

/**
 * Styles used in {@link BottomBackLink} component.
 */
const useStyles = makeStyles((theme: Theme) => ({
    moreInfo: {
        textAlign: 'left',
        textTransform: 'uppercase',
        textDecoration: 'none',
    },
    linkIcon: {
        marginRight: theme.spacing(1),
        width: 28,
    },
}));

/**
 * Properties for {@link BottomBackLink} component.
 */
export type BottomBackLinkProps = PropsWithChildren<{}>;

/**
 * Bottom back link at end of page, resolves to going back
 */
const BottomBackLink = ({ children }: BottomBackLinkProps) => {
    const classes = useStyles();
    return (
        <Stack justifyContent="space-between" direction="row" pt={3}>
            <Link
                variant="body1"
                color="primary"
                className={classes.moreInfo}
                // @ts-ignore
                back
            >
                <ArrowBackIcon color="inherit" className={classes.linkIcon} />
                Zurück
            </Link>
            {children}
        </Stack>
    );
}

export default BottomBackLink;
