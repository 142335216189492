import {
    Skeleton,
    Stack,
} from '@mui/material';

import React from 'react';

/**
 * Properties for {@link LoadingSekeleton}
 */
export type LoadingSekeletonProps = {};


/**
 * Loading sekelton for documents
 */
const LoadingSekeleton: React.FC<LoadingSekeletonProps> = () => (
    <Stack spacing={2} aria-label="Daten werden geladen">
        <div aria-hidden>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </div>
        <div aria-hidden>
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </div>
    </Stack>
);

export default LoadingSekeleton;
