import {
    Button,
    Card,
    CardContent,
    CardHeader,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { useNavigate } from 'react-router-dom';
import { useStyles } from './ContainerHeader';

/**
 * Properties for {@link PageContainer}
 */
export type PageContainerProps = {
    /**
     * Title to displayed on the back
     */
    title?: string,

    /**
     * Whether or not to display back link
     */
    back?: boolean,
} & PropsWithChildren<{}>;

/**
 * Display a page container, with title and page layout.
 */
const PageContainer = ({
    title,
    back,
    children,
}: PageContainerProps) => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <Card>
            <CardHeader
                title={
                    (!title || !back)
                        ? title
                        : (
                            <>
                                <Button className={classes.backButton} onClick={() => navigate(-1)}>
                                    zurück
                                </Button>
                                <div>{title}</div>
                            </>
                        )
                }
                className={classes.headerWithBack}
            />
            <CardContent>
                {children}
            </CardContent>
        </Card>
    );
};

export default PageContainer;
