import { UserUserRoleType } from '../Types/Client/graphql';
import { gql } from '../Types/Client';
import { useAuth } from './useAuth';
import { useQuery } from '@apollo/client';

// The query to get all filtered
export const GET_LIST = gql(/* GraphQL */ `
    query GetContentList($take: Int, $key: String) {
        contents(take: $take, where: { key: { equals: $key } }) {
            id,
            image { url }
            title
            content {
                document(hydrateRelationships: true)
            }
        }
    }
`);

export const GET_LIST_BY_ROLE = gql(/* GraphQL */ `
    query GetContentListByRole($take: Int, $key: String, $role: String) {
        contents(take: $take, where: { key: { equals: $key }, tags: { some: { name: { equals: $role } } } }) {
            id,
            image { url }
            title
            content {
                document(hydrateRelationships: true)
            }
        }
    }
`);

const roleTags = {
    [UserUserRoleType.Admin]: 'Admin',
    [UserUserRoleType.Seller]: 'Anbieter',
    [UserUserRoleType.Buyer]: 'Käufer',
};

/**
 * Use content list from server.
 */
const useContentList = (maxItems: number = 99) => {
    const { authentication } = useAuth();
    const role = roleTags[authentication?.userRole ?? UserUserRoleType.Buyer];
    const { loading, data, error } = useQuery(
        GET_LIST_BY_ROLE,
         { variables: { take: maxItems, key: null, role }, fetchPolicy: 'cache-and-network' },
    );
    return { loading, data, error };
};

export default useContentList;
