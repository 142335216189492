import { gql } from '../Types/Client';
import { useMutation } from '@apollo/client';

// The query to get all filtered
export const REQUEST_CALLBACK = gql(/* GraphQL */ `
    mutation RequestACallback($phone: String!, $message: String) {
        requestCallback(phone: $phone, message: $message)
    }
`);

export type CallbackParams = { phone: string, message?: string };

/**
 * Autheticate a user on the server
 */
const useCallbackRequst = () => {
    const [mutate, mutationState] = useMutation(
        REQUEST_CALLBACK, { variables: { phone: '' } },
    );

    const request = (credentials: CallbackParams): Promise<boolean> => {
        return mutate({ variables: credentials })
            .then((response) => {
                if (!response.data?.requestCallback) {
                    throw new Error('Sending fialed');
                }
                return true;
            })
    };
    return { ...mutationState, request };
};

export default useCallbackRequst;
