import {
    Stack,
    TextField,
} from '@mui/material';

import React from 'react';
import useAuth from '../../Hooks/useAuth';
import useForm from '../../Hooks/useValidatedForm';

/**
 * Data from inputs in component
 */
export type FormData = {
    phone: string,
    message?: string,
};

/**
 * Properties for {@link Form} component.
 */
export type FormProps = {
    /**
     * Handler when form is submitted
     */
    onSubmit: (values: FormData) => void,

    /**
     * Form id, use `<button form="[id-goes-here]" />` to submit form from outside form
     */
    id: string,
};

/**
 * Display form for requesting a callback.
 */
const Form = ({ onSubmit, id }: FormProps) => {
    // Form state management
    const { authentication } = useAuth();
    const { getFieldProps, handleSubmit } = useForm<FormData>({
        phone: { required: true },
    }, { defaultValues: { phone: authentication?.phoneNumber || '' } });

    return (
        <Stack
            component="form"
            id={id}
            onSubmit={handleSubmit((data) => onSubmit(data))}
            role="form"
            spacing={1}
            pt={2}
        >
            <TextField
                label="Telefonnummer"
                placeholder="Telefonnummer"
                helperText="Auf dieser Nummer nehmen wir mit Ihnen Kontakt auf"
                margin="dense"
                type="tel"
                {...getFieldProps('phone')}
            />
            <TextField
                label="Wünsche / Anmerkungen"
                placeholder="Anmerkungen"
                helperText="Bspw. bevorzugte Erreichbarkeit"
                margin="dense"
                rows={4}
                multiline
                {...getFieldProps('message')}
            />
        </Stack>
    );
};

export default Form;
