import {
    Stack,
    Typography,
} from '@mui/material';

import ErrorBrowserHelp from './ErrorBrowserHelp';
import React from 'react';

/**
 * Properties for {@link LoadingError} component
 */
export type LoadingErrorProps = {
    /**
     * Optional error information
     */
    error?: Error,
};

/**
 * Only display in dev mode
 */
const hideErrorMessage = process.env.NODE_ENV !== 'development';

/**
 * Loading error display component
 */
const LoadingError: React.FC<LoadingErrorProps> = ({
    error,
}): React.ReactElement<any, any> => {
    return (
        <Stack spacing={1} alignItems="center" p={2}>
            <Typography variant="h3" gutterBottom>
                Leider ist ein Fehler aufgetreten
            </Typography>
            {(!hideErrorMessage && error) && (
                <Typography variant="body2" color="textSecondary">
                    {error.message}
                </Typography>
            )}
            <Typography>
                Es tut uns leid, die Daten konnten nicht ordnungsgemäß geladen werden.
            </Typography>
            <ErrorBrowserHelp />
        </Stack>
    );
};

export default LoadingError;
