import {
    Grid,
    GridProps,
    Theme,
} from '@mui/material';
import React, { Children, PropsWithChildren } from 'react';

import Container from '../Common/Container';
import { makeStyles } from '@mui/styles';
import { ucFirst } from '../../Lib/Util';

/**
 * Styles used in {@link ColumnLayout} component.
 */
const useStyles = makeStyles((theme: Theme) => ({
    column: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        minWidth: 'auto',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            minHeight: '66vh',
        },
    },
    columnFirst: {
        [theme.breakpoints.up('md')]: {
            borderTopLeftRadius: theme.spacing(1),
            borderBottomLeftRadius: theme.spacing(1),
        },
    },
    columnLast: {
        [theme.breakpoints.up('md')]: {
            borderTopRightRadius: theme.spacing(1),
            borderBottomRightRadius: theme.spacing(1),
        },
    },
    colorPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    colorDefault: {
        backgroundColor: theme.palette.common.white,
    },

    pageContainer: {
        marginRight: theme.spacing(-1),
        marginLeft: theme.spacing(-1),
        width: 'auto',
        [theme.breakpoints.up('md')]: {
            paddingTop: 120,
            marginRight: 0,
            marginLeft: 0,
        },
    },
}));

/**
 * Styles used in {@link ColumnLayout} component.
 */
export const useColumnExtraStyles = makeStyles((theme: Theme) => ({
    logo: {
        alignSelf: 'flex-start',
        marginLeft: theme.spacing(-1),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(-3),
        },
    },
    moreInfo: {
        textAlign: 'left',
        textTransform: 'uppercase',
        textDecoration: 'none',
    },
    infoColumn: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    formColumn: {
        alignItems: 'flex-start',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flex: 1,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            borderTopRightRadius: theme.spacing(1),
            borderBottomRightRadius: theme.spacing(1),
            marginBottom: theme.spacing(0),
        },
    },
    fullWidth: {
        width: '100%',
    },
    pageTitle: {
        textTransform: 'uppercase',
    },
    help: {
        alignSelf: 'flex-end',
        fontSize: '0.75rem',
        paddingRight: theme.spacing(1),
        marginTop: theme.spacing(2),
        textAlign: 'right',
    },
    linkIcon: {
        marginRight: theme.spacing(1),
        width: 28,
    },
    spaceBottom: {
        marginBottom: theme.spacing(2),
    },
    lead: {
        marginBottom: theme.spacing(2),
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: 24,
        },
    },
    bottomContainer: {
        marginTop: theme.spacing(2),
    },
}));

/**
 * Properties for individual {@link Column}
 */
export type ColumnProps = {
    color?: 'primary' | 'default',
    className?: string,
};

/**
 * Child of column layout
 */
export const Column: React.FC<PropsWithChildren<ColumnProps>> = ({ children }) => (<>{children}</>);

/**
 * Properties for {@link ColumnLayout} component
 */
export type ColumnLayoutProps = {
    children: Array<React.ReactElement<typeof Column>>,
    columnAlign?: GridProps['alignItems'],
};

/**
 * Two column page layout
 */
const ColumnLayout = ({
    children,
    columnAlign = 'stretch',
}: ColumnLayoutProps) => {
    const classes = useStyles();
    return (
        <Container size="default" wrap={false}>
            <Grid
                container
                justifyContent="center"
                alignItems={columnAlign}
                className={classes.pageContainer}
            >
                {Children.map(children, (child, i) => (
                    <Grid
                        key={i}
                        item md
                        className={[
                            classes.column,
                            i === 0 ? classes.columnFirst : (i === children.length - 1 ? classes.columnLast : ''),
                            // @ts-ignore
                            classes[ucFirst(child.props.color || 'default', 'color')],
                            // @ts-ignore
                            child.props.className
                        ].join(' ')}
                    >
                        {child}
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default ColumnLayout;
