import {
    Box,
    Divider,
    List,
    ListItem,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';

import React from 'react';

// Simple loader item
const ListingLoaderItem = () => (
    <ListItem aria-label="Daten werden geladen">
        <Stack direction="row" alignItems="flex-start" width="100%" spacing={2} aria-hidden>
            <Box flex={1}>
                <Skeleton height={220} variant="rounded" />
            </Box>
            <Stack dir="vertical" spacing={1} flex={1}>
                <Typography variant="h4">
                    <Skeleton />
                </Typography>
                <Typography variant="subtitle1">
                    <Skeleton />
                </Typography>
                <Skeleton />
                <Skeleton />
            </Stack>
        </Stack>
    </ListItem>
);

/**
 * Listing loader display
 */
const ListingLoader = () => {
    return (
        <List>
            <Divider component="li" />
            <ListingLoaderItem />
            <Divider component="li" aria-hidden />
            <ListingLoaderItem aria-hidden />
            <Divider component="li" aria-hidden />
            <ListingLoaderItem aria-hidden />
            <Divider component="li" aria-hidden />
            <ListingLoaderItem aria-hidden />
            <Divider component="li" aria-hidden />
            <ListingLoaderItem aria-hidden />
        </List>
    );
}

export default ListingLoader;
