import {
    Navigate,
    Route,
    Routes as Switch,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import React, { useEffect } from 'react';

import About from './Components/Public/About';
import Advertisement from './Components/Pages/Advertisement';
import Advertisements from './Components/Pages/Advertisements';
import Agents from './Components/Pages/Agents';
import Contact from './Components/Public/Contact';
import Content from './Components/Pages/Content';
import CreateConversation from './Components/User/CreateConversation';
import Creation from './Components/Pages/Creation';
import Dashboard from './Components/Pages/Dashboard';
import Edit from './Components/Pages/Edit';
import Imprint from './Components/Public/Imprint';
import LandingPage from './Components/Public/LandingPage';
import LegalTerms from './Components/Public/LegalTerms';
import MessageCenter from './Components/User/MessageCenter';
import NotFound from './Components/Public/NotFound';
import OAuthErrorCodes from './Lang/OAuthErrorCodes';
import PasswordReset from './Components/Public/PasswordReset';
import Registration from './Components/Pages/Registration';
import SignIn from './Components/Public/SignIn';
import UserConfirm from './Components/Pages/UserConfirm';
import UserContent from './Components/Pages/User';
import { UserUserStateType } from  './Types/Client/graphql';
import useAuth from './Hooks/useAuth';
import useNotifications from './Hooks/useNotifications';

/**
 * Provide readable title for all pages
 */
const pageTitleByPath = {
    '/kontakt': 'Kontaktieren Sie uns',
    '/impressum': 'Impressum',
    '/agb': 'Unsere Nutzungsbedingungen',
    '/register/apotheker': 'Registrierung für Pharmazeuten',
    '/home': 'Startseite - Benutzerdashboard',
    '/erstellen': 'Ein neues Inserat erstellen',
    '/bearbeiten': 'Ihr Inserat bearbeiten',
    '/inserate': 'Ihre Suche',
    '/inserat/': 'Inserat anzeigen',
    '/interessenten': 'Interessenten Suche',
    '/benutzer': 'Benutzerbereich',
    '/nachrichten': 'Postfach',
    '/passwort': 'Password vergessen',
    '/content': 'Inhalt anzeigen',
    '/info': 'Informationen zur ApothekenBörse',
    '/hilfe-login': 'Hilfe beim Login',
    '/login/apotheker': 'Login für Pharmazeuten',
    '/login': 'Login für Dritte',
    '/konto': 'Ihr Bentzerkonto',
    '/': 'Startseite - Wilkommen bei der ApothekenBörse',
};

function Routes() {
    const navigate = useNavigate();

    // Naviate to use dashboard on sign in
    const { isAuthenticated, authentication } = useAuth();
    const [searchParams] = useSearchParams();
    const { addError } = useNotifications();
    React.useEffect(() => {
        const errorCode = searchParams.get('loginState')
        if (errorCode) {
            if (errorCode === 'success') {
                navigate('/home');
            } else {
                addError(OAuthErrorCodes[errorCode] || 'Fehler beim login');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    // Scrolling to top for content pages
    const { pathname } = useLocation();
    useEffect(() => {
        document.documentElement.scrollTo({ top: 0, left: 0 });
        const pageTitle = Object.entries(pageTitleByPath).find(([path, title]) => (
            pathname.startsWith(path) ? true : false
        ))?.[1] ?? '';
        document.title = `ApothekenBörse - ${pageTitle}`;
    }, [pathname]);

    // Custom function to wrap restricted element, also with incomplete profile
    const restrictedElement = (restriced: React.ReactNode) => (
        isAuthenticated ? (
            authentication?.userState !== UserUserStateType.Active
                ? <Navigate to="/konto" replace /> : restriced
        ) : <Navigate to="/" replace />
    );

    return (
        <Switch>
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/impressum" element={<Imprint />} />
            <Route path="/agb" element={<LegalTerms />} />
            <Route path="/register/apotheker" element={<Registration type="pharmacists" />} />
            <Route path="/home" element={restrictedElement(<Dashboard />)} />
            <Route path="/erstellen/:id?" element={restrictedElement(<Creation />)} />
            <Route path="/bearbeiten/:id" element={restrictedElement(<Edit />)} />
            <Route path="/inserate" element={restrictedElement(<Advertisements />)} />
            <Route path="/inserat/:id" element={restrictedElement(<Advertisement />)} />
            <Route path="/interessenten" element={restrictedElement(<Agents />)} />
            <Route path="/benutzer" element={restrictedElement(<UserContent />)} />
            <Route path="/nachrichten" element={restrictedElement(<MessageCenter />)} />
            <Route path="/nachricht" element={restrictedElement(<CreateConversation />)} />
            <Route path="/register" element={<Registration />} />
            <Route path="/passwort" element={<PasswordReset />} />
            <Route path="/content/:id/:title?" element={<Content />} />
            <Route path="/info" element={<About />} />
            <Route path="/hilfe-login" element={<About loginHelp />} />
            <Route path="/login/apotheker" element={<SignIn type="pharmacists" />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/konto" element={<UserConfirm />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<NotFound />} />
        </Switch>
    );
}

export default Routes;
