import React from 'react';
import { useAppBarStyles as useStyles } from './Styles';

/**
 * Spacer component to account for {@link Toolbar} when used as fixed layout component
 */
const AppBarSpacer = () => {
    const classes = useStyles();
    return (
        <div className={classes.appBarSpacer} />
    );
};

export default AppBarSpacer;
