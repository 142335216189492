"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Options config
 */
const optionsConfig = [
    { label: 'Anteil mit Konzession', value: 'withLicense' },
    { label: 'Anteil ohne Konzession', value: 'withoutLicense' },
    { label: 'Pacht', value: 'lease' }
];
/**
 * Export the config
 */
exports.default = optionsConfig;
