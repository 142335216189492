import { Button, Stack, Typography } from '@mui/material';

import LANG from '../../Lang/StaticTexts';
import React from 'react';

/**
 * Properties for {@link NoResultsMessage}
 */
export type NoResultsMessageProps = {
    /**
     * If passed message is only shown if array is empty
     */
    items?: Array<unknown> | null | undefined;

    /**
     * Message string or component to display
     */
    message?: string | JSX.Element,

    /**
     * Detailed message
     */
    detailMessage?: string | JSX.Element,

    /**
     * Label for action button
     */
    actionLabel?: string,

    /**
     * Handler when action button is clicked
     */
    onAction?: () => void,
};

/**
 * Empty state display. Displays an empty indicator if items passed is not set or empty. Otherwise
 * displays nothing.
 */
const NoResultsMessage: React.FC<NoResultsMessageProps> = ({
    items,
    message = LANG.GenericNoResults,
    detailMessage,
    actionLabel,
    onAction,
}) => {
    if (items && items.length > 0) {
        return null;
    }

    return (
        <Stack spacing={2} alignItems="center" width="100%" sx={{ '& > .MuiTypography-root': { maxWidth: '70%' } }}>
            {
                typeof message === 'string'
                    ? (<Typography variant="h4" color="text.primary" flex={1} align="center">{message}</Typography>)
                    : (message)
            }
            {
                typeof detailMessage === 'string'
                    ? (<Typography variant="inherit" color="text.secondary" flex={1} align="center">{detailMessage}</Typography>)
                    : (message)
            }
            {
                onAction && actionLabel
                ? (<Button variant="contained" onClick={onAction}>{actionLabel}</Button>)
                : (undefined)
            }
        </Stack>
    );
}

export default NoResultsMessage;
