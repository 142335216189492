import {
    Divider,
    List,
    ListItem,
} from '@mui/material';
import { ProfileParamsType, searchParameterToFilters, serializeUrl } from '../../Lib/Filter';

import AgentItem from './UserAgentItem';
import NoResultsMessage from '../Common/NoResultsMessage';
import QueryState from '../Common/QueryState';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSearchProfiles from '../../Hooks/useSearchProfiles';

/**
 * Properties for {@link UerAgentsListing} component.
 */
export type UerAgentsListingProps = {};

/**
 * Show a UerAgentsListing of user search agents
 */
// eslint-disable-next-line no-empty-pattern
const UerAgentsListing = ({}: UerAgentsListingProps) => {
    const { data, ...queryState } = useSearchProfiles();
    const navigate = useNavigate();

    const handleShow = (params: ProfileParamsType) => {
        const serialized = serializeUrl(searchParameterToFilters(params));
        navigate(`/inserate?${serialized}`);
    };

    return (
        <List>
            {data?.searchProfiles?.map((result) => (
                <>
                    <ListItem style={{ paddingTop: 20, paddingBottom: 20 }}>
                        <AgentItem
                            profile={result}
                            onShowResults={() => handleShow(result.searchParameters)}
                        />
                    </ListItem>
                    <Divider component="li" />
                </>
            ))}
            <QueryState {...queryState} />
            <NoResultsMessage
                items={data?.searchProfiles}
                detailMessage="Sie haben noch keine Suchagenten eingerichtet. Starten Sie jetzt Ihre Suche und aktivieren Sie einen Suchagenten, wenn Sie über neue passende Inserate benachtichtigt werden wollen."
                actionLabel="Suche starten"
                onAction={() => navigate('/inserate')}
            />
        </List>
    );
};

export default UerAgentsListing;
