/**
 * Validate modulo11.
 *
 * Based on {@link https://github.com/smh/checkdigit/blob/master/lib/mod11.js}
 */
export const isValid = (
    value: string
) => {
    const checkDigitIndex = value.length - 1;
    const checkDigit = calcCheckDigit(value.substring(0, checkDigitIndex));
    return checkDigit !== 'X' && value.substring(checkDigitIndex) === checkDigit;
};

// Get a check digit
export const calcCheckDigit = (input: string) => {
    let sum = 0;
    input.split('').reverse().forEach((value, index) => {
        sum += parseInt(value, 10) * (index % 6 + 2);
    });
    const sumMod11 = sum % 11;
    if (sumMod11 === 0) {
        return '0';
    }  else if (sumMod11 === 1) {
        return 'X';
    } else {
        return (11 - sumMod11) + '';
    }
};

/**
 * Validate a GHL number with a possible leading P
 */
export const isValidGHKPNum = (input: string) => {
    return isValid(
        input.toUpperCase().startsWith('P') ? input.substring(1, input.length) : input
    );
}
