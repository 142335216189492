import {
    Divider,
    List,
    ListItemButton,
} from '@mui/material';
import React, { Fragment } from 'react';

import ListingItem from './ListingItem';

/**
 * Properties for {@link Listing} component.
 */
export type ListingProps = {
    results: Array<any>,

    /**
     * When a listing is selected
     */
    onSelect: (id: string) => void,
};

/**
 * ....
 */
const Listing = ({
    results,
    onSelect,
}: ListingProps) => {

    return (
        <List>
            {results.map((item) => (
                <Fragment key={item.id}>
                    <Divider component="li" />
                    <ListItemButton onClick={() => onSelect(item.id)} disableGutters sx={{ paddingY: 1.5 }}>
                        <ListingItem item={item} />
                    </ListItemButton>
                </Fragment>
            ))}
        </List>
    );
}

export default Listing;
