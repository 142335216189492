import {
    ControlledTreeEnvironment,
    Tree
} from 'react-complex-tree';
import React, { useEffect } from 'react';
import useLocationsTree, { getSelectionFromIndices } from './LocationsData';

import FormDialog from '../Common/FormDialog';
import ListContainer from './ListContainer';
import ListItem from './ListItem';
import LoadingIndicator from './LoadingIndicator';
import SelectedIndicator from './SelectedIndicator';
import { TreeItem } from '../../Hooks/useLocationsTree';
import useLocationSelection from './useLocationSelection';

/**
 * Properties for {@link Modal} component
 */
export type ModalProps = {
    /**
     * Dialog opened state control
     */
    open?: boolean,

    /**
     * Whether multiple selection is enabled or not
     */
    multiple?: boolean,

    /**
     * Callback fired when user confirms selection
     */
    onSelect: (selectedValues: Array<TreeItem>) => void,

    /**
     * Callback fired when dialog close requested
     */
    onClose: () => void,

    /**
     * Live values
     */
    values?: Array<TreeItem>,
};

/**
 * Modal location selection component
 */
const Modal = ({
    open = true,
    multiple = false,
    onSelect,
    onClose,
    values,
}: ModalProps) => {
    const { data, loading } = useLocationsTree();
    const {
        setFocusedItem,
        focusedItem,
        expandedItems,
        selectedItems,
        handleSelection,
        updateValues,
    } = useLocationSelection({ data, multiple });

    // Handle value updates live
    useEffect(() => {
        const treeValues = (values ?? []).flatMap(({ type, id, children }) => (
            [`${type}-${id}`, ...(children?.map(({ type, id }) => (`${type}-${id}`)) ?? [])]
        ));
        updateValues(treeValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    /**
     * Handle selection confirmation via button
     */
    const handleButtonClick = () => {
        onSelect(getSelectionFromIndices(selectedItems, data));
    };

    return (
        <FormDialog
            open={open}
            onClose={onClose}
            title="Standort Auswahl"
            action="Übernehmen"
            onAction={handleButtonClick}
            dialogContentProps={{ style: { height: '50vh' } }}
        >
            <>
                {loading ? <LoadingIndicator /> : null}
                <ControlledTreeEnvironment
                    items={data}
                    viewState={{
                        'tree-1': {
                            focusedItem,
                            expandedItems,
                            selectedItems,
                        },
                    }}
                    onFocusItem={item => setFocusedItem(item.index)}
                    onExpandItem={() => {}}
                    onCollapseItem={() => {}}
                    onSelectItems={handleSelection}
                    getItemTitle={item => item.data}
                    renderItem={ListItem}
                    renderItemsContainer={ListContainer}
                    renderItemArrow={SelectedIndicator}
                >
                    <Tree treeId="tree-1" rootItem="root" treeLabel="Regionsauswahl" />
                </ControlledTreeEnvironment>
            </>
        </FormDialog>
    );
};

export default Modal;
