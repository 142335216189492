import { ListItem, ListItemProps, Typography } from '@mui/material';

import React from 'react';

/**
 * Properties for {@link ListingEmpty}
 */
export type ListingEmptyProps = {
    /**
     * Items passed optionally
     */
    items: Array<unknown> | null | undefined;

    /**
     * Message string or component to display
     */
    message?: string | JSX.Element,
} & ListItemProps;

/**
 * Empty state display. Displays an empty indicator if items passed is not set or empty. Otherwise
 * displays nothing.
 */
const ListingEmpty: React.FC<ListingEmptyProps> = ({
    items,
    message = 'Keine Elemente',
    ...listItemProps
}) => {
    if (items && items.length > 0) {
        return null;
    }

    return (
        <ListItem {...listItemProps}>
            {
                typeof message === 'string'
                    ? (<Typography variant="inherit" color="text.secondary" flex={1} align="center">{message}</Typography>)
                    : (message)
            }
        </ListItem>
    );
}

export default ListingEmpty;
