import {
    Alert,
    Button,
    Checkbox,
    Grid,
    Link,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import { rulesDefault, rulesPharmacists } from '../../Lib/RegistrationValidation';

import FileDrop from '../Common/FileDrop';
import FormErrorDisplay from '../Common/FormErrorDisplay';
import PasswordField from './PasswordField';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import React from 'react';
import StateSelectField from '../Common/StateSelectField';
import { makeStyles } from '@mui/styles';
import useValidatedForm from '../../Hooks/useValidatedForm';

/**
 * Data of form in {@link RegistrationForm}
 */
export type RegistrationFormData = {
    salutation: string,
    titlePrefix: string,
    titleSuffix: string,
    email: string,
    phoneNumber?: string,
    stateId?: string,
    firstName: string,
    lastName: string,
    password: string,
    name: string,
    company?: string,
    jobRole?: string,
    employeeId?: string,
    document?: File,
    legalTerms?: string,
};

/**
 * Properties for {@link RegistrationForm} component
 */
export type RegistrationFormProps = {
    /**
     * Callback when registration form is submitted
     */
    onSubmit: (data: RegistrationFormData) => void,

    /**
     * Form type
     */
    type?: 'default' | 'pharmacists',
};

/**
 * Styles used in {@link RegistrationForm} component
 */
const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
    formGroupHeading: {
        marginTop: spacing(1),
    },
    terms: {
        backgroundColor: palette.grey[100],
        marginTop: spacing(2),
        marginBottom: spacing(2),
        padding: spacing(2),
    },
    checkbox: {
        marginTop: spacing(-0.5),
    },
    button: {
        alignSelf: 'flex-start',
    },
    formHelperProminent: {
        color: palette.info.main,
        fontWeight: '700',
        fontSize: 14,
    },
}));

const TEXT_DOCUMENT = {
    default: 'Wir benötigen einen amtlichen Lichtbildausweis, um Ihre Identität zu bestätigen. Ihre Daten werden streng vertraulich behandelt und nach Überprüfung gelöscht. Nur Bildformate erlaubt.',
    pharmacists: 'Wir benötigen einen amtlichen Lichtbildausweis oder einen Mitgliedsausweis, um Ihre Identität zu bestätigen. Ihre Daten werden streng vertraulich behandelt und nach Überprüfung gelöscht. Nur Bildformate erlaubt.',
};

/**
 * A RegistrationForm to display a chat RegistrationForm in
 */
const RegistrationForm = ({
    onSubmit,
    type = 'default',
}: RegistrationFormProps) => {
    // Stylings
    const classes = useStyles();

    // Form state management
    const {
        getFieldProps, getFieldErrorProps, setValue, handleSubmit, watch
    } = useValidatedForm<RegistrationFormData>(
        type === 'pharmacists' ? rulesPharmacists : rulesDefault
    );

    // Document and passsword handler
    const password = watch('password');
    const setDocumentValue = (value?: File) => setValue('document', value);

    // We need to call this for validation to work, because the field is virtual (not present in
    // DOM) react-hook-form cannot handle it otherwise
    getFieldProps('document');

    return (
        <Stack
            component="form"
            onSubmit={handleSubmit((data) => onSubmit({
                ...data,
                name:  type === 'pharmacists' ? data.name : [data.firstName, data.lastName].join('_'),
                employeeId: data.employeeId && (data.employeeId.startsWith('P') ? data.employeeId : `P${data.employeeId}`)
            }))}
            role="form"
        >
            <Stack spacing={2}>
                <Typography variant="h2">
                    Neues Konto beantragen
                </Typography>
                <Typography variant="body1">
                    Um die Apothekenbörse nutzen zu können, benötigen Sie eine Registrierung. Sobald Sie
                    registriert sind, können Sie landesweit und ohne Hürde die Plattform nutzen.
                </Typography>
                <Typography variant="body1">
                    HINWEIS: Nicht-Mitglieder des Österreichischen Apothekerverbands oder der
                    pharmazeutischen Gehaltskasse werden auf der Plattform mit ihrem Klarnamen angezeigt.
                </Typography>
            </Stack>
            <Grid container columnSpacing={2} rowSpacing={0}>
                <Grid item md={12} xs={12}>
                    <Typography variant="h6" className={classes.formGroupHeading}>
                        Angaben zur Person
                    </Typography>
                </Grid>
                <Grid item md={2} xs={6}>
                   <TextField
                       label="Anrede"
                       select
                       fullWidth
                       {...getFieldProps('salutation')}
                   >
                       <MenuItem value="Herr">Herr</MenuItem>
                       <MenuItem value="Frau">Frau</MenuItem>
                       <MenuItem value=" ">Divers</MenuItem>
                   </TextField>
                </Grid>
                <Grid item md={2} xs={6}>
                    <TextField
                        label="Titel vorgestellt"
                        placeholder="Mag."
                        fullWidth
                        {...getFieldProps('titlePrefix')}
                    />
                </Grid>
                <Grid item md={3} xs={12}>
                    <TextField
                        label="Vorname"
                        placeholder="Vorname"
                        fullWidth
                        {...getFieldProps('firstName')}
                    />
                </Grid>
                <Grid item md={3} xs={8}>
                    <TextField
                        label="Nachname"
                        placeholder="Nachname"
                        fullWidth
                        {...getFieldProps('lastName')}
                    />
                </Grid>
                <Grid item md={2} xs={4}>
                    <TextField
                        label="Titel nachgestellt"
                        placeholder="Bsc."
                        fullWidth
                        {...getFieldProps('titleSuffix')}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <FileDrop
                        onChange={setDocumentValue}
                        label={type === 'pharmacists' ? 'Mitgliedsausweis*' : 'Identifikationsdokument*'}
                    />
                    <FormErrorDisplay {...getFieldErrorProps('document')} />
                </Grid>
                <Grid item md={8} xs={12} pt={1.5}>
                    <Typography variant="body2">{TEXT_DOCUMENT[type]}</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography variant="h6" className={classes.formGroupHeading}>
                        Kontakt
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        label="Email-Adresse"
                        type="email"
                        fullWidth
                        {...getFieldProps('email')}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        label="Telefon"
                        type="tel"
                        fullWidth
                        {...getFieldProps('phoneNumber')}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography variant="h6" className={classes.formGroupHeading}>
                        {type === 'pharmacists' ? 'Pharmazeutische Gehaltskasse' : 'Informationen'}
                    </Typography>
                </Grid>
                {type === 'pharmacists'
                    ? (
                        <Grid item md={4} xs={12}>
                            <TextField
                                label="Personalnummer (P...)"
                                fullWidth
                                {...getFieldProps('employeeId')}
                            />
                        </Grid>
                    )
                    : (
                        <>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    label="Unternehmen"
                                    fullWidth
                                    {...getFieldProps('company')}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                               <TextField
                                   label="Funktion"
                                   select
                                   fullWidth
                                   {...getFieldProps('jobRole')}
                               >
                                   <MenuItem value="Stb / WT">Stb / WT</MenuItem>
                                   <MenuItem value="Notar">Notar</MenuItem>
                                   <MenuItem value="Großhandel">Großhandel</MenuItem>
                                   <MenuItem value="sonstiges">sonstiges</MenuItem>
                               </TextField>
                           </Grid>
                           <Grid item md={4} xs={12}>
                              <StateSelectField
                                  label="Bundesland"
                                  fullWidth
                                  {...getFieldProps('stateId')}
                              />
                          </Grid>
                        </>
                    )
                }
                <Grid item md={12} xs={12}>
                    <Typography variant="h6" className={classes.formGroupHeading}>
                        Benutzerkonto & Passwort
                    </Typography>
                </Grid>
                {type === 'pharmacists'
                    ? (
                        <Grid item md={4} xs={12}>
                            <TextField
                                label="Anzeigename / Pseudonym"
                                fullWidth
                                helperText="Der Anzeigename ist für andere Benutzer sichtbar."
                                FormHelperTextProps={{ className: classes.formHelperProminent }}
                                {...getFieldProps('name')}
                            />
                        </Grid>
                    )
                    : (
                        <Grid item md={12} xs={12} pb={2}>
                            <Alert severity="info">
                                Ihr Anzeigename wird aus Ihrem Vor- und Nachnamen zusammengesetzt. Sie
                                treten auf dieser Plattform mit Ihrem Klarnamen auf.
                            </Alert>
                        </Grid>
                    )
                }
                <Grid item md={12} xs={12}>
                    <Typography variant="body2" color="text.secondary">
                        Das Passwort muss aus mindestens 8 Zeichen, Groß- sowie Kleinbuchstaben und
                        Zahlen bestehen
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <PasswordField
                        label="Passwort"
                        fullWidth
                        {...getFieldProps('password')}
                    />
                </Grid>
                <Grid item md={12} xs={12}>
                    <PasswordStrengthIndicator value={password} />
                </Grid>
            </Grid>

            <Paper className={classes.terms}>
                <Stack direction="row" alignItems="flext-start">
                    <Checkbox className={classes.checkbox} {...getFieldProps('legalTerms')} />
                    <Typography>
                        Ich habe die AGB (<Link target="_blank" href={`${process.env.PUBLIC_URL}/agb`}>hier geht es zu den AGB</Link>)
                        gelesen und akzeptiere diese. Ich nehme zur Kenntnis, dass ich Emails
                        erhalten werde, die mit meinem Account zusammenhängen.
                    </Typography>
                </Stack>
                <FormErrorDisplay {...getFieldErrorProps('legalTerms')} />
            </Paper>
            <Button size="large" variant="contained" type="submit" className={classes.button}>
                Konto beantragen
            </Button>
        </Stack>
    );
};

export default RegistrationForm;
