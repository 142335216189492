import {
    Stack,
    Typography,
} from '@mui/material';

import React from 'react';

/**
 * Properties for {@link DescriptionText} component.
 */
export type DescriptionTextProps = {
    /**
     * Text to display
     */
    text?: string | null,
};

/**
 * Show an ads description text with all markup and stylings rendered correctly.
 */
const DescriptionText = ({ text }: DescriptionTextProps) => {
    return (
        <Stack spacing={1}>
            {text?.split('\n\n').map((paragrah, i) => (
                <Typography variant="subtitle2" key={`description_p_${i}`}>
                    {paragrah.split('\n').map((line, l) => (
                        <Typography variant="inherit" key={`line_p_${i}_${l}`}>
                            {line}
                        </Typography>
                    ))}
                </Typography>
            ))}
        </Stack>
    );
}

export default DescriptionText;
