import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Stack,
    Typography,
} from '@mui/material';
import DashboardForm, { FilterData } from '../User/DashboardForm';
import React, { useState } from 'react';

import Container from '../Common/Container';
import InfoReel from '../Content/InfoReel';
import LANG from '../../Lang/StaticTexts';
import Link from '../Common/AppLink';
import RequestButton from '../CallMeBack/RequestButton';
import ResponsiveStack from '../Common/ResponsiveStack';
import { serializeAll } from '../../Lib/Filter';
import { useIsSeller } from '../../Hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate();
    const [displayFilter, setDisplayFilter] = useState<FilterData>({ action: 'buy', type: 'withLicense' });
    const isSeller = useIsSeller();

    const handleShow = () => {
        const { action, ...submittedFilter } = displayFilter;
        const actionUrl = action !== 'buy' ? 'interessenten' : 'inserate';
        const params = new URLSearchParams(Object.entries(serializeAll(submittedFilter))); //.toString();
        navigate(`/${actionUrl}?${params}`);
    };

    const headerText = (isSeller ? LANG.dashboardTitleSeller : LANG.dashboardTitle).split('oder');
    const headerTitle = isSeller
        ? (<>{headerText[0]} oder <Link to="/erstellen">{headerText[1]}</Link></>)
        : headerText[0];

    return (
        <Container size="default" wrap={false}>
            <Stack spacing={4}>
                <Card>
                    <CardHeader
                        titleTypographyProps={{ variant: 'h3' }}
                        title={headerTitle}
                    />
                    <CardContent>
                        <Stack alignItems="center" spacing={5}>
                            <Stack width="100%">
                                <DashboardForm onChange={setDisplayFilter} />
                            </Stack>
                            <Button
                                onClick={handleShow}
                                variant="contained"
                                size="large"
                            >
                                Ergebnisse anzeigen
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader
                        titleTypographyProps={{ variant: 'h3' }}
                        title="Sie benötigen Unterstützung beim Kauf oder Verkauf?"
                    />
                    <CardContent>
                        <ResponsiveStack desktopProps={{ alignItems: 'center', spacing: 5 }} spacing={1}>
                            <Typography>
                                Eine Apothekennachfolge bringt viele Fragen mit sich. Wir vom
                                Österreichischen Apothekerverband sind für Sie da! Wir beraten Sie
                                gerne persönlich zu allen Themen im Rahmen Ihres Betriebsübergangs.
                            </Typography>
                            <RequestButton />
                        </ResponsiveStack>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader
                        titleTypographyProps={{ variant: 'h3' }}
                        title="Informationen"
                    />
                    <CardContent>
                        <InfoReel
                            onShow={(info) => navigate(`/content/${info.id}/${encodeURIComponent(info.title || '')}`)}
                        />
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    );
}

export default Dashboard;
