import { Grid } from '@mui/material';
import InfoItem from './InfoItem';
import QueryState from '../Common/QueryState';
import React from 'react';
import useContentList from '../../Hooks/useContentList';

/**
 * Properties for {@link InfoReel} component
 */
export type InfoReelProps = {
    onShow: (item: { id: string, title?: string | null }) => void,
};

/**
 * Info reel
 */
const InfoReel: React.FC<InfoReelProps> = ({ onShow }: InfoReelProps) => {
    const { data, ...state } = useContentList();
    return (
        <QueryState {...state}>
            <Grid container spacing={4}>
                {data?.contents?.map((info, indexKey) => (
                    <InfoItem
                        item={info}
                        key={`info_item_${indexKey}`}
                        onShow={() => onShow(info)}
                    />
                ))}
            </Grid>
        </QueryState>
    );
};

export default InfoReel;
