import {
    Box,
    Button,
    Theme,
    Typography,
    TypographyProps,
} from '@mui/material';
import SidebarContainer, { Main, Sidebar } from '../Common/SidebarContainer';

import React from 'react';
import {
    makeStyles,
} from '@mui/styles';
import { useNavigate } from 'react-router-dom';

/**
 * Stylings used in {@link AllFotosButton} component.
 */
export const useStyles = makeStyles(({ spacing }: Theme) => ({
    backButton: {
        padding: 0,
        paddingLeft: 0,
        fontWeight: 'normal',
        minWidth: 'auto',
        marginBottom: spacing(0.75),
    },
    backButtonColor: {
        color: 'inherit',
    },
    pageTitle: {
        textTransform: 'uppercase',
    },
    specialHeader: {
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        borderTopLeftRadius: spacing(1),
        borderTopRightRadius: spacing(1),
    },
    headerWithBack: {
        paddingTop: spacing(1),
    },
}));

/**
 * Properties for {@link ContainerHeader}
 */
export type ContainerHeaderProps = {
    /**
     * A classname to pass to the root element
     */
    className?: string,

    /**
     * Primary title
     */
    title: string | React.ReactNode,

    /**
     * Optional subtitle
     */
    subtitle?: string | React.ReactNode,

    /**
     * Properties passed to title typography
     */
    titleTypographyProps?: Partial<TypographyProps>,

    /**
     * Whether or not to have sidebar first
     */
    sidebarFirst?: boolean,
};

/**
 * Complex container header with back button.
 */
const ContainerHeader = ({
    className = 'container-header',
    title,
    subtitle,
    titleTypographyProps = {},
    sidebarFirst = false,
}: ContainerHeaderProps) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [FirstCol, SecondCol] = sidebarFirst ? [Sidebar, Main] : [Main, Sidebar];

    return (
        <Box className={[classes.specialHeader, className].join(' ')}>
            <Button
                className={[classes.backButton, classes.backButtonColor].join(' ')}
                onClick={() => navigate(-1)}
            >
                zurück
            </Button>
            <SidebarContainer alignItems="flex-end">
                <FirstCol>
                    <Typography
                        variant="h2"
                        color="inherit"
                        className={classes.pageTitle}
                        {...titleTypographyProps}
                    >
                        {title}
                    </Typography>
                </FirstCol>
                <SecondCol>
                    {
                        (!subtitle || React.isValidElement(subtitle))
                            ? subtitle
                            : (<Typography variant="h2" color="inherit">{subtitle}</Typography>)
                    }
                </SecondCol>
            </SidebarContainer>
        </Box>
    );
};

export default ContainerHeader;
