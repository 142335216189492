import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    InMemoryCache,
} from '@apollo/client';
import { IntrospectionQuery, buildClientSchema } from 'graphql';
import React, { PropsWithChildren } from 'react';

import { DateTimeResolver } from 'graphql-scalars';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import introspectionResult from '../Types/Client/graphql.schema.json';
import { setContext } from '@apollo/client/link/context';
import { withScalars } from 'apollo-link-scalars';

const schema = buildClientSchema(
    (introspectionResult as unknown) as IntrospectionQuery,
);

/**
 * We need to setup a link
 */
const httpLink = createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND_URL}/api/graphql`,
    credentials: 'include'
});


/**
 * Authentication provider header injection
 */
const authTokenLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            credentials: 'include',
            'Apollo-Require-Preflight': 'true',
        },
    };
});

/**
 * Combined with scalars
 */
const typesMap = {
    DateTime: DateTimeResolver,
};

const link = ApolloLink.from([
    (withScalars({ schema, typesMap }) as unknown) as ApolloLink,
    authTokenLink.concat(httpLink),
]);

/**
 * Setup the client to connect to server
 */
const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});

/**
 * The provider
 */
const ApiClientProvider = ({ children }: PropsWithChildren<{}>) => {
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    );
}

export default ApiClientProvider;
