import AppLink from '../Common/AppLink';
import { ArrowBackIcon } from '../Icon';
import Container from '../Common/Container';
import ContentDisplay from '../Content/Display';
import React from 'react';
import {
    Typography,
} from '@mui/material';
import displayDocument from '../../Lang/DocumentImprint';
import { useColumnExtraStyles } from './ColumnLayout';

const Imprint = () => {
    const classes = useColumnExtraStyles();
    return (
        <Container pageContainer>
            <Typography variant="h1">IMPRESSUM</Typography>
            <ContentDisplay document={displayDocument} />
            <div className={classes.bottomContainer}>
                <AppLink
                    variant="body1"
                    color="primary"
                    onClick={() => {}}
                    className={classes.moreInfo}
                    to="/"
                >
                    <ArrowBackIcon color="inherit" className={classes.linkIcon} />
                    Zurück
                </AppLink>
            </div>
        </Container>
    );
}

export default Imprint;
