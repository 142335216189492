import {
    Skeleton,
    Stack,
    Typography,
    TypographyProps,
} from '@mui/material';

import ContentDisplay from './Display';
import LoadingSkeleton from './LoadingSkeleton';
import React from 'react';
import { useContentByKey } from '../../Hooks/useContent';

/**
 * Properties for {@link CMSContentDisplay}
 */
export type CMSContentDisplayProps = {
    /**
     * Cms content key to load from
     */
    contentKey: string,

    /**
     * Whether or not to hide the title
     */
    hideTitle?: boolean,

    /**
     * Typography properties for title
     */
    titleTypographyProps?: TypographyProps,
};


/**
 * Component to display cms content. Loads and displays cms content.
 */
const CMSContentDisplay: React.FC<CMSContentDisplayProps> = ({
    contentKey,
    hideTitle = false,
    titleTypographyProps = { variant: 'h4' },
}) => {
    const { loading, data } = useContentByKey(contentKey);
    return (
        <Stack spacing={2}>
            {hideTitle ? null : (
                <Typography {...titleTypographyProps}>
                    {loading ? <Skeleton aria-label="Wird geladen" /> : data?.title}
                </Typography>
            )}
            {
                loading
                    ? (
                        <LoadingSkeleton aria-label="Inhalt wird geladen" />
                    ) : (
                        <ContentDisplay
                            document={data?.content?.document}
                        />
                    )
            }
        </Stack>
    );
};

export default CMSContentDisplay;
