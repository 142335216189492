import {
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemProps,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    Typography,
} from '@mui/material';

import { Conversation } from '../../Types/Client/graphql';
import React from 'react';
import { makeStyles } from '@mui/styles';

/**
 * Styles used in {@link ConversationItem} component
 */
const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
    listItem: {
        paddingLeft: spacing(1),
        paddingRight: spacing(1),
        '& .MuiListItemAvatar-root': {
            [breakpoints.down('md')]: {
                display: 'block',
            },
        },
    },
    avatar: {
        marginRight: spacing(1),
    },
}));

/**
 * Properties for {@link ConversationItem}
 */
export type ConversationItemProps = {
    /**
     * Class name passed to the root container
     */
    className?: string,

    /**
     * Converstation to display
     */
    conversation: Conversation,

    /**
     * Secondary action
     */
    secondaryAction?: JSX.Element,

    /**
     * Onclick handler
     */
    onClick?: () => void,
} & Exclude<ListItemProps, 'button' | 'onClick'>;

/**
 * List item for a post
 */
const ConversationItem = ({
    className,
    conversation,
    secondaryAction,
    onClick,
    ...listItemProps
}: ConversationItemProps) => {
    const classes = useStyles();
    const Component = onClick ? ListItemButton : ListItem;
    return (
        // @ts-ignore
        <Component
            disableGutters={false}
            className={[classes.listItem, className].join(' ')}
            divider
            {...listItemProps}
            onClick={onClick}
          >
            <ListItemAvatar className={classes.avatar}>
                <Typography
                    variant="body1"
                    color="primary"
                >
                    {conversation.partner?.name}
                </Typography>
            </ListItemAvatar>
            <ListItemText
                primary={conversation.advertisment ? `Objekt: ${conversation.advertisment.id}` : '-'}
            />
            <ListItemSecondaryAction>
                {secondaryAction}
            </ListItemSecondaryAction>
        </Component>
    );
};

export default ConversationItem;
