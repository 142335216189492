import { Advertisment } from '../../Types/Client/graphql';
import AppLink from '../Common/AppLink';
import {
    Button,
} from '@mui/material';
import React from 'react';
import useAuth from '../../Hooks/useAuth';
import { useNavigate } from 'react-router-dom';

/**
 * Properties for {@link Actions} component.
 */
export type ActionsProps = {
    /**
     * Advertisment to contact user
     */
    advertisement: Advertisment,
};

/**
 * Single advertisment display page
 */
const Actions = ({ advertisement }: ActionsProps) => {
    const navigate = useNavigate();
    const { authentication } = useAuth();

    if (!authentication || !advertisement.user?.id) {
        return null;
    }

    // Own ad
    if (advertisement?.user?.id === authentication.id) {
        return (
            <AppLink to={`/bearbeiten/${advertisement.id}`}>
                Inserat bearbeiten
            </AppLink>
        );
    }

    return (
        <Button
            size="large"
            variant="contained"
            onClick={() => navigate(`/nachricht?advertisment=${advertisement.id}`)}
        >
            Anbieter kontaktieren
        </Button>
    );
}

export default Actions;
