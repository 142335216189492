import { CardActionArea, Grid, Link, Typography } from '@mui/material';

import { Content } from '../../Types/Client/graphql';
import React from 'react';
import { documentPreviewText } from '../../Lib/Util';

/**
 * Properties for {@link InfoItem} component
 */
export type InfoItemProps = {
    /**
     * Info data to display
     */
    item: Pick<Content, 'title' | 'content'> & { image?: { url?: string | null } | null },

    /**
     * Wheter the image should be imitted
     */
    omitThumbnail?: boolean,

    /**
     * On show handler. If given displays a more link.
     */
    onShow?: () => void,
};

/**
 * Info reel
 */
const InfoItem: React.FC<InfoItemProps> = ({
    item,
    omitThumbnail,
    onShow,
}) => {
    // Thumbnail display
    const thumb = (omitThumbnail || !item.image || !item.image.url)
        ? null
        : (
            <img
                src={item.image.url}
                alt="Bild des Info-Beitrags"
                aria-hidden
                style={{ maxWidth: '100%' }}
            />
        );

    return (
        <Grid item md={4}>
            <CardActionArea onClick={onShow}>
                {thumb}
                <Typography variant="h4" gutterBottom color="text.primary">
                    {item.title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {item.content ? documentPreviewText(item.content) : null}
                </Typography>
                <Link onClick={onShow} component="button">Anzeigen</Link>
            </CardActionArea>
        </Grid>
    );
};

export default InfoItem;
