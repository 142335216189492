import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Listeners to scroll events
 */
export type ScrollTriggerOptions = {
    onBottom?: () => void,
    onTop?: () => void,
    onScroll?: (scrollValue: number) => void,
    onAutoScroll?: () => void,
    endOffset: number,
};

/**
 * Use a dependency as trigger to scroll an element whenever it changes.
 * Originally taken and heavily improved from
 * https://dev.to/deepcodes/automatic-scrolling-for-chat-app-in-1-line-of-code-react-hook-3lm1
 */
const useScollTrigger = <THTMLElmenent extends HTMLElement, TDependency>(
    dep: TDependency,
    options: ScrollTriggerOptions = { endOffset: 0 },
): MutableRefObject<THTMLElmenent | null> => {
    // We keep a reference to the html element, and also a reference to wheter or not the use has
    // scrolled manually, to not interfere with his manual scroll
    const ref = useRef<THTMLElmenent>(null);
    const didManualScroll = useRef(false);
    const scrollArea = useRef<'top' | 'bottom' | null>(null);

    // Run the effect
    useEffect(() => {
        if (ref.current) {
            // Request animation frame makes sure it runs after the renderings are done, otherwise
            // it might trigger before the rendering with changes to dependency to the DOM have
            // been completed.
            requestAnimationFrame(() => {
                const { scrollHeight, scrollTop, offsetHeight } = ref.current!;
                if (didManualScroll.current === false || scrollHeight <= scrollTop + offsetHeight + 100) {
                    ref.current?.scrollTo(0, scrollHeight);
                    didManualScroll.current = false;
                    options?.onAutoScroll?.();
                }
            });
        }
        // Should not run when listener changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dep]);

    // Attach a listener for manual scrolling
    useEffect(() => {
        if(ref.current) {
            ref.current.addEventListener('scroll', () => {
                didManualScroll.current = true;
                const { scrollHeight, scrollTop, offsetHeight } = ref.current!;
                options?.onScroll?.(scrollTop);
                if(scrollTop + offsetHeight >= scrollHeight - options.endOffset) {
                    if (scrollArea.current !== 'bottom') {
                        options?.onBottom?.();
                        scrollArea.current = 'bottom';
                    }
                } else if (scrollTop <= options.endOffset) {
                    if (scrollArea.current !== 'top') {
                        options?.onTop?.();
                        scrollArea.current = 'top';
                    }
                } else {
                    scrollArea.current = null;
                }
            }, { passive: true});
        }
        // Should not run when listener changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);


    return ref;
};

export default useScollTrigger;
