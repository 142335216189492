import {
    Grid,
    Link,
    Typography,
} from '@mui/material';
import React, { useCallback } from 'react';

import Container from '../Components/Common/Container';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    // History is used
    const navigate = useNavigate();
    const goToTerms = useCallback(() => navigate('/agb'), [navigate]);
    const goToContact = useCallback(() => navigate('/kontakt'), [navigate]);
    const goToImprint = useCallback(() => navigate('/impressum'), [navigate]);

    return (
        <Container wrap={false}>
            <Grid container className="App-footer" pt={3} pb={2}>
                <Grid item md={6}>
                    <Typography variant="body2" color="primary.contrastText">
                        Copyright ©
                        {' '}
                        <Link color="primary.contrastText" href="https://www.apothekerverband.at/" target="_blank">
                            Apothekerverband
                        </Link>
                        {' '}
                        {new Date().getFullYear()}
                        {', '}
                        <Typography variant="inherit" color="primary.contrastText" component="span" fontSize="small">
                            {process.env.REACT_APP_VERSION}-{process.env.REACT_APP_GIT_SHA}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item md={6}>
                    <Typography variant="body2" color="primary.contrastText" align="right">
                        <Link component="button" onClick={goToTerms} color="primary.contrastText">
                            Nutzungsbedingungen
                        </Link>
                        {' | '}
                        <Link component="button" onClick={goToContact} color="primary.contrastText">
                            Kontakt
                        </Link>
                        {' | '}
                        <Link component="button" onClick={goToImprint} color="primary.contrastText">
                            Impressum
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Footer;
