"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.componentBlocks = void 0;
const component_blocks_1 = require("@keystone-6/fields-document/component-blocks");
const blocksRender_1 = require("./blocksRender");
exports.componentBlocks = {
    numberedListing: (0, component_blocks_1.component)({
        preview: (0, blocksRender_1.withFieldProps)('numberedListing'),
        label: 'Bullet-Point',
        schema: {
            content: component_blocks_1.fields.child({
                kind: 'block',
                placeholder: 'Inhalt...',
                formatting: { inlineMarks: 'inherit', softBreaks: 'inherit', alignment: undefined },
                links: 'inherit',
            }),
            bullet: component_blocks_1.fields.child({ kind: 'inline', placeholder: '1.' }),
        },
        chromeless: true,
    }),
};
// export default componentBlocks;
