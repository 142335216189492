import {
    FieldValues,
    Path,
    UseFormProps,
    UseFormReturn,
    useForm,
} from 'react-hook-form';

import { getErrorMessage } from '../Lib/FormValidation';

/**
 * Properties of validated form hook
 */
export type UseValidatedFormReturn<
    TFieldValues extends FieldValues = FieldValues,
    TContext = any,
    TTransformedValues extends FieldValues | undefined = undefined
> = Pick<UseFormReturn<TFieldValues, TContext, TTransformedValues>, 'reset' | 'control' | 'register' | 'getFieldState' | 'handleSubmit' | 'watch' | 'setValue'> & {
    /**
     * Get properties for field error
     */
    getFieldErrorProps: (fieldname: Path<TFieldValues>) => { error: boolean, helperText?: string},

    /**
     * Get all properties for field, including a call to register
     */
    getFieldProps: (fieldname: Path<TFieldValues>) => { error: boolean, helperText?: string } & any,
};

/**
 * Common form validation handling for application
 */
const useValidatedForm = <
    TFieldValues extends FieldValues = FieldValues,
    TContext = any,
    TTransformedValues extends FieldValues | undefined = undefined
>(
    validationRules: Partial<Record<keyof TFieldValues, any>>,
    formProps?: UseFormProps<TFieldValues, TContext>,
): UseValidatedFormReturn<TFieldValues, TContext, TTransformedValues> => {
    // Form state management
    const {
        register, getFieldState, handleSubmit, watch, formState, setValue, reset, control,
    } = useForm<TFieldValues>({
        mode: 'onTouched',
        reValidateMode: 'onBlur',
        delayError: 200,
        ...formProps
    });

    // Deriving error state for textfield from field state
    const getFieldErrorProps = (fieldName: Path<TFieldValues>) => {
        const { invalid, error } = getFieldState(fieldName, formState);
        return invalid ? {
            error: invalid,
            helperText: (invalid && error) ? getErrorMessage(error, validationRules[fieldName]) : ' ',
        } : { error: false };
    };

    // Derive all field props
    const getFieldProps = (fieldName: Path<TFieldValues>) => {
        // console.log(fieldName, validationRules[fieldName], register);
        return {
            ...getFieldErrorProps(fieldName),
            ...register(fieldName, validationRules[fieldName]),
            // Add required mark manually from validation definition
            ...(validationRules[fieldName]?.required ? { required: true } : {}),
        };
    };

    return {
        register,
        getFieldState,
        handleSubmit,
        watch,
        getFieldErrorProps,
        getFieldProps,
        setValue,
        reset,
        control,
    };
};

export default useValidatedForm;
