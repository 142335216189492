import React, { PropsWithChildren } from 'react';

import ResponsiveStack from './ResponsiveStack';
import { StackProps } from './Stack';
import {
    Theme,
} from '@mui/material';
import {
    makeStyles,
} from '@mui/styles';

/**
 * Stylings used in {@link AllFotosButton} component.
 */
const useStyles = makeStyles(({ breakpoints }: Theme) => ({
    container: {
        '& .main': {
            flex: 2,
        },
        '& .side': {
            flex: 1,
            [breakpoints.up('md')]: {
                position: 'sticky',
                top: 10,
            },
        },
    },
}));

export const Sidebar: React.FC<PropsWithChildren<{}>> = ({ children }) => (
    <div className="side">
        {children}
    </div>
);

export const Main: React.FC<PropsWithChildren<{}>> = ({ children }) => (
    <div className="main">
        {children}
    </div>
);

/**
 * Properties for {@link SidebarContainer}
 */
export type SidebarContainerProps = StackProps & {
    children: [React.ReactElement<typeof Sidebar> | null, React.ReactElement<typeof Main> | null]
        | [React.ReactElement<typeof Main> | null, React.ReactElement<typeof Sidebar> | null]
        | React.ReactElement<typeof Sidebar | typeof Main>,
};

/**
 * Default SidebarContainer for content. Designed to hold a sidebar and main component.
 */
const SidebarContainer: React.FC<SidebarContainerProps> = ({
    children,
    ...containerProps
}) => {
    const classes = useStyles();

    return (
        <ResponsiveStack
            direction="row"
            alignItems="flexStart"
            // @ts-ignore
            desktopProps={{ ...containerProps }}
            className={classes.container}
            spacing={2}
        >
            {children}
        </ResponsiveStack>
    );
};

export default SidebarContainer;
