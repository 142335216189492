import { gql } from '../Types/Client';
import { useQuery } from '@apollo/client';

// The query to get all filtered
export const GET_ALL_BOOKMARKS = gql(/* GraphQL */ `
    query GetBookmarks {
        bookmarks {
            id
            advertisment {
                id
                status
                name
                description
                thumbnail
                type
                netSales
                grossProfit
                transferDate
                operatingTurn
                operatingResult
                createdAt
                updatedAt
                state { id stateName abbreviation }
                region { id regionName }
                user { id name userSource }
            }
            createdAt
            updatedAt
        }
    }
`);

/**
 * Use advertisments from server.
 */
const useUserBookmarks = () => {
    const { loading, data, error } = useQuery(
        GET_ALL_BOOKMARKS, { fetchPolicy: 'cache-and-network' },
    );
    return { loading, data, error };
};

export default useUserBookmarks;
