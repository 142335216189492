import FotoUpload from './FotoUpload';
import FotoUploadContent from './FotoUploadContent';
import React from 'react';
import Stack from '../Common/Stack';
import {
    Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';

/**
 * Data from inputs in component
 */
export type FormData = {
    fotos: Array<File>,
};


/**
 * Properties for {@link Form} component.
 */
export type FormProps = {
    /**
     * Handler when form is submitted
     */
    onSubmit: (values: FormData) => void,

    /**
     * Form id, use `<button form="[id-goes-here]" />` to submit form from outside form
     */
    id: string,

};

/**
 * Display form for managing fotos of an ad. Append new fotos and also manage existing ones.
 */
const Form = ({ onSubmit, id }: FormProps) => {
    // Form state management
    const { handleSubmit, setValue } = useForm<FormData>();
    return (
        <Stack
            dir="vertical"
            justifyContent="space-between"
            component="form"
            id={id}
            onSubmit={handleSubmit((data) => onSubmit(data))}
            role="form"
        >
            <div>
                <Typography variant="subtitle1">Fotos</Typography>
                <Typography variant="body2" color="text.secondary">
                    Fügen Sie Bilder zu Ihrer Anzeige hinzu.
                </Typography>
            </div>
            <FotoUpload
                Content={FotoUploadContent}
                onChange={(fotos) => setValue("fotos", fotos)}
            />
        </Stack>
    );
};

export default Form;
