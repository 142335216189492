import {
    SvgIcon,
    SvgIconProps,
} from '@mui/material';

import { ReactComponent as AddSvg } from './apo-icons-add.svg';
import { ReactComponent as AdvertismentsSvg } from './apo-icons-advertisments.svg';
import { ReactComponent as AgentSvg } from './apo-icons-agent.svg';
import { ReactComponent as ArrowBackSvg } from './apo-icons-arrow-back.svg';
import { ReactComponent as ArrowDownSvg } from './apo-icons-arrow-down.svg';
import { ReactComponent as ArrowLeftSvg } from './apo-icons-arrow-left.svg';
import { ReactComponent as ArrowLoginSvg } from './apo-icons-arrow-login.svg';
import { ReactComponent as ArrowMoreSvg } from './apo-icons-arrow-more.svg';
import { ReactComponent as ArrowRightSvg } from './apo-icons-arrow-right.svg';
import { ReactComponent as ArrowUpSvg } from './apo-icons-arrow-up.svg';
import { ReactComponent as BookmarkOnSvg } from './apo-icons-bookmark-on.svg';
import { ReactComponent as BookmarkSvg } from './apo-icons-bookmark.svg';
import { ReactComponent as CheckSvg } from './apo-icons-check.svg';
import { ReactComponent as ClearSvg } from './apo-icons-clear.svg';
import { ReactComponent as DeleteSvg } from './apo-icons-delete.svg';
import { ReactComponent as DownloadSvg } from './apo-icons-download.svg';
import { ReactComponent as EditSvg } from './apo-icons-edit.svg';
import { ReactComponent as GallerySvg } from './apo-icons-gallery.svg';
import { ReactComponent as GrossProfitSvg } from './apo-icons-gross-profit.svg';
import { ReactComponent as LocationSvg } from './apo-icons-location.svg';
import { ReactComponent as MenuSvg } from './apo-icons-menu.svg';
import { ReactComponent as MessagesSvg } from './apo-icons-messages.svg';
import { ReactComponent as NavMessageSvg } from './apo-icons-nav-message.svg';
import { ReactComponent as NavProfileSvg } from './apo-icons-nav-profile.svg';
import { ReactComponent as NetSalesSvg } from './apo-icons-net-sales.svg';
import { ReactComponent as OperatingResultSvg } from './apo-icons-operating-result.svg';
import { ReactComponent as OperatingTurnSvg } from './apo-icons-operating-turn.svg';
import { ReactComponent as PasswordHideSvg } from './apo-icons-password-hide.svg';
import { ReactComponent as PasswordShowSvg } from './apo-icons-password-show.svg';
import { ReactComponent as SignOutSvg } from './apo-icons-signout.svg';
import { ReactComponent as TransferDateSvg } from './apo-icons-transfer-date.svg';

/**
 * Turn a svg into a svg component in design system
 */
const asIcon = (SvgCmp: any) => (
    (props: SvgIconProps) => (<SvgIcon color="primary" {...props} inheritViewBox><SvgCmp /></SvgIcon>)
);

/**
 * Exüprt all
 */
export const AddIcon = asIcon(AddSvg);
export const AdvertismentsIcon = asIcon(AdvertismentsSvg);
export const AgentIcon = asIcon(AgentSvg);
export const ArrowBackIcon = asIcon(ArrowBackSvg);
export const ArrowLoginIcon = asIcon(ArrowLoginSvg);
export const ArrowMoreIcon = asIcon(ArrowMoreSvg);
export const ArrowDownIcon = asIcon(ArrowDownSvg);
export const ArrowLeftIcon = asIcon(ArrowLeftSvg);
export const ArrowRightIcon = asIcon(ArrowRightSvg);
export const ArrowUpIcon = asIcon(ArrowUpSvg);
export const BookmarkIcon = asIcon(BookmarkSvg);
export const BookmarkOnIcon = asIcon(BookmarkOnSvg);
export const CheckIcon = asIcon(CheckSvg);
export const ClearIcon = asIcon(ClearSvg);
export const DeleteIcon = asIcon(DeleteSvg);
export const DownloadIcon = asIcon(DownloadSvg);
export const EditIcon = asIcon(EditSvg);
export const NetSalesIcon = asIcon(NetSalesSvg);
export const GalleryIcon = asIcon(GallerySvg);
export const GrossProfitIcon = asIcon(GrossProfitSvg);
export const OperatingResultIcon = asIcon(OperatingResultSvg);
export const OperatingTurnIcon = asIcon(OperatingTurnSvg);
export const LocationIcon = asIcon(LocationSvg);
export const MenuIcon = asIcon(MenuSvg);
export const NavMessageIcon = asIcon(NavMessageSvg);
export const NavProfileIcon = asIcon(NavProfileSvg);
export const MessagesIcon = asIcon(MessagesSvg);
export const PasswordHideIcon = asIcon(PasswordHideSvg);
export const PasswordShowIcon = asIcon(PasswordShowSvg);
export const SignOutIcon = asIcon(SignOutSvg);
export const TransferDateIcon = asIcon(TransferDateSvg);
