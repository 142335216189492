import {
    Link as BaseLink,
    Stack,
    Theme,
    Typography,
} from '@mui/material';
import ColumnLayout, { Column, useColumnExtraStyles } from './ColumnLayout';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ArrowBackIcon } from '../Icon';
import CubeLogo from '../../Layout/CubeLogo';
import FormState from '../Common/FormState';
import FormStateMessage from '../Common/FormStateMessage';
import Link from '../Common/AppLink';
import PasswordForgotForm from '../User/PasswordForgotForm';
import PasswordResetForm from '../User/PasswordResetForm';
import { makeStyles } from '@mui/styles';

/**
 * Styles used in {@link PasswordReset} component.
 */
const useStyles = makeStyles((theme: Theme) => ({
    register: {
        padding: theme.spacing(1),
        borderRadius: theme.spacing(0.25),
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.grey[400],
        width: '100%',
    },
}));

const TEXTS = {
    request: (
        <Stack spacing={1} paddingLeft={1} width="100%">
            <Typography variant="h4" gutterBottom={false}>
                Passwort angefordert
            </Typography>
            <Typography variant="subtitle2">
                Sie haben ein neues Passwort angefordert. Wenn es zu der eingegebenen
                Email-Adresse einen verknüpften Account gibt, bekommen Sie eine
                Email mit einem Link um ein neues Passwort anzufordern.
            </Typography>
        </Stack>
    ),
    redeem: (
        <Stack spacing={1} paddingLeft={1} width="100%">
            <Typography variant="h4" gutterBottom={false}>
                Passwort wurde zurückgesetzt
            </Typography>
            <Typography variant="subtitle2">
                Das neue Passwort wurde gesetzt. Ab sofort können Sie sich mit ihrem neuen Passwort
                bei der Apothekenbörse einloggen.
            </Typography>
        </Stack>
    ),
}

/**
 * Sign in form with info messages
 */
const PasswordReset = () => {
    const classes = { ...useColumnExtraStyles(), ...useStyles() };
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [finished, setFinished] = useState<'redeem' | 'request'>();

    const redeemCode = searchParams.get('redeem');
    const email = searchParams.get('email');
    const [form, setForm] = useState<'redeem' | 'request'>(redeemCode !== null ? 'redeem' : 'request');

    return (
        <ColumnLayout>
            <Column className={classes.infoColumn} color="primary">
                <CubeLogo className={classes.logo} />
                <div>
                    <Typography variant="h1" color="inherit" className={classes.lead}>
                        Neues Passwort anfordern
                    </Typography>
                    <Typography component="p" variant="body1" color="inherit" className={classes.spaceBottom}>
                        Wenn Sie ihr Passwort vergessen haben können Sie einen Code anfordern um dieses zurückzusetzen.
                    </Typography>
                </div>
                <Link
                    component="button"
                    variant="body1"
                    color="inherit"
                    // @ts-ignore
                    back
                    className={classes.moreInfo}
                >
                    <ArrowBackIcon color="inherit" className={classes.linkIcon} />
                    zurück
                </Link>
            </Column>
            <Column className={classes.formColumn}>
                <FormState
                    completed={finished !== undefined}
                    Component={FormStateMessage}
                    componentProps={{
                        loadingMessage: null,
                        completedMessage: TEXTS[finished ?? 'request'],
                        completedActionLabel: finished === 'redeem' ? 'weiter' : 'zurück zur Startseite',
                        onAction: () => navigate(finished === 'redeem' ? '/home' : '/')
                    }}
                >
                    {
                        form === 'request'
                            ? (
                                <Stack spacing={1} paddingLeft={1} width="100%">
                                    <Typography variant="h2" gutterBottom={false} className={classes.pageTitle}>
                                        Passwort vergessen
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Fordern Sie ein neues Passwort an.
                                    </Typography>
                                    <PasswordForgotForm onRequested={() => setFinished('request')} />

                                    <BaseLink component="button" onClick={() => setForm('redeem')} variant="body2">
                                        Ich habe bereits einen Code.
                                    </BaseLink>
                                </Stack>
                            )
                            : (
                                <Stack spacing={1} paddingLeft={1} width="100%">
                                    <Typography variant="h4" gutterBottom={false} className={classes.pageTitle}>
                                        Passwort zurücksetzen
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Haben Sie bereits einen Code um Ihr Passwort zurückzusetzen
                                    </Typography>
                                    <PasswordResetForm
                                        code={redeemCode ?? undefined}
                                        email={email ?? undefined}
                                        onSuccess={() => setFinished('redeem')}
                                    />

                                    <BaseLink component="button" onClick={() => setForm('request')} variant="body2">
                                        Ich habe keinen einlöse Code oder möchte einen neuen anfordern.
                                    </BaseLink>
                                </Stack>
                            )
                    }

                    <Typography variant="body2" color="text.secondary" className={classes.help}>
                        Ich benötige Hilfe: <Link to="/hilfe-login">Hilfe zum Login</Link>
                    </Typography>
                </FormState>
            </Column>
        </ColumnLayout>
    );
}

export default PasswordReset;
