import {
    Link,
    LinkProps,
} from '@mui/material';
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
    useNavigate,
} from 'react-router-dom';

import React from 'react';

export type AppLinkOwnProps = {
    /**
     * Special handling for a back link
     */
    back: true,
    to?: never,
} |
{
    back?: never,
    to: RouterLinkProps['to'],
};

/**
 * Properties for {@link AppLink}
 */
export type AppLinkProps =
    Omit<RouterLinkProps, 'to'>
    & LinkProps<typeof RouterLink, {}>
    & AppLinkOwnProps;

/**
 * Component for decarative links within the application encorporating both `@mui` and
 * `react-router-dom`.
 */
const AppLink: React.FC<AppLinkProps> = ({
    children,
    to,
    back,
    ...linkProps
}) => {
    const navigate = useNavigate();
    const handleClick = (back) ? () => navigate(-1) : undefined;
    return (
        <Link {...linkProps} component={RouterLink} to={to} onClick={handleClick}>
            {children}
        </Link>
    );
};

export default AppLink;
