import {
    Checkbox,
} from '@mui/material';
import React from 'react';
import { TreeRenderProps } from  'react-complex-tree';

/**
 * Properties for {@link SelectedIndicator}, not properly exported
 */
type SelectedIndicatorProps = Parameters<Required<TreeRenderProps>['renderItemArrow']>[0];

/**
 * Render an indicator if it is selected or not
 */
const SelectedIndicator = ({ item, context }: SelectedIndicatorProps) => {
    return (
        <span {...context.arrowProps}>
            <Checkbox checked={context.isExpanded || context.isSelected} readOnly />
        </span>
    );
};


export default SelectedIndicator;
