import {
    Card,
    CardContent,
    CardProps,
    Container as MuiContainer,
    Theme,
} from '@mui/material';
import React, { PropsWithChildren } from 'react';

import {
    makeStyles,
} from '@mui/styles';

/**
 * Properties for {@link Container}
 */
export type ContainerProps = PropsWithChildren<{
    /**
     * Container width
     */
    size?: 'narrow' | 'default',

    /**
     * Whether or not to wrap content with a {@link Card} and {@link CardContent}
     */
    wrap?: boolean,

    /**
     * Whether or not to be considered full page container
     */
    pageContainer?: boolean,
} & CardProps>;

/**
 * Stylings used in {@link AllFotosButton} component.
 */
const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
    contentHeaderAdjustment: {
        '& .container-header:first-child': {
            marginTop: spacing(-2),
        },
    },
    pageContainer: {
        [breakpoints.up('md')]: {
            paddingTop: 120,
        },
        [breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
}));


/**
 * Grid props by size type
 */
const gridProps = {
    narrow: { maxWidth: 'md' },
    default: { maxWidth: 'lg' },
} as const;


/**
 * Default container for content. Wrapping all in a default {@link Box}
 */
const Container: React.FC<ContainerProps> = ({
    children,
    size = 'default' as const,
    wrap = true,
    pageContainer = false,
    ...boxProps
}) => {
    const classes = useStyles();
    return (
        <MuiContainer {...gridProps[size]} className={pageContainer ? classes.pageContainer : ''}>
            {
                wrap
                ? (
                    <Card {...boxProps}>
                        <CardContent className={classes.contentHeaderAdjustment}>
                            {children}
                        </CardContent>
                    </Card>
                )
                : children
            }
        </MuiContainer>
    );
};

export default Container;
