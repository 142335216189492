import {
    Link,
    Typography,
} from '@mui/material';

import Container from '../Common/Container';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NotFound = () => {
    return (
        <Container size="narrow" pageContainer>
            <Typography variant="h1">Seite nicht gefunden</Typography>
            <Typography variant="body1">
                Leider konnte die von Ihnen angeforderte Seite nicht gefunden werden.
            </Typography>
            <Typography variant="body1">
                <Link to="/" component={RouterLink}>zur Startseite</Link>
            </Typography>
        </Container>
    );
}

export default NotFound;
