import {
    DialogTitle,
    IconButton,
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
    Theme,
} from '@mui/material';
import React, { ReactNode } from 'react';

import { ClearIcon as CloseIcon } from '../Icon';
import {
    makeStyles,
} from '@mui/styles';

/**
 * Properties for {@link Dialog} component
 */
export type DialogProps = Omit<MuiDialogProps, 'title'> & {
    /**
     * Dialog title
     */
    title?: string | ReactNode,
};

/**
 * Stylings used in {@link Dialog} component.
 */
const useStyles = makeStyles(({ spacing }: Theme) => ({
    closeButton: {
        marginRight: spacing(0.5),
    },
    closeIcon: {
        width: 36,
        height: 36,
    },
    closeButtonStandalone: {
        position: 'absolute',
        right: spacing(0.5),
        top: spacing(0.5),
    },
}));

/**
 * Dialog component with close control
 */
const Dialog = ({
    title,
    children,
    ...dialogProps
}: DialogProps) => {
    // Styles
    const classes = useStyles();

    return (
        <MuiDialog {...dialogProps}>
            {title
                ? (
                    <DialogTitle>
                        {title}
                        <IconButton
                            aria-label="close"
                            onClick={(e) => dialogProps.onClose?.(e, "backdropClick")}
                            className={classes.closeButton}
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </DialogTitle>
                )
                : (
                        <IconButton
                            aria-label="close"
                            onClick={(e) => dialogProps.onClose?.(e, "backdropClick")}
                            className={[classes.closeButton, classes.closeButtonStandalone].join(' ')}
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                )
            }
            {children}
        </MuiDialog>
    );
};

export default Dialog;
